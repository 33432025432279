import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { TextButton } from 'components/Button';
import {
  Container,
  Title,
  SubTitle,
  Text
} from './style';
import { PATHS } from 'routes';

const TimesUp = ({
  deleteCardData
}) => {
  const navigate = useNavigate();

  const handleHome = () => {
    // TODO :: Delete stored data
    deleteCardData?.();
    navigate(PATHS.HOME);
  };

  const handleBook = () => {
    deleteCardData?.();
    window.location.href = PATHS.BOOKING;
  };

  return (
    <Container>
      <Title>Time's <b>up</b></Title>
      <SubTitle>reservation expired</SubTitle>
      <Box sx={{ paddingTop: '24px', paddingBottom: '36px' }}>
        <Text $fw={500}>
          The time given to you to complete the reservation has expired.
        </Text>
        <Text>
          To continue your process again, press the home page button to return to the book page to the home page.
        </Text>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <TextButton
          title={'Home Page'}
          color={'#002424'}
          onClick={handleHome}
        />
        <TextButton
          title={'Book Now'}
          hasArrow
          color={'#139CD7'}
          onClick={handleBook}
        />
      </Box>
    </Container>
  )
};

export default TimesUp;
