import styled, { css } from 'styled-components';
import BlueTick from 'assets/img/icons/blue-tick.svg';

export const SectionTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #002424;
`;

export const CurrencyContainer = styled.div`
  /* padding: 18px 19px; */
  padding: 0px 19px 18px 19px;
  
`;

export const CurrencyDescription = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #231F20;
  padding-top: 19px;
  display: flex;
  align-items: baseline;
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 15px;
    border: 1px solid #1A9DD8;
    border-radius: 100%;
    min-width: 7px;
  }
`;

export const CurrencyBoxes = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 15px;
  flex-wrap: wrap;
  
  &>div {
    margin-top: 10px;
  }
`;

export const CurrencyBox = styled.div`
  /* width: 203px; */
  width: 45%;
  min-width: 153px;
  height: 83px;
  /* background: #F5F5F5; */
  background: ${({ $selected }) => $selected ? '#F5F5F5' : '#FFFFFF'};
  border: 1px solid ${({ $selected }) => $selected ? '#139CD7' : '#C8C8C8'};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  ${({ $selected }) => $selected && css`
    background-image: url(${BlueTick});
    background-repeat: no-repeat;
    background-position: right 9px top 9px;
  `};
`;

export const CurrencyText = styled.span`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: ${({ $light }) => $light ? 300 : 500};
  font-size: 16px;
  line-height: 16px;
  color: #002424;
  user-select: none;
`;

export const PriceText = styled.span`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ $thiny }) => $thiny ? '12px' : '16px'};
  line-height: 32px;
  color: #002424;
  user-select: none;
`;
