import { AxiosError } from "axios";

const errorHandler = (error) => {
  const permission = ['development', 'test'].includes(process.env.NODE_ENV);
  if (error instanceof AxiosError) {
    !!permission && console.log(`AxiosError: ${error.message} -> ${error.config.url}`);
    return error.response?.data?.message || error.response?.statusText;
  } else if (error instanceof Error) {
    !!permission && console.log(`Error: ${error.message} -> ${error.name}`);
    return JSON.parse(error.message) || error.message;
  } else {
    !!permission && console.log('ERROR ::', error);
    return 'Error';
  }
};

export default errorHandler;
