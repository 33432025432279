import React from 'react';
import { ReactComponent as WarningIcon } from 'assets/img/icons/warning.svg';
import Button, { TextButton } from 'components/Button';
// import Dropdown from 'components/Dropdown';
import Modal from 'components/Modal';
import BillingSection from './components/BillingSection';
import SelectCurrency from './components/SelectCurrency';
import GuestMenu from './components/GuestMenu';
import PaymentModal from './components/PaymentModal';
import { BookingContext } from 'contexts/Booking';
import { formatDateTime, formatPrice, getLocalStorage, setLocalStorage } from 'helpers';
import { Helmet } from "react-helmet";
import {
  Container,
  Card,
  WarningArea,
  SectionHeader,
  CardContents,
  CardContent,
  SectionTitle,
  ReceiptContainer,
  ReceiptTitle,
  ButtonContainer,
  ReceiptOverallContainer,
  Line,
  ReceiptOverall,
  FoodCount,
  SessionDate,
  Price,
  Fee,
  ReceiptContent,
  Receipt,
  StepPrice,
  // Box,
  CardModalArea,
  List,
  // SpecialList,
  ListItem,
  // SpecialOccasionArea,
  // SpecialTitle,
  // SpecialText,
  // SummaryInfoArea,
  // SummaryText,
  // SummaryWarning,
  SpecialRequest
} from './style';
import CreditCardInfo from './components/CreditCardInfo';


const CONSENT = {
  information: { value: false, error: null, isRequired: true },
  terms: { value: false, error: null, isRequired: true },
  privacy_policy: { value: false, error: null, isRequired: true },
  kvkk: { value: false, error: null, isRequired: true },
  electronic: { value: false, error: null, isRequired: false },
  explicit: { value: false, error: null, isRequired: true }
}

const CheckoutSection = ({
  counter,
  order,
  handleChangeBillingInfo,
  handleChangeOrder,
  nextSection,
  prevSection,
  stopCounter,
  startCounter,
  t,
  _t
}) => {
  const [consents, setConsents] = React.useState(CONSENT);
  const { createOrder: createOrderCt, /* getCurrencyList,  */currencyData } = React.useContext(BookingContext);
  const [paymentScript, setPaymentScript] = React.useState(null);
  const [payProcess, setPayProcess] = React.useState(false);
  const [hasProcessError, setHasProcessError] = React.useState(false);
  const [cardInfo, setCardInfo] = React.useState({
    holderName: "",
    no: "",
    expiry: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: ""
  });
  const paymentScriptRef = React.useRef(null);

  const paymentRef = React.useRef(null);

  const checkConsent = () => {
    const _consents = { ...consents };
    let isValid = true;
    Object.keys(_consents).forEach(key => {
      if (_consents[key].isRequired && !_consents[key].value) {
        // _consents[key].error = 'This field is required';
        _consents[key].error = t('textboxes.required');
        isValid = false;
      } else {
        _consents[key].error = null;
      }
    });
    setConsents(_consents);
    return isValid;
  };

  const checkBillingInfo = () => {
    console.log(order);
    const _order = { ...order };
    let isValid = true;
    Object.keys(_order.billingInfo).forEach(key => {
      if (!_order.billingInfo[key].value) {
        // _order.billingInfo[key].error = 'This field is required';
        _order.billingInfo[key].error = t('textboxes.required');
        isValid = false;
      } else {
        _order.billingInfo[key].error = null;
      }
    });
    handleChangeOrder(_order);
    return isValid;
  }

  const createOrder = async () => {
    setHasProcessError(false)
    if (!checkBillingInfo() || !checkConsent()) {
      return;
    }
    setPayProcess(true);
    const orderGuest = order.guestsInfo.map(e => {
      const d = new Date(e.birthday);
      const _m = d.getMonth() + 1;
      const _d = d.getDate();
      const formetted = `${d.getFullYear()}-${_m > 9 ? _m : `0${_m}`}-${_d > 9 ? _d : `0${_d}`}`;
      return {
        name_surname: e.name,
        birthday: formetted,
        ...(e?.main?.value && { main_food: e.main.value }),
        ...(e?.salad?.value && { salad_food: e.salad.value }),
        ...(e?.starter?.value && { starter_food: e.starter.value }),
        ...(e?.dessert?.value && { dessert_food: e.dessert.value }),
        ...(e?.cocktail?.value && { cocktail_food: e.cocktail.value })
      }
    });

    try {
      // expiry = 01 / 12
      const ex_month = cardInfo.expiry.split('/')[0].trim();
      const ex_year = cardInfo.expiry.split('/')[1].trim();
      const _order = {
        "card": order.card.id,
        "currency": order.billingInfo.currency.value,
        "billing_info": {
          "name": order.billingInfo.fullName.value,
          "surname": order.billingInfo.surname.value,
          "phone": order.billingInfo.dialCode.value + order.billingInfo.phone.value,
          "email": order.billingInfo.email.value,
          "country": order.billingInfo.country.value,
          "state": order.billingInfo.state.value,
          "city": order.billingInfo.city.value,
          "zip_code": order.billingInfo.postalCode.value,
          "address": order.billingInfo.fullAddress.value
        },
        order_detail: orderGuest,
        summary: {
          tr: renderSummaryText('tr'),
          en: renderSummaryText('en')
        },
        card_info: {
          holderName: cardInfo.holderName,
          no: cardInfo.no.replace(/\s/g, ''),
          // no: cardInfo.no,
          // expiryMonth: cardInfo.expiryMonth,
          expiryMonth: ex_month,
          // expiryYear: cardInfo.expiryYear,
          expiryYear: `20${ex_year}`,
          cvv: cardInfo.cvv
        },
        policy: {
          pre_contractual: consents.information.value,
          explicit_consent: consents.explicit.value,
          kvkk: consents.kvkk.value,
          conditions: consents.terms.value,
          distance_sale: consents.privacy_policy.value,
          electronic: consents.electronic.value
        },
        ...(order.fillGuestsInfo && { special_request: order.fillGuestsInfo })
      }
      const prevLocal = getLocalStorage('order');
      const newLocal = {
        ...prevLocal,
        ...order
      };
      setLocalStorage('order', newLocal);
      // stopCounter?.();
      const res = await createOrderCt(_order);

      setPaymentScript(res);
      paymentScriptRef.current = res;
      // paymentRef.current.open();
      // setPaymentScript(`<script type="text/javascript">if (typeof iyziInit == 'undefined') {var iyziInit = {currency:"EUR",token:"e96b7a8f-9ad6-43b7-a7fa-a91413aa5aee",price:800.00,locale:"tr",baseUrl:"https://sandbox-api.iyzipay.com", merchantGatewayBaseUrl:"https://sandbox-merchantgw.iyzipay.com", registerCardEnabled:true,bkmEnabled:false,bankTransferEnabled:false,bankTransferTimeLimit:{"value":5,"type":"day"},bankTransferRedirectUrl:"https://dist-website-api.aydinolmez.com/iyzico/callback",bankTransferCustomUIProps:{ },campaignEnabled:false,campaignMarketingUiDisplay:null,paymentSourceName:"",plusInstallmentResponseList:null,payWithIyzicoSingleTab:false,payWithIyzicoOneTab:false,mixPaymentEnabled:true,creditCardEnabled:true,bankTransferAccounts:[],userCards:[],fundEnabled:false,memberCheckoutOtpData:{ },force3Ds:false,isSandbox:true,storeNewCardEnabled:true,paymentWithNewCardEnabled:true,enabledApmTypes:["SOFORT","IDEAL","QIWI","GIROPAY"],payWithIyzicoUsed:false,payWithIyzicoEnabled:true,payWithIyzicoCustomUI:{ },buyerName:"Aydın Ölmez",buyerSurname:".",merchantInfo:"",cancelUrl:"",buyerProtectionEnabled:false,hide3DS:false,gsmNumber:"+905072303833",email:"aydin.olmez@kodzillaistanbul.com",checkConsumerDetail:{ },subscriptionPaymentEnabled:false,ucsEnabled:false,fingerprintEnabled:false,payWithIyzicoFirstTab:false,creditEnabled:false,payWithIyzicoLead:false,goBackUrl:"",metadata : { },createTag:function(){var iyziJSTag = document.createElement('script');iyziJSTag.setAttribute('src','https://sandbox-static.iyzipay.com/checkoutform/v2/bundle.js?v=1682430337159');document.head.appendChild(iyziJSTag);}};iyziInit.createTag();}</script>`)
      // paymentRef.current.open();
    } catch (error) {
      // startCounter?.();
      console.log('error', error);
    }
    setPayProcess(false);
  };

  const handleBack = () => {
    prevSection();
  };

  const renderSummaryText = React.useCallback((lang) => {
    let summary = [];
    const type = order?.card?.booking?.booking_type;
    if (!!lang) {
      summary = _t(`pages.booking.checkout_section.summary.${type}`, lang);
    } else {
      summary = t(`pages.booking.checkout_section.summary.${type}`);
    }
    return Array.isArray(summary) ? summary : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, _t]);

  const calcTotalPrice = React.useCallback((price) => {
    const currencyType = order?.billingInfo?.currency?.value;
    if (currencyType !== 'EUR') {
      return `₺${formatPrice(price * currencyData?.data)}`;
    }

    return `€${formatPrice(price)}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyData?.data, order?.billingInfo?.currency?.value, order?.card?.total_price]);

  const InjectScript = ({ script }) => {
    const divRef = React.useRef(null);

    React.useEffect(() => {
      if (divRef.current === null) {
        return;
      }
      console.log('::create::', script);
      const doc = document
        .createRange()
        .createContextualFragment(script)
      console.log('doc', doc);
      divRef.current.innerHTML = ''
      divRef.current.appendChild(doc)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [script])

    return <div ref={divRef} />
  };

  const sendPaymentToFastPay = () => {
    if (document.getElementById("the-form")) {
      setHasProcessError(false)
      const htmlText = paymentScriptRef.current?.htmlContent;
      const actionLink = htmlText.substring(htmlText.lastIndexOf('.action'))
      const regex = /https?:\/\/[^\s]+/g;
      const bankLink = actionLink.match(regex)?.[0].replace('";', '');

      document.getElementById("the-form").action = bankLink;
      document.getElementById("the-form").submit();
    } else {
      setHasProcessError(true)
    }
  }

  React.useEffect(() => {
    if (paymentScriptRef.current?.htmlContent) {
      // paymentRef.current.open?.();
      if (paymentScriptRef.current?.htmlContent?.startsWith?.('<h1>')) {
        delete paymentScriptRef.current?.htmlContent;
      }
      setTimeout(() => {
        console.log('sendPaymentToFastPay();');
        sendPaymentToFastPay();
      // }, 1000);
      }, 500);
    }
  }, [paymentScriptRef.current?.htmlContent])

  const handlePassPay = () => {
    if (!checkBillingInfo() || !checkConsent()) {
      return;
    }
    const prevLocal = getLocalStorage('order');
    const newLocal = {
      ...prevLocal,
      ...order
    };
    setLocalStorage('order', newLocal);
    paymentRef.current.open?.()
  }

  return (
    <Container>

      <Card>
        <WarningArea>
          <WarningIcon />
          <p>{t('common.remaining_time')} <strong>{counter}</strong></p>
        </WarningArea>

        <CardContents>
          <CardContent>
            <SectionHeader>
              <SectionTitle>Exchange Choose</SectionTitle>
            </SectionHeader>

            <SelectCurrency
              order={order}
              handleChangeBillingInfo={handleChangeBillingInfo}
              currencyData={currencyData.data}
              totalPrice={order.card?.total_price}
              t={t}
            />

            {/* <SectionHeader onClick={() => console.log('order?.specialOccasion', order?.specialOccasion)}>
              <SectionTitle>Do You Have a Special Occasion?</SectionTitle>
            </SectionHeader>
            <Box>
              {order.specialOccasion?.label &&
                <SpecialOccasionArea>
                  <SpecialTitle>{order.specialOccasion?.label}</SpecialTitle>
                  <SpecialText>Birthday Cake / Music and Name on the led Screen
                    <SpecialText $brand>
                      €100.00 + €3.51 fee
                    </SpecialText>
                  </SpecialText>

                  <SpecialList>
                    {SUMMARY.map((item, index) => (
                      <ListItem key={index}>{item}</ListItem>
                    ))}
                  </SpecialList>
                </SpecialOccasionArea>
              }
            </Box> */}
          </CardContent>

          <CardContent $bg={'#139CD7'} $padding={'30px 15px 20px 15px'}>
            <ReceiptTitle>Dinner in the Sky</ReceiptTitle>
            <ReceiptContainer>
              <ReceiptOverallContainer>
                <ReceiptOverall>
                  <FoodCount>{order.guests} x {order.card.booking?.name}</FoodCount>
                  <SessionDate>{formatDateTime(order.session?.start_date)}</SessionDate>
                </ReceiptOverall>
                <ReceiptOverall $sx={{ alignItems: 'flex-end' }}>
                  {/* <Price>€695.00</Price> */}
                  <Price>€{formatPrice(order.card?.unit_price)}</Price>
                  {/* <Fee>+ €8.23 fee</Fee> */}
                </ReceiptOverall>
              </ReceiptOverallContainer>
              {/* {
                // ** depraecated
                false &&
                // order.specialOccasion?.value &&
                <React.Fragment>
                  <Line $sx={{ marginBottom: '5px' }} />
                  <ReceiptOverallContainer>
                    <ReceiptOverall>
                      <FoodCount>{order.specialOccasion?.label} in the Sky</FoodCount>
                      <SessionDate>Premium - {formatDateTime(order?.date)}</SessionDate>
                    </ReceiptOverall>
                    <ReceiptOverall $sx={{ alignItems: 'flex-end' }}>
                      <Price>€400.00</Price>
                    </ReceiptOverall>
                  </ReceiptOverallContainer>
                </React.Fragment>
              } */}
              <Line />
              <ReceiptContent>
                <Receipt>
                  {/* <StepPrice $bold>Total</StepPrice> */}
                  <StepPrice $bold>{t('pages.success.total')}</StepPrice>
                  {/* <StepPrice $bold>€793.00</StepPrice> */}
                  {/* <StepPrice $bold>€{formatPrice(order.card?.total_price)}</StepPrice> */}

                  <StepPrice as={'span'} $bold $sx={{ textAlign: 'end' }}>
                    €{formatPrice(order.card?.total_price)}
                    <Fee>{t('pages.success.tax_include')}</Fee>
                  </StepPrice>
                </Receipt>

                {order?.billingInfo?.currency?.value !== 'EUR' &&
                  <React.Fragment>
                    <Receipt>
                      <StepPrice>{t('pages.success.exchange_rate')}</StepPrice>
                      <StepPrice>1EUR = {formatPrice(currencyData?.data)}TRY</StepPrice>
                    </Receipt>
                  </React.Fragment>
                }

                <Receipt>
                  {/* <StepPrice $bold>Total Pay</StepPrice> */}
                  <StepPrice $bold>{t('pages.success.total_paid')}</StepPrice>
                  <StepPrice $bold>{calcTotalPrice(order.card?.total_price)}</StepPrice>
                </Receipt>

              </ReceiptContent>

            </ReceiptContainer>

            {/* <SummaryInfoArea>
              <SummaryWarning>
                <WarningIcon />
                Please note that your guests will be seated across multiple tables.
              </SummaryWarning>
              <SummaryText>{DESCRIPTION}</SummaryText>
            </SummaryInfoArea> */}
          </CardContent>
        </CardContents>

        <React.Fragment>
          <SectionHeader>
            <SectionTitle>{t('pages.success.summary')}</SectionTitle>
          </SectionHeader>
          <List $sx={{ padding: '25px 19px' }}>
            {renderSummaryText().map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </List>


          <SectionHeader>
            <SectionTitle>{t('pages.success.guests')}</SectionTitle>
          </SectionHeader>
          <GuestMenu
            guests={order.guestsInfo}
            session={order.session}
            t={t}
          />
        </React.Fragment>

        {order?.fillGuestsInfo &&
          <React.Fragment>
            <SectionHeader>
              <SectionTitle>{t('pages.success.special_requests')}</SectionTitle>
            </SectionHeader>
            <SpecialRequest>{order.fillGuestsInfo}</SpecialRequest>
          </React.Fragment>}

        <BillingSection
          order={order}
          handleChangeBillingInfo={handleChangeBillingInfo}
          t={t}
          consents={consents}
          setConsents={setConsents}
        />
      </Card>

      <ButtonContainer>
        <TextButton
          title={t('buttons.back')}
          color={'#002424'}
          onClick={handleBack}
        />
        <Button
          title={t('buttons.proceed_payment')}
          hasArrow
          backgroundColor={'#139CD7'}
          overlayColor={'#002424'}
          // onClick={createOrder}
          onClick={handlePassPay}
          fullSize
        />
      </ButtonContainer>


      <Modal
        ref={paymentRef}
        size={'md'}
        isCloseOutsideClick={false}
        modalContainerStyle={{ maxHeight: '80vh', width: '40%', maxWidth: '700px', minWidth: '400px' }}
        // onClose={() => setPaymentScript(null)}
        onClose={() => {
          // paymentScript.current = null;
          window.location.reload();
        }}
      >
        {/* <Helmet>
            <script>
              {paymentScriptRef.current?.htmlContent.replace('<script type="text/javascript">', '').replace('</script>', '')}
              {paymentScriptRef.current?.htmlContent.substring(paymentScriptRef.current?.htmlContent.indexOf('<script')).replace('<script type="text/javascript">', '').replace('</script>', '')}
            </script>
          </Helmet> */}
        {paymentScriptRef.current?.htmlContent &&
          <div
            dangerouslySetInnerHTML={{
              // __html: (hasProcessError ? '' : paymentScriptRef.current?.htmlContent)
              __html: paymentScriptRef.current?.htmlContent
            }}
          />
        }
        {/* <CardModalArea> */}
        <CreditCardInfo
          setCardInfo={setCardInfo}
          cardInfo={cardInfo}
          onClickOrder={createOrder}
          payProcess={payProcess}
          hasProcessError={hasProcessError}
        />
        {/* <Button
            title={t('buttons.proceed_payment')}
            hasArrow
            backgroundColor={'#139CD7'}
            overlayColor={'#002424'}
            onClick={createOrder}
            loading={payProcess}
            fullSize
          /> */}
        {/* </CardModalArea> */}

        {/* <InjectScript script={paymentScriptRef.current?.checkoutFormContent} /> */}
        {/* <PaymentModal
          detail={paymentScriptRef.current}
        /> */}
      </Modal>

    </Container>
  )
};

export default CheckoutSection;
