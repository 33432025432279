import React from 'react';
import Calendar from 'components/Calendar';
import { TextButton } from 'components/Button';
import { ReactComponent as Minus } from 'assets/img/icons/minus.svg';
import { ReactComponent as Plus } from 'assets/img/icons/plus.svg';
import {
  Container,
  Cards,
  CalendarArea,
  Card,
  CardHeader,
  Sessions,
  CardTitle,
  SessionBox,
  SessionContent,
  SessionInfo,
  SessionImage,
  SessionTexts,
  SessionSelect,
  SessionRight,
  GuestsContainer,
  GuestTitle,
  GuestContent,
  CounterArea,
  CountText,
  ButtonContainer,
  SessionInner,
  NoDateArea
} from './style';
import moment from 'moment';
import { LanguageContext } from 'contexts/Languages';
import { BookingContext } from 'contexts/Booking';

import { bookingCalendar, bookingOnDay } from 'services/booking'; // TODO :: delete
import { formatPrice, getLocalStorage } from 'helpers';
import Loader from 'components/Loader';

const DateSection = ({
  nextSection,
  order,
  handleChangeOrder,
  t
}) => {
  const { language } = React.useContext(LanguageContext);
  const { createCard } = React.useContext(BookingContext);
  const calenderRef = React.useRef(null);
  const [calendarHeight, setCalendarHeight] = React.useState(0);
  const [availableDates, setAvailableDates] = React.useState({ data: [], isLoading: false });
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [sections, setSections] = React.useState({ data: [], isLoading: false });
  const [isProcess, setIsProcess] = React.useState(false);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      setCalendarHeight(calenderRef.current?.getHeight?.());
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDates.data, t]);

  const changeMonth = async (date) => {
    handleChangeOrder('date', null);
    calenderRef.current?.changeDay?.(null);
    handleChangeOrder('session', null);
    setSelectedDate(null);
    setAvailableDates({ data: [], isLoading: true });
    try {
      const selectedCalendar = new Date(`${date.month + 1}/1/${date.year}`);
      const tsDate = selectedCalendar.getTime() / 1000;
      const availables = await bookingCalendar({ lang: language.code, today: tsDate });

      setAvailableDates({ data: availables, isLoading: false });
    } catch (error) {
      console.log('DateSection :: change month error:', error);
    }
  };

  React.useEffect(() => {
    const localData = getLocalStorage('order');

    if (!!localData?.date) {
      console.log('availableDates', availableDates);
      const l = new Date(localData.date);
      if (order.session && availableDates.data.find(el => el._id === moment(order.session?.start_date).format('YYYY-MM-DD'))) {
        // setSelectedDate(order.session?.start_date);
        const d = new Date(order.session?.start_date);
        setSelectedDate(d);
        // calenderRef.current?.changeDay?.(d.getDate());
        setSections({ data: [order.session], isLoading: false });
        // setTimeout(() => {
        //   setSections({ data: [order.session], isLoading: false });
        // }, 500);
      } else {
        availableDates.data?.forEach?.(el => {
          const d = new Date(el._id);
          if (l.getMonth() === d.getMonth()
            && l.getDate() === d.getDate()
            && l.getFullYear() === d.getFullYear()) {
            calenderRef.current?.changeDay?.(d.getDate())
            // onChangeDate(d);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDates]);

  const getSessionsOnDay = async (date) => {
    setSections({ data: [], isLoading: true });
    try {
      // const selectedCalendar = new Date(`${date.month + 1}/1/${date.year}`);
      const tsDate = date.getTime() / 1000;
      const availables = await bookingOnDay({ lang: language.code, today: tsDate });

      setSections({ data: availables, isLoading: false });
    } catch (error) {
      setSections({ ...sections, isLoading: false });
      console.log('DateSection :: change month error:', error);
    }
  };

  const onChangeDate = async (date) => {
    setSelectedDate(date);
    // handleChangeOrder('date', date);
    handleChangeOrder('guests', 1);
    handleChangeOrder('session', null);
    getSessionsOnDay(date);
    // return;
    // setAvailableDates({ data: [], isLoading: true });
    // try {
    //   // const selectedCalendar = new Date(`${currentMY.month + 1}/1/${currentMY.year}`);
    //   const tsDate = date.getTime() / 1000;
    //   const availables = await bookingOnDay({ lang: language.code, today: tsDate });
    //   console.log('available :: ', availables);
    // } catch (error) {
    //   console.log('onChangeDate :: change date error:', error);
    // }
  };

  const formatDate = (date) => {
    const formatted = calenderRef.current?.formatDateToText(date);
    if (!date || formatted.day === 'Invalid date') return '';

    return `${formatted.date} ${formatted.month}`;
  };

  const handleSectionSelect = (session) => {
    if (session.id === order.session?.id) {
      // setSelectedSection(prev => ({ ...prev, section: null }));
      handleChangeOrder('session', null);
      return;
    }
    handleChangeOrder('guests', order.guests > session?.available_count ? session?.available_count : order.guests);
    handleChangeOrder('session', session);
    // setSelectedSection(prev => ({ section: session.id, guests: prev.guests > session.max_guests ? session.max_guests : prev.guests }));
  };

  const handleGuestsChange = (type) => {
    switch (type) {
      case 'minus':
        // setSelectedSection(prev => ({ ...prev, guests: prev.guests - 1 }));
        handleChangeOrder('guests', order.guests - 1);
        break;
      case 'plus':
        // setSelectedSection(prev => ({ ...prev, guests: prev.guests + 1 }));
        handleChangeOrder('guests', order.guests + 1);
        break;
      default:
        break;
    }
  };

  const check = () => {
    if (!order.session || order.guests < 1 || !selectedDate) {
      return false;
    }
    return true;
  };

  const handleContinue = async () => {
    setIsProcess(true);

    if (!check()) {
      console.error('credentials are not valid');
      return;
    }

    const card = {
      booking: order.session.id,
      unit: order.guests
    };

    try {
      if (!order.card || order.session?.id === card?.booking
        // && order.guests === card.unit
      ) {
        const bookingCard = await createCard(card);
        handleChangeOrder('card', bookingCard);
      }
      nextSection();
      setIsProcess(false);
      return;
    } catch (error) {
      setIsProcess(false);
      console.log('createCard Error', error);
    }
  };

  const formatTime = (t) => {
    // (${moment(t).utc().locale(language.code).format("z")})
    return `${moment(t).utc().locale(language.code).format("LT")}`;
  };

  const isDisabledContinue = React.useMemo(() => {
    if (!order.session || order.guests < 1 || !selectedDate) {
      return true;
    }
    return false;
  }, [order, selectedDate]);

  // const defaultDate = React.useCallback(() => {
  //   return order?.date || null;
  // }, []);

  return (
    <Container>
      <Cards>
        <CalendarArea>
          <Calendar
            ref={calenderRef}
            dayContainerStyle={{ fontSize: '13px' }}
            containerStyle={{ padding: 0 }}
            tableStyle={{ background: '#fff', width: '100%' }}
            changeDate={onChangeDate}
            onCalendarChange={changeMonth}
            availableDates={availableDates.data}
            // defaultDate={defaultDate()}
            loading={availableDates.isLoading}
            availableDays={[]}
          />
        </CalendarArea>
        <Card
          $calendarHeight={calendarHeight}
          $hasDate={!!selectedDate}
        >
          {selectedDate &&
            <CardHeader onClick={() => console.log('order', order)}>
              <CardTitle>{t('pages.booking.date_step.selected_date')} - {formatDate(selectedDate)}</CardTitle>
            </CardHeader>
          }

          {(!selectedDate && !sections.isLoading) && (
            <NoDateArea>
              <p>{t('pages.booking.date_step.select_date')}</p>
            </NoDateArea>
          )}

          {sections.isLoading && (
            <NoDateArea>
              <p>{t('common.please_wait')}</p>
              <Loader
                dotColor={'#fff'}
                containerStyle={{ marginLeft: '10px' }}
              />
            </NoDateArea>
          )}

          {(selectedDate && sections.data && sections.data.length > 0) && (
            <Sessions>
              {sections.data.map(session => (
                <SessionBox
                  key={session?.id}
                  $selected={order.session?.id === session?.id}
                >
                  <SessionContent
                    $selected={order.session?.id === session?.id}
                    onClick={() => handleSectionSelect(session)}
                  >
                    <SessionInfo>
                      <SessionImage src={session.image} alt='session' />
                      <SessionInner>
                        <SessionRight>
                          <SessionTexts
                            $sx={{
                              'span': {
                                color: '#139CD7'
                              },
                              'span > span': {
                                fontWeight: 200
                              },
                            }}
                          >
                            {session.name} <span>€{formatPrice(session.price)} | <span>{t('pages.booking.date_step.per_guest')}</span></span>
                          </SessionTexts>
                          <SessionTexts>{t('pages.booking.date_step.welcome')} {formatTime(session.start_date)} - {t('pages.booking.date_step.landing')} {formatTime(session.end_date)}</SessionTexts>
                          {/* <SessionTexts
                            $color={'#008D28'}
                            $fw={400}
                          >4 seats available</SessionTexts> */}
                        </SessionRight>
                        <SessionSelect
                          $isSelected={order.session?.id === session.id}
                          onClick={() => handleSectionSelect(session)}
                        ></SessionSelect>
                      </SessionInner>
                    </SessionInfo>
                  </SessionContent>

                  <GuestsContainer>
                    <GuestTitle>Guests</GuestTitle>
                    <GuestContent>
                      <CounterArea disabled={order.guests <= 1} onClick={() => handleGuestsChange('minus')}>
                        <Minus />
                      </CounterArea>
                      <CountText>{order.guests}</CountText>
                      <CounterArea disabled={session.available_count <= order.guests} onClick={() => handleGuestsChange('plus')}>
                        <Plus />
                      </CounterArea>
                    </GuestContent>
                  </GuestsContainer>
                </SessionBox>
              ))}
            </Sessions>
          )}

        </Card>
      </Cards>
      <ButtonContainer
        $hasDate={!!selectedDate}
      >
        <TextButton
          title={t('buttons.continue')}
          hasArrow
          color={'#139CD7'}
          onClick={handleContinue}
          loading={isProcess}
          disabled={isDisabledContinue}
        />
      </ButtonContainer>

    </Container>
  )
};

export default DateSection;
