import styled from 'styled-components';

export const Container = styled.section`
  padding: 40px 52px;
  /* padding-top: 40px; */
  width: 85%;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 100%;
    padding: 20px 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
    padding: 20px 16px;
  }
`;

export const TopSection = styled.div`
  /* display: flex; */
  /* justify-content: space-around; */
`;

export const BackTitle = styled.span`
  position: relative;
  user-select: none;
  top: 5px;
  z-index: -1;
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: clamp(2.75rem, 0.2596rem + 5.6923vw, 7.375rem);
  line-height: 174px;
  text-transform: capitalize;
  color: #E0E0E0;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    /* font-size: 70px; */
    line-height: 70px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    /* font-size: 60px; */
    line-height: 74px;
  }
`;

export const Title = styled.h1`
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 56px;
  text-transform: capitalize;
  color: #139CD7;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 40px;
    line-height: 60px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 24px;
    line-height: 30px;
  }
`;

export const SubTitle = styled.h2`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 80px;
  line-height: 94px;
  letter-spacing: -1.4px;
  color: #002424;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 50px;
    line-height: 56px;
    letter-spacing: -0.582741px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.582741px;
  }
`;

export const Description = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  display: flex;
  align-items: center;
  letter-spacing: -1.4px;
  color: #002424;
`;

export const Image = styled.img`
  /* width: calc(100% / 3); */
  cursor: pointer;
  user-select: none;

  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out;
  &:hover {
    opacity: .7;
  }
`;

export const Video = styled.video`
  cursor: pointer;
  user-select: none;

  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out;
  &:hover {
    opacity: .7;
  }

  &::-webkit-media-controls {
    display:none;
  }

  &::-webkit-media-controls-volume-slider {
    display:none;
  }

  &::-webkit-media-controls-mute-button {
    display:none;
  }
`;
