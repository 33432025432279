import styled from 'styled-components';

export const Container = styled.section`
  padding: 0 52px;
  padding-top: 40px;
  padding-bottom:38px; /*new*/
  width: 85%;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 100%;
    padding: 0 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
    padding: 0 16px;
  }
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    flex-direction: column;
    align-items: center;
  }
`;

export const Image = styled.img`
  width: 30%;
  padding-right: 68px;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  max-width: 500px;
  margin: 0 auto;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    box-sizing: border-box;
    padding: 0 30px;
    padding-bottom: 30px;
    width: 100%;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 50%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
  }
`;

export const BackTitle = styled.span`
  position: relative;
  user-select: none;
  top: 5px;
  z-index: -1;
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: 110px;
  line-height: 110px;
  text-transform: capitalize;
  color: #E0E0E0;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-weight: 400;
    font-size: 65px;
    line-height: 66px;
    top: 1px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-weight: 400;
    font-size: 75px;
    line-height: 76px;
    top: 1px;
  }
`;

export const Title = styled.h1`
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 56px;
  text-transform: capitalize;
  color: #139CD7;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-weight: 300;
    font-size: 44px;
    line-height: 52px;
    letter-spacing: -0.582741px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.582741px;
  }
`;

export const SubTitle = styled.h2`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 42px;
  line-height: 1.3;
  letter-spacing: -1.4px;
  color: #002424;
  padding-bottom: 16px;
  padding-top: 10px;
  /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    font-size: 50px;
    line-height: 50px;
    letter-spacing: -0.582741px;
    padding-bottom: 10px;
  }
 */
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 38px;
    /* line-height: 38px; */
    letter-spacing: -0.582741px;
  }
`;

export const Description = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  display: flex;
  align-items: center;
  letter-spacing: -1.4px;
  color: #002424;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    font-size: 30px;
    line-height: 39px;
    padding-top: 10px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 22px;
    /* line-height: 45px; */
    line-height: 25px;
    padding-right: 30px;
    padding-top: 10px;
  }
`;

export const DescriptionContent = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  display: flex;
  align-items: center;
  letter-spacing: -1.4px;
  color: #002424;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    font-size: 30px;
    line-height: 39px;
    padding-top: 10px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 22px;
    /* line-height: 45px; */
    line-height: 25px;
    padding-right: 30px;
    padding-top: 10px;
  }
`;

export const PackageContainer = styled.div`
  /* display: flex; */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  padding-top: 38px;
  
  display: grid;
  gap: 10;
  grid-template-columns: repeat(auto-fit, calc(100% / 2 - 10px));
  /* grid-template-columns: repeat(auto-fit, calc(100% / 3 - 10px)); */

  & > div:not(:last-child) {
    padding-right: 30px;
    border-right: 1px solid #C8C8C8;
  }

  @media only screen and (max-width: 1041px) {
    grid-template-columns: repeat(auto-fit, calc(100% / 2 - 10px));
  };
  
  @media only screen and (max-width: 731px) {
    grid-template-columns: repeat(auto-fit, calc(100% / 1 - 10px));
    & > div:not(:last-child) {
      padding-right: 0;
      border-right: none;
    }
  };
  
`;

export const PackageContent = styled.div`
  /* width: 400px; */
  /* width: calc(100% / 3 - 39px); */
`;

export const PackageType = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: -1.4px;
  text-transform: uppercase;
  color: #002424;
  padding-top: 38px;
  padding-bottom: 18px;
`;

export const PackageDescription = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #002424;
  padding-bottom: 18px;
`;

export const PackageList = styled.ul`
  list-style: none;
  padding-bottom: 38px;
`;

export const PackageItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 0;
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    min-width: 7px;
    margin-right: 15px;
    border: 2px solid #1A9DD8;
    border-radius: 100%;
  }
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #231F20;
`;
