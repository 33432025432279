import Helmet from 'react-helmet';
import propTypes from 'prop-types';

const Seo = ({ title, description, keywords, image, url }) => {
  return (
    <Helmet
      defaultTitle='Dinner in The Sky'
      // titleTemplate="Dinner in The Sky · %s"
      titleTemplate="%s · Dinner in The Sky"
      defer={false}
    >
      <title>{title}</title>
      <meta name='keyword' content={keywords} />
      <meta name="description" content={description} data-react-helmet="true" />
      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta property="og:description" content={description} data-react-helmet="true" />
      {url && <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/${url}`} />}
      <meta property="og:type" content="website" data-react-helmet="true" />
      {image && <meta property="og:image" content={image} data-react-helmet="true" />}
      {image && <meta property="og:image:type" content="image/png" />}

      <link rel="shortcut icon" type="image/x-icon" href={`/icons/favicon.ico`} />
      <link rel="icon" type="image/png" sizes="192x192" href={`/icons/logo192.png`} />
      <link rel="icon" type="image/png" sizes="512x512" href={`/icons/logo512.png`} />
      <link rel="mask-icon" href={`/icons/favicon.ico`} color="#139CD7" />

      <link rel="apple-touch-icon" sizes="192x192" href={`/icons/logo192.png`} />
      <link rel="apple-touch-icon" sizes="512x512" href={`/icons/logo512.png`} />

      <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/${window.location.pathname}`} />

    </Helmet>
  )
};

Seo.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  keywords: propTypes.string,
  image: propTypes.string,
  url: propTypes.string,
};

Seo.defaultProps = {
  title: '',
  description: 'Gökyüzünde unutulmaz bir gastronomik lezzet deneyimi',
  keywords: 'Dinner in The Sky',
  image: null,
  url: '',
};

export default Seo;
