import React from 'react';
import {
  Dot,
  Loader
} from './style';

const LoaderUI = ({
  dotColor,
  containerStyle,
  dotSize
}) => {
  return (
    <Loader $sx={containerStyle} $dotSize={dotSize}>
      <Dot $dotColor={dotColor} />
      <Dot $dotColor={dotColor} />
      <Dot $dotColor={dotColor} />
      <Dot $dotColor={dotColor} />
      <Dot $dotColor={dotColor} />
      <Dot $dotColor={dotColor} />
    </Loader>
  )
};

LoaderUI.defaultProps = {
  dotColor: '#139cd7',
  containerStyle: {},
  dotSize: '20px'
};

export default LoaderUI;
