import styled from 'styled-components';

export const BillingContainer = styled.div`

`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 9px 19px;
  height: 46px;
  border-bottom: 1.04189px dashed #898C8D;
`;

export const SectionTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #002424;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 25px;
  padding-top: 20px;
  padding: 18px 19px;
  padding-bottom: 0;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const InputFContainer = styled.div`
  padding: 0px 19px;
  padding-bottom: 18px;
`;

export const ConsentsArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 25px;
  .consent-btn {
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    grid-template-columns: repeat(1, 1fr);
  }
`;
