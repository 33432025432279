import React from 'react';
import ExperienceImage from 'assets/img/image/experience-section-2.png';
// import ExperienceImage from 'assets/img/image/experience-section.png';
import {
  Container,
  TopSection,
  Image,
  Content,
  BackTitle,
  Title,
  SubTitle,
  Description,
  DescriptionContent,
  PackageContainer,
  PackageContent,
  PackageType,
  PackageDescription,
  PackageList,
  PackageItem,
} from './style';
import { TextButton } from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import Modal from 'components/Modal';

const Experience = ({
  sectionId,
  t
}) => {
  const nav = useNavigate();
  const bookingModalRef = React.useRef();

  const handleBookModal = (type) => {
    bookingModalRef.current.open();
  }

  const packages = React.useMemo(() => {
    const lunchPg = t('pages.booking.checkout_section.summary.lunch');
    const lunchBrief = t('pages.booking.checkout_section.lunch_description');

    const dinnerPg = t('pages.booking.checkout_section.summary.dinner');
    const dinnerBrief = t('pages.booking.checkout_section.dinner_description');

    const cocktailPg = t('pages.booking.checkout_section.summary.cocktail');
    const cocktailBrief = t('pages.booking.checkout_section.cocktail_description');
    return [
      {
        // type: t('pages.booking.checkout_section.lunch'),
        type: 'lunch',
        brief: lunchBrief,
        included: lunchPg
      },
      {
        // type: t('pages.booking.checkout_section.dinner'),
        type: 'dinner',
        brief: dinnerBrief,
        included: dinnerPg
      }
      // {
      //   // type: t('pages.booking.checkout_section.cocktail'),
      //   type: 'cocktail',
      //   brief: cocktailBrief,
      //   included: cocktailPg
      // },
    ];
  }, [t]);

  return (
    <Container id={sectionId}>
      <Modal
        ref={bookingModalRef}
        size={'md'}
        modalType={'booking'}
      />
      <TopSection>
        <Image src={ExperienceImage} alt='Experience' />
        <Content>
          <BackTitle>{t('common.cappadocia')}</BackTitle>
          <Title>{t('pages.home.experience_section.title')}</Title>
          <SubTitle>{t('pages.home.experience_section.sub_title')}</SubTitle>
          <Description>{t('pages.home.experience_section.text')}</Description>
          <DescriptionContent>{t('pages.home.experience_section.desc')}</DescriptionContent>
        </Content>
      </TopSection>

      {/* <PackageContainer>
        {packages.map((packageItem, index) => (
          <PackageContent key={index}>
            <PackageType>{t(`buttons.${packageItem.type}`)}</PackageType>
            <PackageDescription>
              {packageItem.brief}
            </PackageDescription>
            <TextButton
              color={'#139CD7'}
              hasArrow
              title={t(`pages.home.experience_section.book_ur_${packageItem.type}`)}
              onClick={() => nav(PATHS.BOOKING)}
              // onClick={() => handleBookModal(packageItem.type)}
              containerStyle={{ marginBottom: '24px' }}
            />
            <PackageList>
              {packageItem.included.map((item, index) => (
                <PackageItem key={index}>{item}</PackageItem>
              ))}
            </PackageList>
          </PackageContent>
        ))}
      </PackageContainer> */}
    </Container>
  )
};

export default Experience;
