import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Header = styled.div`

`;

export const MealText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 27.8557px;
  line-height: 35px;
  text-transform: uppercase;
  color: #231F20;
  padding-bottom: 20px;
`;

export const MenuArea = styled.div`
  /* display: flex;
  flex-direction: row;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column;
    align-items: center;
  } */
`;

export const MenuContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 485px;
  overflow-y: auto; */
  /* margin-top: 30px; */
  /* box-sizing: border-box;
  padding: 10px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-height: unset;
  } */
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const FoodName = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 20.26px;
  line-height: 30.39px;
  color: #231F20;
`;

export const FoodTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 20.26px;
  line-height: 30.39px;
  color: #231F20;
`;

export const FoodDescription = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-size: 20.26px;
  margin-top: 2px;
  color: #231F20;
`;

export const ImageContainer = styled.div`
  /* width: 427.97px; */
  /* height: 484.94px; */
  background: rgba(26, 157, 216, 0.1);
  flex: none;
  flex-grow: 0;
  margin-right: 23px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const Img = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  display:block;
  flex-shrink: 0 !important;
  border-radius:10px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    height: 200px;
  }
`;

export const SectionTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: bold;
  text-decoration: underline;
  text-decoration: overline;
  font-size: 30px;
  line-height: 42px;
  color: #231F20;
  /* padding-bottom: 10px; */
`;
export const MenuSection = styled.div`
  margin-bottom: 30px;
  display:flex;
  align-items:center;
  gap:16px;
  flex-direction:row;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction:column;
    gap:24px;
    
  }
  .columnItem {
    flex-grow: 1 !important;
    width:100%
  }
  /* &:not(:last-child) {
    border-bottom: 1px solid #231F20;
  } */
`;
