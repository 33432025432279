import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import BackImg from 'assets/img/image/contact-cloud.png';
import MapImg from 'assets/img/image/map-2.png';
// import MapImg from 'assets/img/image/map.png';
import TextInput from 'components/TextInput';
import { TextButton } from 'components/Button';
import CheckBox, { propagation } from 'components/CheckBox';
import Modal from 'components/Modal';
import Success from './components/Success';
import { BookingContext } from 'contexts/Booking';
import {
  Container,
  BackImage,
  TopSection,
  BackTitle,
  Title,
  SubTitle,
  Content,
  ImageArea,
  // BigBalloonImage,
  // SmallBalloonImage,
  ContactContainer,
  MapImage,
  Left,
  Right,
  CardTitle,
  CardDescription,
  AddressArea,
  AddressText,
  ContactCard,
  Row,
  ButtonArea
} from './style';

const FORM_SCHEMA = {
  firstName: { value: '', error: '' },
  lastName: { value: '', error: '' },
  email: { value: '', error: '' },
  phone: { value: '', error: '' },
  comment: { value: '', error: '' },
  consents: {
    newsletters: { value: false, error: null, isRequired: false }
  }
};

const Contact = ({
  sectionId,
  t
}) => {
  const { sendContactForm } = React.useContext(BookingContext);
  const [form, setForm] = React.useState(FORM_SCHEMA);
  const [status, setStatus] = React.useState({ success: false, error: null, isProcess: false });
  const consentModalRef = React.useRef(null);
  const reCaptchaRef = React.useRef();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: { value, error: '' } });
  };

  const sendForm = async () => {
    console.log(form);
    const newForm = { ...form };
    let hasError = false;
    Object.keys(newForm).forEach((key) => {
      if (typeof newForm[key] === 'boolean') {
        return;
      }
      if (key === 'consents') {
        Object.keys(newForm[key]).forEach((consentKey) => {
          if (newForm[key][consentKey].isRequired && !newForm[key][consentKey].value) {
            // newForm[key][consentKey].error = 'This field is required';
            newForm[key][consentKey].error = t('textboxes.required');
            hasError = true;
          }
        });
        return;
      }
      if (!newForm[key].value) {
        // newForm[key].error = 'This field is required';
        newForm[key].error = t('textboxes.required');
        hasError = true;
      }
    });

    if (hasError) {
      setForm(newForm);
      return;
    }

    const token = reCaptchaRef.current.getValue();
    if (token === "") {
      reCaptchaRef.current.reset();
      return;
    };

    const payload = {
      name: form.firstName.value,
      surname: form.lastName.value,
      email: form.email.value,
      phone: form.phone.value,
      comment: form.comment.value,
      privacy_policy: form.consents.newsletters.value,
      // privacy_policy: form.consents.privacy.value,
    }

    setStatus(prev => ({ ...prev, isProcess: true }));
    try {
      await sendContactForm({ payload });
      setStatus({ success: true, error: null, isProcess: false });
    } catch (error) {
      setStatus({ success: false, error: error, isProcess: false });
    }
  };

  const openConsentModal = () => {
    consentModalRef.current.open();
  };

  const renderConsentText = (k) => {
    const translated = t(`pages.home.contact_section.${k}`);
    const clickStart = translated.indexOf('<0>') + 3;
    const clickEnd = translated.indexOf('</0>');
    const clickText = translated.substring(clickStart, clickEnd);
    const beforeClick = translated.substring(0, clickStart - 3);
    const afterClick = translated.substring(clickEnd + 4);
    // const clickableElem = React.createElement('span', { className: 'consent-btn', onClick: () => console.log('click') }, beforeClick + clickText + afterClick);
    const clickableElem = React.createElement('span', null,
      // React.createElement('span', {},
      React.createElement('span', {}, beforeClick),
      React.createElement('span', {
        className: 'consent-btn', onClick: (e) => {
          propagation(e);
          openConsentModal();
        }
      }, clickText),
      React.createElement('span', {}, afterClick)
      // )
    );
    return clickableElem;
  };

  const reSendForm = () => {
    setStatus({ success: false, error: null, isProcess: false });
    setForm(FORM_SCHEMA);
    reCaptchaRef.current?.reset?.();
  };

  const handleCapchaChange = (value) => {
    console.log("Captcha value:", value);
  }

  const asyncScriptOnLoad = () => {
    console.log('init recaptcha');
  };

  return (
    <Container id={sectionId}>
      <BackImage src={BackImg} />
      <Modal
        modalType={'consent'}
        subProp={{
          type: 'kvkk'
        }}
        ref={consentModalRef}
      />
      <TopSection>
        <ImageArea>
          {/* <BigBalloonImage src={Balloon1} alt="balloon" /> */}
          {/* <SmallBalloonImage src={Balloon2} alt="balloon" /> */}
        </ImageArea>
        <Content>
          <BackTitle>{t('common.project_name')}</BackTitle>
          <Title>{t('common.contact')}</Title>
          <SubTitle>{t('pages.home.contact_section.sub_title')}</SubTitle>
        </Content>
      </TopSection>

      <ContactContainer>
        <Left>
          <MapImage src={MapImg} />
        </Left>

        <Right>
          <CardTitle>
            {t('pages.home.contact_section.section_title')}
          </CardTitle>
          <CardDescription>
            {t('pages.home.contact_section.title')}
          </CardDescription>

          <AddressArea>
            <AddressText>
              {t('pages.home.contact_section.address')}:{'\n'}
              Cumhuriyet mahallesi, Zelve cad. No:2 Taşköprü Avanos Nevşehir/TÜRKİYE. Bigchefs Otoparkı
            </AddressText>
            <AddressText>
              {t('pages.home.contact_section.phone_email')}:{'\n'}
              +90 535 470 1446 | info@dinnerintheskycappadocia.com.tr
            </AddressText>
          </AddressArea>

          <ContactCard>
            {status.success && (<Success t={t} reSendForm={reSendForm} />)}

            {(!status.success) && (
              <React.Fragment>
                <Row>
                  <TextInput
                    name={'firstName'}
                    value={form.firstName.value}
                    onChange={handleChange}
                    // placeholder={'First Name'}
                    placeholder={t('textboxes.first_name')}
                    containerStyle={{ marginRight: 20 }}
                    fullWidth
                    error={form.firstName.error}
                  />
                  <TextInput
                    name={'lastName'}
                    value={form.lastName.value}
                    onChange={handleChange}
                    // placeholder={'Last Name'}
                    placeholder={t('textboxes.last_name')}
                    fullWidth
                    error={form.lastName.error}
                  />
                </Row>

                <Row $responsive>
                  <TextInput
                    name={'email'}
                    value={form.email.value}
                    onChange={handleChange}
                    // placeholder={'Email'}
                    placeholder={t('textboxes.email')}
                    containerStyle={{ marginRight: 20 }}
                    fullWidth
                    error={form.email.error}
                  />
                  <TextInput
                    name={'phone'}
                    autoComplete={'tel'}
                    inputMode={'tel'}
                    value={form.phone.value}
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        handleChange(e);
                      }
                    }}
                    // placeholder={'Phone'}
                    placeholder={t('textboxes.phone')}
                    fullWidth
                    error={form.phone.error}
                  />
                </Row>

                <TextInput
                  name={'comment'}
                  value={form.comment.value}
                  onChange={handleChange}
                  // placeholder={'Comment'}
                  placeholder={t('textboxes.comment')}
                  fullWidth
                  error={form.comment.error}
                  multiline
                  maxRows={4}
                  inputProps={{ maxLength: 500 }}
                  inputStyle={{ margin: 0 }}
                />
                <CheckBox
                  checked={form.consents.newsletters.value}
                  setChecked={e => {
                    setForm({ ...form, consents: { ...form.consents, newsletters: { ...form.consents.newsletters, value: e, error: null } } });
                  }}
                  containerStyle={{ paddingBottom: '55px', paddingTop: '20px' }}
                  // label={<span>{renderConsentText('consent_text')}</span>}
                  label={renderConsentText('consent_text')}
                  error={form.consents.newsletters.error}
                />
                <ButtonArea>
                  <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    ref={reCaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleCapchaChange}
                    asyncScriptOnLoad={asyncScriptOnLoad}
                  />
                  <div>
                    <TextButton
                      title={t('buttons.send_now')}
                      hasArrow
                      onClick={sendForm}
                      containerStyle={{ float: 'right' }}
                      loading={status.isProcess}
                      dotColor={'#002424'}
                    />
                  </div>
                </ButtonArea>
              </React.Fragment>
            )}
          </ContactCard>

        </Right>

      </ContactContainer>
    </Container>
  )
};

export default Contact;
