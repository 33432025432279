import styled from 'styled-components';
import ForwardIcon from 'assets/img/icons/forward-arrow.svg';

export const Container = styled.section`
  position: relative;
  padding-top: 40px;
  /* width: 85%; */
  /* margin: 0 auto; */
  box-sizing: border-box;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-around;
  width: 85%;
  margin: 0 auto;
  padding: 0 52px;
  box-sizing: border-box;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 100%;
    padding: 0 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
    flex-direction: column-reverse;
    padding: 0 16px;
  }
`;

export const Content = styled.div`
  width: 70%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 60%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 30%;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  padding-left: 68px;
  max-width: 500px;
  margin: 0 auto;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    box-sizing: border-box;
    padding: 0 30px;
    padding-bottom: 30px;
    width: 100%;
  }
`;

export const BackTitle = styled.span`
  position: relative;
  user-select: none;
  top: 5px;
  z-index: -1;
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: clamp(2.75rem, 0.2596rem + 5.6923vw, 4.375rem);
  line-height: 144px;
  text-transform: capitalize;
  color: #E0E0E0;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    /* font-size: 84px; */
    line-height: 84px;
    top: 0px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    /* font-size: 60px; */
    line-height: 60px;
  }
`;

export const Title = styled.h1`
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 56px;
  text-transform: capitalize;
  color: #139CD7;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 40px;
    line-height: 40px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 24px;
    line-height: 24px;
  }
`;

export const SubText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 65px;
  line-height: 94px;
  letter-spacing: -1.4px;
  color: #002424;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 48px;
    line-height: 54px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 32px;
    line-height: 38px;
  }
`;

export const BriefText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 44px;
  letter-spacing: -1.4px;
  color: #002424;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.982741px;
    padding-top: 13px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.582741px;
  }
`;

export const MenuList = styled.ul`
  list-style:none;
  padding:0;
  margin:0;
  display:flex;
  align-items:center;
  gap:12px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size:16px;
  margin-top:24px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    gap:8px;
  }
  li {
    &:after {
      content: "/";
      padding-left:12px;
      color: ${({ theme }) => theme.colors.brand};
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
        padding-left:8px;
  }
    }
    &:last-child {
      &:after {
        content:"";
        padding-left:revert;
      }
    }
  } 
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  position: relative;
  box-sizing: border-box;
  .menu-meal-title {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.brand};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  padding-left: 54px;
  &::after {
    transition: width 0.2s ease-in-out;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    /* width: 30%; */
    width: 0;
    /* height: 96%; */
    height: 110px;
    background: #139CD7;
    opacity: 0.72;
    border-radius: 32px 0px 0px 32px;
    background-image: url(${ForwardIcon});
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    padding-left: 0;
  }
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    width: 100%;
    padding-left: 0;
    display: flex;
    justify-content: flex-end;
    &::after {
      border-radius: 55px 0px 0px 55px;
      height: 100%;
      background-position-x: 90%;
    }
  }
`;

export const MenuCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: transparent;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: #139CD722;
    ${ImageContainer} {
      &::after {
        width: 30%;
      }
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
        &::after {
          width: 320px;
          /* border-radius: 55px 0px 0px 55px; */
        }
      }
    }
  }
  box-sizing: border-box;
  padding: 30px calc(7.5% + 52px);

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    padding: 0 32px;
  }
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    padding: 30px 16px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MenuContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

export const MenuMeal = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 64px;
  letter-spacing: -1.4px;
  color: #002424;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 56px;
    line-height: 64px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 44px;
    line-height: 52px;
  }
`;

export const MenuItemName = styled.p`
      display:inline-block;
      border-bottom:2px solid transparent;
      transition:all .2s;
      color: ${({ theme }) => theme.colors.brand};
      padding-bottom:3px;
      text-align:center;
      &:hover {
        border-color:${({ theme }) => theme.colors.brand};
      }
`;

export const MenuBrief = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #002424;
  width: 40%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    width: 100%;
  }
`;

export const MenuTypeImage = styled.img`
  /* padding-left: 54px; */
  position: relative;
  width: fit-content;
  width: 334px;
  height: 110px;
  border-radius: 55px 0px 0px 55px;
  object-fit: cover;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    width: 320px;
  }
`;

export const CloudImage = styled.img`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  user-select: none;
  pointer-events: none;
`;
