import React from 'react';
import { useCookies } from 'react-cookie';
import Switch from 'components/Switch';
import Button, { TextButton } from 'components/Button';
import {
  SettingsTitle,
  CookieArea,
  SectionHead,
  SectionTitle,
  SectionText,
  Line,
  ButtonArea
} from './style';
import { LanguageContext } from 'contexts/Languages';

const CookieSettings = () => {
  const [cookies, setCookie] = useCookies(['demo']);
  const [checked, setChecked] = React.useState({
    funtional: true,
    performance: false
  });
  const { t } = React.useContext(LanguageContext);

  const changeCookieSetting = (key, value) => {
    setChecked(prev => ({ ...prev, [key]: value }));
  };

  const saveSelected = () => {
    console.log('cookies', cookies);
    setCookie('demo', 'demo content', { path: window.location.host, sameSite: 'strict' });
  };

  const applyAllCookies = () => {
    setCookie('demo', 'demo content', { path: window.location.host, sameSite: 'strict' });
  };

  return (
    <React.Fragment>
      {/* <SettingsTitle>Cookies settings</SettingsTitle> */}
      <SettingsTitle>{t('pages.cookie_management.cookie_customize_title')}</SettingsTitle>

      <SectionText>{t('pages.cookie_management.settings.title')}</SectionText>
      {/* <CookieArea>
        <SectionHead>
          <SectionTitle>STRICTLY NECESSARY COOKIES</SectionTitle>
          <SectionText>ALWAYS ACTIVE</SectionText>
        </SectionHead>
        <SectionText>These cookies are necessary for purely technical reasons for a normal visit to the website. Given the technical necessity, only an information obligation applies, and these cookies are placed as soon as you access the website.</SectionText>
        <Line />
      </CookieArea>

      <CookieArea>
        <SectionHead>
          <SectionTitle>FUNCTIONAL COOKIES</SectionTitle>
          <Switch
            toggle={val => changeCookieSetting('funtional', val)}
            checked={checked.funtional}
          />
        </SectionHead>
        <SectionText>You cannot refuse these cookies if you wish to browse this website, but they are only placed after a choice has been made regarding the placement of cookies.</SectionText>
        <Line />
      </CookieArea> */}

      <CookieArea>
        <SectionHead>
          {/* <SectionTitle>PERFORMANCE & TRACKING COOKIES</SectionTitle> */}
          <SectionTitle>{t('pages.cookie_management.settings.performance_title')}</SectionTitle>
          <Switch
            toggle={val => changeCookieSetting('performance', val)}
            checked={checked.performance}
          />
        </SectionHead>
        <SectionText>{t('pages.cookie_management.settings.performance')}</SectionText>
        <Line />
      </CookieArea>

      <ButtonArea>
        <TextButton
          title={t('pages.cookie_management.settings.save_settings')}
          color={'#002424'}
          onClick={saveSelected}
          hasArrow
          size={'sm'}
        />

        <Button
          title={t('pages.cookie_management.settings.accept_all')}
          onClick={applyAllCookies}
          backgroundColor={'#139CD7'}
          overlayColor={'#002424'}
          size={'sm'}
        />
      </ButtonArea>

    </React.Fragment>
  )
};

export default CookieSettings;
