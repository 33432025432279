import React, { useEffect } from 'react';
// import { ReactComponent as WarningIcon } from 'assets/img/icons/warning.svg';
import { ReactComponent as CongratIcon } from 'assets/img/icons/congrat.svg';
import { TextButton } from 'components/Button';
// import Dropdown from 'components/Dropdown';
import { currencySymbol, formatDateTime, formatPrice } from 'helpers';
import BalloonImg from "assets/img/image/booking-balloon.png";
import { useReactToPrint } from 'react-to-print';
import { useSearchParams } from 'react-router-dom';
import {
  Container,
  Content,
  Card,
  SectionHeader,
  CardContents,
  CardContent,
  SectionTitle,
  ReceiptContainer,
  ReceiptTitle,
  ButtonContainer,
  ReceiptOverallContainer,
  Line,
  ReceiptOverall,
  FoodCount,
  SessionDate,
  Price,
  Fee,
  ReceiptContent,
  Receipt,
  StepPrice,
  Box,

  List,
  SpecialList,
  ListItem,
  SpecialOccasionArea,
  SpecialTitle,
  // SpecialText,
  // SummaryInfoArea,
  // SummaryText,
  // SummaryWarning,
  CardCongrat,
  CongratArea,
  Text,
  BackTitle,
  Title,
  ImageArea,
  Image,
  Layout,
  SpecialRequest
} from './style';
import { BookingContext } from 'contexts/Booking';
import GuestMenu from 'pages/Booking/components/CheckoutSection/components/GuestMenu';
import { LanguageContext } from 'contexts/Languages';


const SuccessPayment = () => {
  const { t, changeLanguage } = React.useContext(LanguageContext);
  const [order, setOrder] = React.useState({ data: null, loading: true, error: null });
  const { getOrder } = React.useContext(BookingContext);
  const [searchParams] = useSearchParams();
  const componentRef = React.useRef();

  const getData = async (token) => {
    setOrder({ data: null, loading: true, error: null });
    try {
      const data = await getOrder(token);
      setOrder({ data, loading: false, error: null });
    } catch (error) {
      setOrder({ data: null, loading: false, error });
    }
  };

  React.useEffect(() => {
    getData(searchParams.get('token'));
  }, [changeLanguage])

  React.useEffect(() => {
    const token = searchParams.get('token');
    getData(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('token')])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'DinnerInTheSky-Receipt',
    pageStyle: '@page { size: A4 portrait; } @media print { body { -webkit-print-color-adjust: exact; } }'
  });

  React.useEffect(() => {
    window.localStorage.removeItem('order');
  }, [])

  if (order.error) {
    return (
      <Container>
        <Content>
          <Layout>
            <p>No Order Found</p>
          </Layout>
        </Content>
      </Container >
    )
  }

  if (order.loading) {
    return (
      <Container>
        <Content>
          <Layout>
            <p>Loading</p>
          </Layout>
        </Content>
      </Container >
    )
  }

  return (
    <Container>
      <ImageArea>
        <Image
          src={BalloonImg}
          alt={'Back balloon'}
        // sx={{
        //   position: 'absolute',
        // }}
        />
        {/* <Image
          src={CloudImg}
          alt={'Know more about our history'}
          sx={{
            width: '50%',
            position: 'relative',
            top: '-100px'
          }}
        /> */}
      </ImageArea>

      <Content>
        <div>
          <BackTitle>{t('common.cappadocia')}</BackTitle>
          <Title>{t('common.unique_experience')}</Title>
        </div>
        {(!order.error && !order.loading) && (
          <React.Fragment>
            <div ref={componentRef}>
              <Card $mb={'34px'} $pb={'23px'}>
                <SectionHeader>
                  {/* <SectionTitle>{t('pages.success.congratulations')}</SectionTitle> */}
                  <SectionTitle>{t('pages.success.sc_title')}</SectionTitle>
                </SectionHeader>
                <CardCongrat>
                  <CongratArea>
                    <CongratIcon />
                  </CongratArea>
                  <Text>
                    {t('pages.success.sc_desc')}
                    {`\n`}
                    {`\n`}
                    {t('pages.success.sc_gd')}
                  </Text>
                </CardCongrat>
              </Card>

              <Card>
                <CardContents>
                  <CardContent>
                    {order.data?.summary?.length > 0 &&
                      <React.Fragment>
                        <SectionHeader>
                          <SectionTitle>{t('pages.success.summary')}</SectionTitle>
                        </SectionHeader>

                        <List $sx={{ padding: '25px 19px' }}>
                          {order.data?.summary.map((item, index) => (
                            <ListItem key={index}>{item}</ListItem>
                          ))}
                        </List>
                      </React.Fragment>
                    }

                    {/* <SectionHeader>
                  <SectionTitle>Do You Have a Special Occasion?</SectionTitle>
                </SectionHeader> */}

                    {/* <Box>
                    deprecated
                  <Dropdown
                    label='Upgrade your expierence'
                    containerStyle={{
                      width: '100%',
                      marginRight: '20px',
                      paddingTop: '16px'
                    }}
                    disabled
                    // value={order.specialOccasion || { value: 'no', label: 'No' }}
                    defaultValue={order?.specialOccasion || { value: 'no', label: 'No' }}
                    options={[
                      { value: 'no', label: 'No' },
                      { value: 'birthday', label: 'Birthday' }
                    ]}
                  />
                  {order?.specialOccasion?.value &&
                    <SpecialOccasionArea>
                      <SpecialTitle>{order?.specialOccasion?.value} in the Sky</SpecialTitle>
                      <SpecialText>Birthday Cake / Music and Name on the led Screen
                        <SpecialText $brand>
                          €100.00 + €3.51 fee
                        </SpecialText>
                      </SpecialText>

                      <SpecialList>
                        {SUMMARY.map((item, index) => (
                          <ListItem key={index}>{item}</ListItem>
                        ))}
                      </SpecialList>
                    </SpecialOccasionArea>
                  }
                </Box> */}

                    <React.Fragment>
                      <SectionHeader>
                        <SectionTitle>{t('pages.success.guests')}</SectionTitle>
                      </SectionHeader>
                      <GuestMenu
                        guests={
                          order.data?.order_detail?.map((item) => ({
                            name: item.name_surname,
                            ...(item.starter_food && { starter_food: { label: item.starter_food.name } }),
                            ...(item.salad_food && { salad_food: { label: item.salad_food.name } }),
                            ...(item.main_food && { main_food: { label: item.main_food.name } }),
                            ...(item.dessert_food && { dessert_food: { label: item.dessert_food.name } }),
                            ...(item.cocktail_food && { cocktail_food: { label: item.cocktail_food.name } }),
                          }))
                        }
                      />
                    </React.Fragment>

                    {order.data?.special_request &&
                      <React.Fragment>
                        <SectionHeader>
                          <SectionTitle>{t('pages.success.special_requests')}</SectionTitle>
                        </SectionHeader>
                        <Box>
                          <SpecialRequest>{order.data?.special_request}</SpecialRequest>
                        </Box>
                      </React.Fragment>}

                    <SectionHeader>
                      <SpecialTitle>{t('pages.success.billing_info')}</SpecialTitle>
                    </SectionHeader>
                    <Box>
                      <SpecialOccasionArea>
                        {/* <SpecialTitle>Billing Information</SpecialTitle> */}
                        <SpecialList>
                          {Object.keys(order.data?.billing_info || {}).map((key, index) => (
                            <ListItem
                              $sx={key === 'address' && { gridColumnEnd: 'span 2' }}
                              key={index}
                            >{order.data?.billing_info[key]}</ListItem>
                          ))}
                        </SpecialList>
                      </SpecialOccasionArea>
                    </Box>
                  </CardContent>

                  <CardContent $bg={'#139CD7'} $padding={'30px 15px 20px 15px'}>
                    <ReceiptTitle>Dinner in the Sky</ReceiptTitle>
                    <ReceiptContainer>
                      <ReceiptOverallContainer>
                        <ReceiptOverall>
                          <FoodCount>{order.data?.unit} x {order.data?.booking.name}</FoodCount>
                          <SessionDate>{formatDateTime(order.data?.booking?.start_date)}</SessionDate>
                        </ReceiptOverall>
                        <ReceiptOverall $sx={{ alignItems: 'flex-end' }}>
                          <Price>€{formatPrice(order.data?.unit_price)}</Price>
                          {/* <Fee>+ €8.23 fee</Fee> */}
                        </ReceiptOverall>
                      </ReceiptOverallContainer>
                      {/* 
                    deprecated
                  {order?.specialOccasion?.value &&
                    <React.Fragment>
                      <Line $sx={{ marginBottom: '5px' }} />
                      <ReceiptOverallContainer>
                        <ReceiptOverall>
                          <FoodCount>{order?.specialOccasion?.value} in the Sky</FoodCount>
                          <SessionDate>Premium - June 25, 2023 at 9:30 PM</SessionDate>
                        </ReceiptOverall>
                        <ReceiptOverall $sx={{ alignItems: 'flex-end' }}>
                          <Price>€400.00</Price>
                          <Fee>+ €8.23 fee</Fee>
                        </ReceiptOverall>
                      </ReceiptOverallContainer>
                    </React.Fragment>
                  } */}
                      <Line />
                      <ReceiptContent>
                        {/* <Receipt>
                      <StepPrice>Subtotal</StepPrice>
                      <StepPrice>€695.00</StepPrice>
                    </Receipt>
                    <Receipt>
                      <StepPrice>VAT 12%</StepPrice>
                      <StepPrice>€39.09</StepPrice>
                    </Receipt>
                    <Receipt>
                      <StepPrice>VAT 12%</StepPrice>
                      <StepPrice>€74.30</StepPrice>
                    </Receipt> */}
                        <Receipt>
                          <StepPrice $bold>{t('pages.success.total')}</StepPrice>
                          {/* <StepPrice $bold>€793.00</StepPrice> */}
                          <StepPrice as={'span'} $bold $sx={{ textAlign: 'end' }}>
                            €{formatPrice(order.data?.unit_price * order.data?.unit)}
                            <Fee>{t('pages.success.tax_include')}</Fee>
                          </StepPrice>
                        </Receipt>
                        {order.data?.currency_type !== 'EUR' &&
                          <React.Fragment>
                            <Receipt>
                              <StepPrice>{t('pages.success.exchange_rate')}</StepPrice>
                              <StepPrice>1EUR = {formatPrice(order.data?.currency)}TRY</StepPrice>
                            </Receipt>
                            <Receipt>
                              <StepPrice $bold>{t('pages.success.try_total')}</StepPrice>
                              <StepPrice>₺{formatPrice((order.data?.unit_price * order.data?.unit) * order.data?.currency)}</StepPrice>
                            </Receipt>
                          </React.Fragment>
                        }

                        <Receipt>
                          <StepPrice $bold>{t('pages.success.total_paid')}</StepPrice>
                          <StepPrice $bold>{currencySymbol(order.data?.currency_type)}{formatPrice(order.data?.paid_price)}</StepPrice>
                        </Receipt>
                      </ReceiptContent>

                    </ReceiptContainer>

                    {/* <SummaryInfoArea>
                      <SummaryWarning>
                        <WarningIcon />
                        Please note that your guests will be seated across multiple tables.
                      </SummaryWarning>
                      <SummaryText>{DESCRIPTION}</SummaryText>
                    </SummaryInfoArea> */}
                  </CardContent>
                </CardContents>
              </Card>
            </div>
            <ButtonContainer>
              <TextButton
                title={t('pages.success.print')}
                color={'#139CD7'}
                hasArrow
                onClick={handlePrint}
              />
            </ButtonContainer>
          </React.Fragment>
        )}
        {/* {order.loading &&
          <Layout>
            <p>Loading</p>
          </Layout>
        }
        {order.error &&
          <Layout>
            <p>No Order Found</p>
          </Layout>
        } */}
      </Content>

    </Container>
  )
};

export default SuccessPayment;
