import React from 'react';
import SingleMedia from './components/SingleMedia';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { BookingContext } from 'contexts/Booking';
import Loader from 'components/Loader';
import {
  BackTitle,
  Container,
  SubTitle,
  Title,
  TopSection,
  Image,
  Video
} from './style';

// const randomUniqueIdGenerator = () => {
//   return Math.floor(Math.random() * 1000000);
// };

// const MEDIA = [
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'image',
//     source: 'https://unsplash.it/1560/740?image=23',
//   },
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'image',
//     source: 'https://unsplash.it/1560/740?image=24',
//   },
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'image',
//     source: 'https://unsplash.it/1260/740?image=25',
//   },
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'image',
//     source: 'https://unsplash.it/1360/440?image=26',
//   },
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'image',
//     source: 'https://unsplash.it/1560/740?image=27',
//   },
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'video',
//     source: 'http://techslides.com/demos/sample-videos/small.mp4',
//   },
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'image',
//     source: 'https://unsplash.it/1560/740?image=21',
//   },
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'image',
//     source: 'https://unsplash.it/1560/740?image=22',
//   },
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'video',
//     source: 'http://techslides.com/demos/sample-videos/small.mp4',
//   },
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'image',
//     source: 'https://unsplash.it/1560/740?image=21',
//   },
//   {
//     id: randomUniqueIdGenerator(),
//     type: 'image',
//     source: 'https://unsplash.it/1560/740?image=22',
//   },
// ];

const Gallery = ({
  sectionId,
  t
}) => {
  const { getGallery, gallery: { data: allMedia, loading: mediaLoading } } = React.useContext(BookingContext);
  const mediaRef = React.useRef(null);
  const [selectedMedia, setSelectedMedia] = React.useState(null);

  React.useEffect(() => {
    getGallery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openSingle = (data) => {
    setSelectedMedia(data);
    mediaRef.current.open();
  };

  return (
    <Container id={sectionId}>
      <TopSection>
        <BackTitle>{t('common.project_name')}</BackTitle>
        <Title>{t('pages.home.gallery_section.title')}</Title>
        <SubTitle>{t('pages.home.gallery_section.photos_videos')}</SubTitle>
      </TopSection>

      <SingleMedia
        ref={mediaRef}
        allMedia={allMedia}
        data={selectedMedia}
        onClose={() => setSelectedMedia(null)}
      />

      {mediaLoading ?
        (<Loader dotSize={'30px'} />)
        :
        (allMedia?.length > 0
          ?
          (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 2, 750: 4, 900: 4 }}
            >
              <Masonry
                columnsCount={2}
                gutter={'10px'}
              >
                {allMedia.map((item, idx) => (
                  <React.Fragment key={`media_${idx}`}>
                    {item.type === 'image' ?
                      (<Image
                        src={item.source}
                        alt={`gallery ${idx}`}
                        onClick={() => openSingle(item)}
                      />)
                      :
                      (<Video
                        src={item.source}
                        controlsList='nodownload noplaybackrate nofullscreen'
                        disablePictureInPicture
                        disableRemotePlayback
                        onClick={() => openSingle(item)}
                      />)
                    }
                  </React.Fragment>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )
          :
          (<div>No media found</div>)
        )
      }

      {/* <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
      >
        <Masonry>
          {images.map((item, idx) => (
            <img src={item.source} alt='de' />
          ))}
        </Masonry>
      </ResponsiveMasonry> */}
      {/*
      <GalleryWrapper>
        <MediaRow>
          {MEDIA.map((media, idx) => renderMediaRow(media, idx))}
        </MediaRow>

      </GalleryWrapper> */}
    </Container>
  )
};

export default Gallery;
