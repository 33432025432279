export const policyEn = `
Last updated: 30/05/2023
As SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ (“Company”), we benefit from certain technologies such as cookies, pixels, gifs, etc. (“cookies”) in order to improve your experience during your visits to our online channels. The use of such technologies is carried out in accordance with the applicable legislation, in particular the Law on the Protection of Personal Data No. 6698 (the “PDP Law”).
This Cookie Policy is intended to inform you about the processing of your personal data which are obtained during our operation of “dinnerintheskycappadocia.com.tr.”” website (the “Site”) through the use of cookies by the Site users/members/visitors (“Data Subject”). We would like to herein describe which types of cookies are used on our Site, and for which purposes and how you may control such cookies.
As The Company, we may decide not to use cookies which are currently used on our Site, change the types or functions thereof, or add new cookies to our Site. Accordingly, we hereby reserve the right to amend the terms of this Cookie Policy at any time. Any and all kinds of amendments made on the current notice will become effective upon publication on any public channel. Please see the last update date on top of this notice.
For further information on the purposes for processing by the Company of your personal data, please refer to the Policy on the Protection and Processing of Personal Data which can be found at “dinnerintheskycappadocia.com.tr.”
What are Cookies?
Cookies are small data files set up in your computer by a web server, via your browser. Web sites use cookies to recognize you when a connection is established between your browser and the server. Cookies are designed to make a web site more convenient to use for its visitors.
For which Purposes Cookies are Used?
At The Company, we use cookies on our Site for various purposes, and we process personal data through such cookies, in particular, for the following purposes:
• Analyzing the Site and improving the performance of the Site. For instance, the integration of the different servers on which the Site operates, determination of the number of visitors of the Site and carrying out of performance settings accordingly or facilitation of the location by the visitors of what they are looking for.
Which Cookies do We Use?
The different types of cookies which are used by our Site are listed below. Our Site uses first-party “performance cookies” (which are placed by the site visited).
{###table###}
Information about cookies on the Website are given in the following tables:
{###table###}
How can I Control the Use of Cookies?
When you visit our Site, a banner which informs you about the use of cookies will appear, and by clicking “I accept all cookies” button, you agree to all cookies on our website. By clicking on “Change Settings” button, you can determine the types of cookies, depending on your preference. You can withdraw your consent or change cookie settings at any time.
If you block or reject our cookies through your browser settings, some features and services may not work due to technical barriers. Depending on the type of your internet browser, you can learn about cookies, exercise your right to allow or reject cookies, by following the steps below:
• Google Chrome: You can allow or block cookies from the “Cookie” tab by clicking on the “lock mark” or letter “i” in the “address section” of your browser.
• Internet Explorer: You can click on the “Tools” Section at the top right corner of your browser and click on the “Security” tab and manage your cookies by “allowing” or “disallowing” them.
• Mozilla Firefox: You can click on the “open menu” tab at the top right corner of your browser and click on the “Options” image and manage your cookies by using the “Privacy and Security” button.
• For other browsers (such as opera, Microsoft edge, etc.), you can review the help or support pages of the relevant browsers.
• Safari: You can select the “safari” tab from the Settings” section, and manage your cookies from the “Privacy and Security” Section.
• In addition to the options above, you can also visit https://www.allaboutcookies.org, https://www.youronlinechoices.eu/ in order to learn about all cookies and to manage cookies, or you can use the “Privacy Badger” application (https://www.eff.org/tr/privacybadger).
`;

export const tableEn = [
  {
    titles: ['Type of Cookies', 'Function of Cookie'],
    rows: ['Performance Cookies', 'These cookies allow the website owner to collect information on the frequency of the website visits, relevant error messages (if any), time that users spend on the pages and how the users use the website. This information may be used to improve the performance of the website.']
  },
  {
    titles: ['Cookies Service Provider', 'Cookies Name', 'Cookies Purpose', 'Cookies Type', 'Cookies Duration'],
    rows: ['Google', 'Google Analytics', 'Performance Cookies', 'First Party', '2 years']
  }
];

export const policyTr = `
Son güncelleme tarihi: 30/05/2023
SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ (“Şirket”) olarak, çevrimiçi mecralarımızı ziyaretleriniz sırasında sizlerin deneyiminizi geliştirmek için çerezler, pikseller, gifler gibi bazı teknolojilerden (“çerezler”) faydalanmaktayız. Bu teknolojilerin kullanımı başta 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVK Kanunu”) olmak üzere tabi olduğumuz mevzuata uygun şekilde gerçekleştirilmektedir.
İşbu Çerez Politikası metninin amacı, tarafımızca işletilmekte olan “dinnerintheskycappadocia.com.tr.” internet sitesinin (“Site”) işletilmesi sırasında Site kullanıcıları/üyeleri/ziyaretçileri (“Veri Sahibi/İlgili Kişi”) tarafından çerezlerin kullanımı sırasında elde edilen kişisel verilerin işlenmesine ilişkin olarak sizlere bilgi vermektir. İşbu metinde sitemizde hangi amaçlarla hangi tür çerezleri kullandığımızı ve bu çerezleri nasıl kontrol edebileceğinizi sizlere açıklamak istiyoruz.
Şirket olarak sitemizde kullandığımız çerezleri kullanmaktan vazgeçebilir, bunların türlerini veya fonksiyonlarını değiştirebilir veya sitemize yeni çerezler ekleyebiliriz. Dolayısıyla iş bu Çerez Politikası metninin hükümlerini dilediğimiz zaman değiştirme hakkını saklı tutarız. Güncel metin üzerinde gerçekleştirilmiş olan her türlü değişiklik sitede veya herhangi bir kamuya açık mecrada yayınlanmakla birlikte yürürlük kazanacaktır. Son güncelleme tarihini metnin başında bulabilirsiniz.
Kişisel verilerinizin Şirket tarafından işlenme amaçları hakkında daha detaylı bilgi için “dinnerintheskycappadocia.com.tr.” adresinde yer alan Kişisel Verilerin Korunması ve İşlenmesi Politikası’nı okumanızı tavsiye ederiz.
Çerez (Tanımlama Bilgileri) Nedir?
Tanımlama bilgileri, tarayıcınız aracılığıyla bir web sunucusu tarafından bilgisayarınıza yüklenen küçük veri dosyalarıdır. Web siteleri, tanımlama bilgilerini tarayıcınız ile sunucu arasında bağlantı kurulduğunda sizi tanımak için kullanır. Tanımlama bilgileri, bir web sitesinin ziyaretçileri tarafından daha kolay kullanılabilmesi için tasarlanır.
Çerezler Hangi Amaçlarla Kullanılmaktadır?
Şirket olarak sitemizde çeşitli amaçlarla çerezler kullanmakta ve bu çerezler vasıtasıyla kişisel verilerinizi işlemekteyiz. Bu amaçlar başlıca şunlardır:
• Site’yi analiz etmek ve Site’nin performansını arttırmak. Örneğin, Site’nin üzerinde çalıştığı farklı sunucuların entegrasyonu, Site’yi ziyaret edenlerin sayısının tespit edilmesi ve buna göre performans ayarlarının yapılması ya da ziyaretçilerin aradıklarını bulmalarının kolaylaştırılması.
Hangi Çerezleri Kullanıyoruz?
Aşağıda sitemizde kullandığımız türdeki çerezleri bulabilirsiniz. Sitemizde birinci parti çerezler (ziyaret ettiğiniz site tarafından yerleştirilen) ”Performans Tanımlama Bilgileri” kullanılmaktadır.
{###table###}
Sitede yer alan çerezlere ilişkin bilgiler aşağıdaki tablolarda yer almaktadır:
{###table###}
Çerezlerin Kullanımını Nasıl Kontrol Edebilirim?
Sitemizi ziyaret ettiğinizde, çerezlerin kullanımı hakkında sizi bilgilendiren bir uyarı görünür ve “Tüm çerezleri kabul ediyorum” tuşuna tıkladığınızda web sitemizdeki tüm çerezleri onaylamış olursunuz. “Ayarları değiştir” tuşuna tıklayarak çerez türlerini tercihinize göre belirleyebilirsiniz. Onayınızı istediğiniz zaman geri alabilir ve çerez ayarlarını değiştirebilirsiniz.
• İnternet tarayıcınızın çeşidine göre aşağıdaki adımları izleyerek, çerezler hakkında bilgi edinip, izin verme veya reddetme hakkınızı da kullanabilirsiniz. Tarayıcı ayarlarınız aracılığıyla çerezlerimizi engeller veya başka bir şekilde reddederseniz bazı özellikler ve hizmetler teknik engeller nedeniyle çalışmayabilir.Google Chrome: Tarayıcınızın “adres bölümünde” yer alan, “kilit işareti” veya “i” harfini tıklayarak, “Cookie” sekmesinden çerezlere izin verebilir veya engelleyebilirsiniz.
• İnternet Explorer: Tarayıcınızın sağ üst köşesinde yer alan “Tool” veya “Araçlar” Bölümünden “Güvenlik” sekmesini tıklayın ve “izin ver” veya “izin verme” şeklinde çerezleri yönetebilirsiniz.
• Mozilla Firefox: Tarayıcınızın sağ üst köşesinde yer alan “menüyü aç” sekmesini tıklayınız. “Seçenekler” görselini tıklayarak “Gizlilik ve Güvenlik” butonunu kullanarak çerez yönetiminizi yapabilirsiniz.
• Diğer tarayıcılar için de (opera, microsoft edge gibi), ilgili tarayıcının yardım veya destek sayfalarını inceleyebilirsiniz.
• Safari: Telefonunuzun “Ayarlar” bölümünden “safari” sekmesini seçip, “Gizlilik ve Güvenlik” Bölümünden tüm çerez yönetiminizi sağlayabilirsiniz.
• Yukarıdaki seçeneklerin yanısıra; tüm çerezler hakkında bilgi sahibi olmak ve çerez yönetimi için: https://www.allaboutcookies.org, https://www.youronlinechoices.eu/ adresini ziyaret edebilirsiniz veya "Privacy Badger" uygulamasını kullanabilirsiniz (https://www.eff.org/tr/privacybadger)
`;

export const tableTr = [
  {
    titles: ['Çerez Türü', 'Çerez İşlevi'],
    rows: ['Performans Tanımlama Bilgileri', 'Bu tanımlama bilgilerinin kullanılması, sayfa sahibinin sayfa ziyaretlerinin sıklığı, ilgili hata mesajları (varsa), kullanıcıların sayfalarda geçirdiği süre ve kullanıcıların web sitesinde nasıl gezindikleri gibi bilgilerin toplanmasını sağlar. Bu bilgiler, web sitesinin performansını iyileştirmek için kullanılabilir.']
  },
  {
    titles: ['Çerez Hizmet Sağlayıcı', 'Çerez İsmi', 'Çerez Amacı', 'Çerez Tipi', 'Çerez Süresi'],
    rows: ['Google', 'Google Analytics', 'Performans Tanımlama Bilgileri', 'Birinci Parti', '2 yıl']
  }
];
