import styled from 'styled-components';

export const Container = styled.div`
  max-width: 950px;
`;

export const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4.16756px 0px rgba(36, 141, 193, 0.15);
  border-radius: 25.0054px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 24px;
  height: 46px;
  border-bottom: 1.04189px dashed #898C8D;
`;

export const CardContent = styled.div`
  padding: 13px;
`;

export const CardTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #002424;
`;

export const WarningArea = styled.div`
  display: flex;
  align-items: center;
  p {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 1.2px;
    color: ${({ theme }) => theme.colors.error};
    padding-left: 15px;
  }
`;

export const TopWarningArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #C8C8C828;
  /* padding: 17px 24px; */
  padding: 17px 19px;
  p {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 1.8px;
    color: ${({ theme }) => theme.colors.error};
    padding-left: 15px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #C8C8C8;
  margin: 20px 0;
`;

export const GuestInfoContainer = styled.div`
  overflow: auto;
  border-bottom: 1px solid #C8C8C8;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding-top: 35px;
  display: flex;
  justify-content: flex-end;
  & > :not(:last-child) {
    margin-right: 32px;
  }
`;

export const SeatImage = styled.img`
  width: 100%;
  margin-bottom: 40px;
  border-radius: 0px 12px 12px 0px;
  user-select: none;
  pointer-events: none;
`;
