import styled, { css } from 'styled-components';
import CardBack from 'assets/img/image/corporate-back.png';

export const Container = styled.section`
  position: relative;
  padding-bottom: 50px;
  .consent-btn {
    cursor: pointer;
    text-decoration: underline
  }
`;

export const BackImage = styled.img`
  z-index: -1;
  position: absolute;
  width: 100%;
  top: -400px;
  user-select: none;
  pointer-events: none;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    position: relative;
    top: 0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){

  }
`;

export const TopSection = styled.div`
  padding: 0 52px;
  padding-top: 40px;
  width: 85%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 100%;
    padding: 0 32px;
    display: block;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
    padding: 0 16px;
  }
`;

export const BackTitle = styled.span`
  position: relative;
  user-select: none;
  top: 5px;
  z-index: -1;
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.75rem, 0.2596rem + 5.6923vw, 4.375rem);
  line-height: 144px;
  text-transform: capitalize;
  color: #E0E0E0;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    /* font-size: 70px; */
    line-height: 70px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    /* font-size: 60px; */
    line-height: 74px;
  }
`;

export const Title = styled.h1`
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 56px;
  text-transform: capitalize;
  color: #139CD7;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 40px;
    line-height: 60px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 24px;
    line-height: 30px;
  }
`;

export const SubTitle = styled.h2`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 80px;
  line-height: 94px;
  letter-spacing: -1.4px;
  color: #002424;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 50px;
    line-height: 56px;
    letter-spacing: -0.582741px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.582741px;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 80%;
  margin: none;
  /* TODO :: fix */
  @media only screen and ((max-width: 992px) and (min-width: 580px)){
    margin-top: -400px;
  }
`;

export const ImageArea = styled.div`
  position: relative;
  width: 50%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    position: unset;
  }
`;

export const BigBalloonImage = styled.img`
  user-select: none;
  pointer-events: none;
  position: relative;
  top: 0px;
  left: -50px;
  width: 25%;
  max-width: 200px;
  min-width: 120px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    /* top: 40px; */
    top: -80px;
    left: 20px;
    width: 25%;
    max-width: 240px;
    min-width: 120px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    position: absolute;
    top: 20px;
    left: 100px;
    width: 20%;
    max-width: 200px;
    min-width: 70px;
  }
`;

export const SmallBalloonImage = styled.img`
  user-select: none;
  pointer-events: none;
  position: relative;
  top: 100px;
  left: 40px;
  width: 35%;
  max-width: 130px;
  min-width: 50px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    top: 90px;
    top: 0px;
    left: 100px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    position: absolute;
    top: 200px;
    left: 60%;
    width: 20%;
    max-width: 120px;
    min-width: 50px;
  }
`;

export const ContactContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  min-height: 475px;
  background-image: url(${CardBack});
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;  
  box-sizing: border-box;

  display: flex;
  /* padding: 90px 127px; */
  padding-top: 53px;
  /* padding-bottom: 50px; */
  /* padding-left: 127px; */
  padding-left: 70px;
  padding-right: 107px;
  padding-bottom: 90px;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    padding: 53px 50px;
    padding-bottom: 90px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    flex-direction: column;
    width: 100%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    /* padding: 53px 50px; */
    padding-top: 53px;
    padding-bottom: 120px;
    padding-left: 50px;
    padding-right: 50px;
  }

`;

export const Left = styled.div`
  padding-right: 50px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    padding-right: 30px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    padding: 0;
  }
`;

export const Right = styled.div`

`;

export const MapImage = styled.img`
  min-width: 300px;
  user-select: none;
  pointer-events: none;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 100%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    min-width: 200px;
  }
`;

export const CardTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #002424;
  padding-bottom: 15px;
`;

export const CardDescription = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: #002424;
`;

export const AddressArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  &>:not(:last-child) {
    padding-right: 15px;
  }
`;

export const AddressText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #002424;
  word-break: break-word;
  white-space: break-spaces;
`;

export const ContactCard = styled.div`
  background-color: #fff;
  width: 100%;
  /* padding: 60px 15px; */
  margin-top: 30px;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Row = styled.div`
  display: flex;
  padding-bottom: 10px;
  ${({ $responsive }) => $responsive && css`
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
      flex-direction: column;
      &>:not(:last-child) {
        padding-bottom: 10px;
      }
    }
  `}
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;
