import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/arrow-right.svg';
import Btn from 'assets/img/icons/navigate-button.svg';

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 23;
  width: 100vw;
  height: 100vh;
`;

export const Container = styled.div`
  position: fixed;
  z-index: 23;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0;
  border: none;
  /* background: #fff; */
  box-shadow: 0px 12px 52px rgba(0, 0, 0, 0.12);
  
  max-height: 90vh;
  max-width: 90vw;
  min-width: 300px;
  min-height: 300px;

  height: 90vh;
  /* width: 90vw; */

  overflow: hidden;
  display: -webkit-box;
`;

export const Content = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const FSImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const FSVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const CloseButton = styled.button`
  position: fixed;
  z-index: 24;
  top: 0;
  right: 30px;
  transform: translate(50%, 50%);

  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;

  svg {
    width: 45px;
    height: 45px;
  }
`;

export const NavigateButton = styled.button`
  position: fixed;
  z-index: 24;
  top: 50%;
  right: ${({ $isRight }) => $isRight ? '44px' : 'null'};
  left: ${({ $isRight }) => !$isRight ? '0' : 'null'};
  transform: translate(50%, 50%);

  background-image: url(${Btn});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;


  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
/* 
  svg {
    width: 45px;
    height: 45px;
  } */
`;

export const Arrow = styled(ArrowIcon)`
  position: absolute;
  ${({ $isRight }) => !$isRight && `
    transform: rotate(180deg);
  `}
`;

/* width: ${({ $size, theme }) => sizeGenenarate($size, theme.breakpoints).width}; */
/* height: ${({ $size, theme }) => sizeGenenarate($size, theme.breakpoints).height}; */
/* min-height: ${({ $size, theme }) => sizeGenenarate($size, theme.breakpoints).height}; */