import { createContext, useContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

const AppControl = createContext();

AppControl.displayName = "AppControlContext";

function reducer(state, action) {
  switch (action.type) {
    case "NAVMENU_STATUS": {
      return { ...state, isMenuOpen: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

function AppControlProvider({ children }) {
  const initialState = {
    isMenuOpen: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <AppControl.Provider value={value}>{children}</AppControl.Provider>;
};

function useAppControl() {
  const context = useContext(AppControl);

  if (!context) {
    throw new Error(
      "useAppControl should be used inside the AppControlProvider."
    );
  }

  return context;
};

AppControlProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const setMenuStatus = (dispatch, value) => dispatch({ type: "NAVMENU_STATUS", value });

export {
  AppControlProvider,
  useAppControl,
  setMenuStatus
};
