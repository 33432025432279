import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Main } from './style';

const AppLayout = ({
  children
}) => {
  const headerRef = React.useRef();
  const [headerHeight, setheaderHeight] = React.useState(0);

  React.useLayoutEffect(() => {
    const { height } = headerRef.current.getBoundingClientRect();
    setheaderHeight(height);
  }, []);

  return (
    <React.Fragment>
      <Header ref={headerRef} />
      <Main
        $customStyle={{ paddingTop: `${headerHeight}px` }}
      >
        {children}
      </Main>
      <Footer />
    </React.Fragment>
  )
};

export default AppLayout;
