import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Title = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 1.2px;
  color: #000000;
`;

export const SubTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  letter-spacing: 1.2px;
  color: #000000;
  padding: 10px 0;
`;

export const Context = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  letter-spacing: 1.2px;
  color: #000000;
  white-space: break-spaces;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 10px 0;
  border: 1px solid #E9EBF1;
`;

export const Th = styled.th`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  color: #000000;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #E9EBF1;
  border: 1px solid #21212115;
`;

export const Td = styled.td`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18.75px;
  color: #000000;
  white-space: break-spaces;
  border: 1px solid #21212115;
`;
