import styled, { css, keyframes } from 'styled-components';

const TABLE_RADIUS = '16px';
const PASSIVE_COLOR = '#C8C8C8';

export const Wrapper = styled.div`
  padding-right: 24px;
  /* background: pink; */
  border-bottom-left-radius: ${TABLE_RADIUS};
  border-bottom-right-radius: ${TABLE_RADIUS};
  border-top-left-radius: ${TABLE_RADIUS};
  ${({ $containerStyle }) => $containerStyle};
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: ${TABLE_RADIUS};
  ${({ $tableStyle }) => $tableStyle};
`;

export const Thead = styled.thead`
  user-select: none;
`;

export const TableTitleRow = styled.tr`

`;

export const WeekDayText = styled.th`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 14.5865px;
  line-height: 19px;
  text-transform: uppercase;
  text-align: center;
  width: calc(100% /7);
  padding-bottom: 8px;
  padding-top: 10px;
  color: #C8C8C8;  
`;

export const DayTd = styled.td`
  background-color: transparent;
  width: calc(100% / 7);  // TODO :: 7.2?
`;

export const DayBox = styled.button`
  outline: none;
  border: none;
  background-color: #fff;
  display: grid;
  place-items: center;
  width: 52px;
  height: 52px;
  margin: 5px;
  border-width: 2px;
  border-style: solid;
  ${({ $passive }) => $passive && css`
    border: none;
  `}
  cursor: ${({ $passive }) => $passive ? 'default' : 'pointer'};
  border-color: ${({ theme, $isAvailable, $selected }) => ($isAvailable || $selected) ? theme.colors.brand : PASSIVE_COLOR};
  background-color: ${({ theme, $selected, $isAvailable, $passive }) => $selected ? theme.colors.brand : (($isAvailable || $passive) ? '#fff' : PASSIVE_COLOR)};
  border-radius: 100%;

  span {
    ${({ $selected }) => $selected && `
      color: #fff;
      opacity: 1;
    `};
  }
  ${({ $dayContainerStyle }) => $dayContainerStyle};

  @media only screen and (max-width: 455px){
    width: 45px;
    height: 45px;
    margin: 2.5px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xxl}px){
    width: 45px;
    height: 45px;
    margin: 2px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    /* TODO:  */
    width: 45px;
    height: 45px;
    margin: 2px;
  }
  /* border-radius: 200px; */
`;

export const DayText = styled.span`
  display: grid;
  place-items: center;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 16.6702px;
  line-height: 21px;

  /* color: ${({ theme, $passive, $isAvailable }) => $passive ? '#B0B7C3' : '#030229'}; */
  opacity: ${({ $passive }) => $passive ? '0.3' : '0.7'};
  user-select: none;
  color: ${({ theme, $isAvailable }) => $isAvailable ? theme.colors.brand : '#002424'};

  ${({ $passive }) => $passive && css`
    color: ${PASSIVE_COLOR};
  `}
`;

export const Tbody = styled.tbody`
  
`;

export const TitleMonthYear = styled.div`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #002424;
`;

export const Navigations = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5%;
  position: relative;
  left: 5px;
`;

export const NavButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  padding: 5px;
  margin-right: ${({ $isLeft }) => $isLeft ? '10px' : '0'};;
  svg {
    transform: ${({ $isLeft }) => $isLeft ? 'rotate(180deg)' : 'rotate(0)'};
    path {
      fill: ${({ disabled }) => disabled ? '#C8C8C8' : '#002424'};
    }
  }
`;

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-left: 24px;
  border-bottom: 1.04189px dashed #898C8D;
  height: 46px;
  box-sizing: border-box;
`;

const spin = keyframes`
  100% {
    transform: rotate(360deg); 
  }
`;

const dotSpin = keyframes`
  80%, 100% {
    transform: rotate(360deg);
  }
`;

const scaleDot = keyframes`
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  }
`;

export const LoaderWrapper = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
  animation: ${spin} 2.5s infinite linear both;
  margin: 0 auto;
  margin-left: 10px;
`;

export const Dot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: ${dotSpin} 2.0s infinite ease-in-out both; 
  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #203662;
    border-radius: 100%;
    animation: ${scaleDot} 2.0s infinite ease-in-out both; 
  }
  &:nth-child(1) { animation-delay: -1.1s; }
  &:nth-child(2) { animation-delay: -1.0s; }
  &:nth-child(3) { animation-delay: -0.9s; }
  &:nth-child(4) { animation-delay: -0.8s; }
  &:nth-child(5) { animation-delay: -0.7s; }
  &:nth-child(6) { animation-delay: -0.6s; }
  &:nth-child(1):before { animation-delay: -1.1s; }
  &:nth-child(2):before { animation-delay: -1.0s; }
  &:nth-child(3):before { animation-delay: -0.9s; }
  &:nth-child(4):before { animation-delay: -0.8s; }
  &:nth-child(5):before { animation-delay: -0.7s; }
  &:nth-child(6):before { animation-delay: -0.6s; }
`;
