import styled, { css } from 'styled-components'

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #FFFFFF;
  border: 1px solid ${({ checked, theme }) => (checked ? theme.colors.brand : '#002424')};
  /* border-radius: ${props => (props.checked ? '4px' : '8px')}; */
  background-image: ${({ checked, theme }) => checked && `url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99989 10.1703L2.52989 6.70031C2.13989 6.31031 1.50989 6.31031 1.11989 6.70031C0.729893 7.09031 0.729893 7.72031 1.11989 8.11031L5.29989 12.2903C5.68989 12.6803 6.31989 12.6803 6.70989 12.2903L17.2899 1.71031C17.6799 1.32031 17.6799 0.690313 17.2899 0.300312C16.8999 -0.0896875 16.2699 -0.0896875 15.8799 0.300312L5.99989 10.1703Z' fill='%23${theme.colors.brand.slice(1)}' /%3E%3C/svg%3E");`};
  background-repeat: no-repeat;
  background-position: center;
  transition: .5s cubic-bezier(.25,.8,.5,1);

  ${({ $error, theme }) => $error && css`
    border: 1px solid ${theme.colors.error};
  `};

  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
`;

export const CheckboxContainer = styled.div`
  display: -webkit-box;
  margin: 12px 0;
  width: fit-content;
  cursor: ${({ $disabled }) => $disabled ? 'not-allowed' : 'default'};

  ${({ $disabled }) => !$disabled && css`
      &:hover {
        ${StyledCheckbox} {
          box-shadow: 0 0 0 2px ${({ checked, theme }) => (checked ? theme.colors.brand : '#002424')};
        }
      }
`}
  ${({ $error, theme }) => $error && css`
    &:hover {
      ${StyledCheckbox} {
        box-shadow: 0 0 0 2px ${theme.colors.error};
      }
    }
  `};

  ${({ $containerStyle }) => $containerStyle}
`;


export const Label = styled.span`
  width: 98%;
  margin-left: 8px;
  cursor: inherit;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #002424;

  *:where(a, button) {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    text-decoration: underline;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.brand};
  }

  *:where(button) {
    outline: none;
    border: none;
    background-color: transparent;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    *:where(a, button) {
      font-size: 14px;
    }
    
  }
`;
