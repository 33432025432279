import React from 'react';
import { ReactComponent as SuccessIcon } from 'assets/img/icons/success.svg';
import {
  Container,
  Title,
  SubText
} from './style';
import { TextButton } from 'components/Button';

const Success = ({
  reSendForm,
  t
}) => {
  return (
    <Container>
      <SuccessIcon />

      <Title>{t('form.thanks')}</Title>
      
      <SubText>{t('form.contact_success')}</SubText>

      <TextButton
        title={t('buttons.send_again')}
        hasArrow
        onClick={reSendForm}
      />
    </Container>
  )
};

export default Success;
