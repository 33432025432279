import { bookingInstance, appInstance } from './httpService';
import errorHandler from './errorHandler';

export const bookingOnDay = async ({ lang, today }) => {
  try {
    const { data } = await bookingInstance({ lang }).get('/', { params: { today: today } });
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const bookingCalendar = async ({ lang, today }) => {
  try {
    const { data } = await bookingInstance({ lang }).get('/calendar', { params: { today: today } });
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getBooking = async ({ lang, id }) => {
  try {
    const { data } = await bookingInstance({ lang }).get(`/${id}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getSpecialItemList = async ({ lang }) => {
  try {
    const { data } = await appInstance({ lang }).get('/special-item');
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const createCard = async ({ lang, payload }) => {
  try {
    const { data } = await appInstance({ lang }).post('/card', payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const deleteOrderCard = async ({ lang, id }) => {
  try {
    const { data } = await appInstance({ lang }).delete('/card/' + id);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getSingleCard = async ({ lang, id }) => {
  try {
    const { data } = await appInstance({ lang }).get('/card/' + id);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const updateCard = async ({ lang, id, payload }) => {
  try {
    const { data } = await appInstance({ lang }).put('/card/' + id, payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

// TODO
export const orderCard = async ({ lang, payload }) => {
  try {
    const { data } = await appInstance({ lang }).post('/order', payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getCurrency = async ({ lang }) => {
  try {
    const { data } = await appInstance({ lang }).get('/get-currency');
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getOrderWToken = async ({ lang, token }) => {
  try {
    const { data } = await appInstance({ lang }).get(`/order/success/${token}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getGalleryService = async ({ lang }) => {
  try {
    const { data } = await appInstance({ lang }).get('/gallery');
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const createReservationForm = async ({ lang, payload }) => {
  try {
    const { data } = await appInstance({ lang }).post('/reservation-request-form', payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const createContactForm = async ({ lang, payload }) => {
  try {
    const { data } = await appInstance({ lang }).post('/contact-form', payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
