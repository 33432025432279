import { LanguageContext } from 'contexts/Languages';
import React from 'react';
import { Box } from '@mui/material';
import { TextButton } from 'components/Button';
import {
  Container,
  Title,
  SubTitle,
  Text
} from './style';

const ReturnFirst = ({
  deleteCardData,
  close,
  returnFirst
}) => {
  const { t } = React.useContext(LanguageContext);

  const ok = () => {
    returnFirst?.();
    close?.();
  };

  const cancel = () => {
    close?.();
  };

  return (
    <Container>
      <Title>{t('modal.first_return.title')}</Title>
      <SubTitle>{t('modal.first_return.sub_title')}</SubTitle>

      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <TextButton
          title={t('modal.first_return.buttons.no')}
          color={'#002424'}
          onClick={cancel}
        />
        <TextButton
          title={t('modal.first_return.buttons.yes')}
          hasArrow
          color={'#139CD7'}
          onClick={ok}
        />
      </Box>
    </Container>
  )
};

export default ReturnFirst;
