import React from 'react';
import {
  CheckboxContainer,
  StyledCheckbox,
  Label
} from './style';

export const propagation = (e) => {
  e?.preventDefault();
  e?.stopPropagation();
};


const CheckBox = ({
  checked,
  setChecked,
  label,
  containerStyle,
  disabled,
  hasButtonAction,
  error
}) => {

  const changeHandler = () => {
    !disabled && setChecked(!checked);
  };

  return (
    <CheckboxContainer
      onClick={hasButtonAction ? () => { } : changeHandler}
      checked={checked}
      $containerStyle={containerStyle}
      $disabled={disabled}
      $error={error}
    >
      <StyledCheckbox onChange={changeHandler} checked={checked} $error={error} />
      <Label onClick={changeHandler}>{label}</Label>
    </CheckboxContainer>
  )
};

CheckBox.defaultProps = {
  checked: false,
  setChecked: () => { },
  label: '',
  containerStyle: {},
  disabled: false,
  hasButtonAction: false,
  error: false
};

export default CheckBox;
