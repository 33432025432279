import React from 'react';
import { LanguageContext } from './Languages';
import {
  bookingCalendar,
  // getSpecialItemList,
  createCard as createCardService,
  orderCard,
  getSingleCard,
  updateCard as updateCardService,
  getCurrency,
  getOrderWToken,
  getGalleryService,
  deleteOrderCard,
  createReservationForm,
  createContactForm,
  getBooking as getBookingService,
} from 'services/booking';

export const BookingContext = React.createContext();

const initialState = {
  booking: {
    data: null,
    loading: false,
    error: null,
  },
  currency: {
    data: null,
    loading: false,
    error: null,
  },
  gallery: {
    data: [],
    loading: false,
    error: null,
  }
};

const TYPE = {
  GET_BOOKING_LOADING: 'GET_BOOKING_LOADING',
  GET_BOOKING_SUCCESS: 'GET_BOOKING_SUCCESS',
  GET_BOOKING_FAILED: 'GET_BOOKING_FAILED',

  GET_CURRENCY_LOADING: 'GET_CURRENCY_LOADING',
  GET_CURRENCY_SUCCESS: 'GET_CURRENCY_SUCCESS',
  GET_CURRENCY_FAILED: 'GET_CURRENCY_FAILED',

  GET_GALLERY_LOADING: 'GET_GALLERY_LOADING',
  GET_GALLERY_SUCCESS: 'GET_GALLERY_SUCCESS',
  GET_GALLERY_FAILED: 'GET_GALLERY_FAILED'
};

const reducer = (state, action) => {
  switch (action.type) {
    case TYPE.GET_BOOKING_LOADING:
      return {
        ...state,
        booking: {
          ...state.booking,
          loading: true,
          error: null,
        },
      };
    case TYPE.GET_BOOKING_SUCCESS:
      return {
        ...state,
        booking: {
          ...state.booking,
          loading: false,
          data: action.payload.data,
        },
      };
    case TYPE.GET_BOOKING_FAILED:
      return {
        ...state,
        booking: {
          ...state.booking,
          loading: false,
          error: action.payload.error,
        },
      };

    case TYPE.GET_CURRENCY_LOADING:
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: true,
          error: null,
        },
      };
    case TYPE.GET_CURRENCY_SUCCESS:
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: false,
          data: action.payload.data,
        },
      };
    case TYPE.GET_CURRENCY_FAILED:
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: false,
          error: action.payload.error,
        },
      };

    case TYPE.GET_GALLERY_LOADING:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          loading: true,
          error: null,
        },
      };
    case TYPE.GET_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          loading: false,
          data: action.payload.data,
        },
      };
    case TYPE.GET_GALLERY_FAILED:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          loading: false,
          error: action.payload.error,
        },
      };

    default:
      throw new Error();
  }
};

export const BookingProvider = ({ children }) => {
  const [states, dispatch] = React.useReducer(reducer, initialState);
  const { language } = React.useContext(LanguageContext);

  const getBooking = async () => {
    dispatch({ type: TYPE.GET_BOOKING_LOADING });
    try {
      const data = await bookingCalendar({ lang: language.code });
      dispatch({ type: TYPE.GET_BOOKING_SUCCESS, payload: { data } });
    } catch (error) {
      dispatch({ type: TYPE.GET_BOOKING_FAILED, payload: { error } });
    }
  };

  // const getSpecialItems = async () => {
  //   dispatch({ type: TYPE.GET_BOOKING_LOADING });
  //   try {
  //     const data = await getSpecialItemList({ lang: language.code });
  //     dispatch({ type: TYPE.GET_BOOKING_SUCCESS, payload: { data } });
  //   } catch (error) {
  //     dispatch({ type: TYPE.GET_BOOKING_FAILED, payload: { error } });
  //   }
  // };

  const createCard = async (payload) => {
    // dispatch({ type: TYPE.GET_BOOKING_LOADING });
    try {
      const data = await createCardService({ lang: language.code, payload });
      // dispatch({ type: TYPE.GET_BOOKING_SUCCESS, payload: { data } });
      return data;
    } catch (error) {
      // dispatch({ type: TYPE.GET_BOOKING_FAILED, payload: { error } });
      throw error;
    }
  };

  const createOrder = async (payload) => {
    // dispatch({ type: TYPE.GET_BOOKING_LOADING });
    try {
      const data = await orderCard({ lang: language.code, payload });
      // dispatch({ type: TYPE.GET_BOOKING_SUCCESS, payload: { data } });
      return data;
    } catch (error) {
      // dispatch({ type: TYPE.GET_BOOKING_FAILED, payload: { error } });
      throw error;
    }
  };

  const getCard = async (id) => {
    // dispatch({ type: TYPE.GET_BOOKING_LOADING });
    try {
      const data = await getSingleCard({ lang: language.code, id });
      // dispatch({ type: TYPE.GET_BOOKING_SUCCESS, payload: { data } });
      return data;
    } catch (error) {
      // dispatch({ type: TYPE.GET_BOOKING_FAILED, payload: { error } });
      throw error;
    }
  };

  const updateCard = async ({ id, payload }) => {
    // dispatch({ type: TYPE.GET_BOOKING_LOADING });
    try {
      const data = await updateCardService({ lang: language.code, id, payload });
      // dispatch({ type: TYPE.GET_BOOKING_SUCCESS, payload: { data } });
      return data;
    } catch (error) {
      // dispatch({ type: TYPE.GET_BOOKING_FAILED, payload: { error } });
      throw error;
    }
  };

  const deleteCard = async (id) => {
    // dispatch({ type: TYPE.GET_BOOKING_LOADING });
    try {
      const data = await deleteOrderCard({ lang: language.code, id });
      // dispatch({ type: TYPE.GET_BOOKING_SUCCESS, payload: { data } });
      return data;
    } catch (error) {
      // dispatch({ type: TYPE.GET_BOOKING_FAILED, payload: { error } });
      throw error;
    }
  };

  const getCurrencyList = async () => {
    dispatch({ type: TYPE.GET_CURRENCY_LOADING });
    try {
      const data = await getCurrency({ lang: language.code });
      dispatch({ type: TYPE.GET_CURRENCY_SUCCESS, payload: { data } });
    } catch (error) {
      dispatch({ type: TYPE.GET_CURRENCY_FAILED, payload: { error } });
    }
  };

  const getOrder = async (token) => {
    try {
      if (!token) {
        throw new Error('Token is required');
      }
      const data = await getOrderWToken({ lang: language.code, token });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const getGallery = async () => {
    dispatch({ type: TYPE.GET_GALLERY_LOADING });
    try {
      const data = await getGalleryService({ lang: language.code });

      const _data = data.map((item) => {
        const type = item.file_type.split('/')[1] === 'video' ? 'video' : 'image';
        return {
          ...item,
          id: item.id,
          type: type,
          source: item.file
        }
      });
      // dispatch({ type: TYPE.GET_GALLERY_SUCCESS, payload: { data } });
      dispatch({ type: TYPE.GET_GALLERY_SUCCESS, payload: { data: _data } });
      return data;
    } catch (error) {
      dispatch({ type: TYPE.GET_GALLERY_FAILED, payload: { error } });
      throw error;
    }
  };

  const sendReservationForm = async ({ payload }) => {
    try {
      const data = await createReservationForm({ lang: language.code, payload });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const sendContactForm = async ({ payload }) => {
    try {
      const data = await createContactForm({ lang: language.code, payload });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const getSingleBooking = async (id) => {
    try {
      const data = await getBookingService({ lang: language.code, id });
      return data;
    } catch (error) {
      throw error;
    }
  };

  return (
    <BookingContext.Provider
      value={{
        states,
        currencyData: states.currency,
        gallery: states.gallery,
        dispatch,
        getBooking,
        createCard,
        createOrder,
        getCard,
        updateCard,
        getCurrencyList,
        getOrder,
        getGallery,
        deleteCard,
        sendReservationForm,
        sendContactForm,
        getSingleBooking
      }}>
      {children}
    </BookingContext.Provider>
  )
};
