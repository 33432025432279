import React from 'react';
import {
  Container,
  Header,
  MealText,

  MenuArea,
  MenuContainer,
  MenuItem,
  FoodName,
  FoodDescription,
  ImageContainer,
  Img,
  SectionTitle,
  MenuSection
} from './style';

const MenuModal = ({
  data,
  t
}) => {

  function replacePlaceholder(text, placeholder, replacement) {
    return text.replace(new RegExp(placeholder, 'g'), replacement);
  };

  const renderMenuTitle = (k) => {
    //menu_section
    const translated = t(`pages.home.menu_section_secondary.${k}`);
    let result = replacePlaceholder(translated, '<0>', '<strong>');
    result = replacePlaceholder(result, '</0>', '</strong>');
    return result;
  };

  if (!data || data?.length === 0) {
    return null;
  }

  // data.menu.push.apply(data.menu, data.menu);

  return (
    <Container>
      <Header>
        <MealText
          dangerouslySetInnerHTML={{ __html: renderMenuTitle(data.key) }}
        />
      </Header>

      <MenuArea>
        {/* <ImageContainer>
          <Img
            alt='menu'
            src={data.image}
          />
        </ImageContainer> */}
        <MenuContainer>
        {data.menu.map((item, index) => (
  <MenuSection key={index}>
    {item.foods && item.foods.length > 0 && (
      <Img
        alt='menu'
        src={item.foods[0].imageElem} // Display the image for the first food item in the section
      />
    )}
    <div className="columnItem">
    <SectionTitle>{t(`common.food_categorty.${item.type}`)}</SectionTitle>
    {item.foods && item.foods.map((food, idx) => (
      <MenuItem key={`f_${idx}`}>
        <FoodName>{food.foodName}</FoodName>
        <FoodDescription>{food?.foodDescription}</FoodDescription>
      </MenuItem>
    ))}
    </div>
  </MenuSection>
))}
        </MenuContainer>
      </MenuArea>
    </Container>
  )
};

MenuModal.defaultProps = {
  meal: '',
  data: []
};

export default MenuModal;
