import styled from 'styled-components';
import { ReactComponent as Warning } from 'assets/img/icons/Warning-line.svg'

export const Container = styled.section`
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  width: 85%;
  margin: 0 auto;
  padding: 0 52px;
  padding-top: 40px;
  padding-bottom: 40px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 100%;
    padding-right: 32px;
    padding-left: 32px;
  }
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    flex-direction: column-reverse;
    padding: 0 16px;
    padding-top: 20px;
    padding-bottom: 40px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const WarningArea = styled.div`
  background-color: #d8525220;
  border-radius: 100%;
`;

export const WarningIcon = styled(Warning)`
  width: 100px;
  height: 100px;
  padding: 30px;
`;

export const Image = styled.img`
  width: 40%;
  object-fit: contain;
  user-select: none;
  pointer-events: none;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 43%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    width: 50%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    margin: 0 auto;
    width: 100%;
    margin-bottom: 20px;
    max-width: 500px;
    display: none;
  }
`;

export const Title = styled.h1`
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  text-transform: capitalize;
  color: #139CD7;
  padding-bottom: 10px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 62px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 42px;
    line-height: 42px;
  }
`;

export const SubTitle = styled.h2`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #002424;
  text-align: center;
  padding: 10px 0;
`;

export const Description = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 34px;
  color: #002424;
  width: 50%;
  white-space: break-spaces;
  text-align: center;
  padding: 20px;
  width: 100%;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 20px;
    line-height: 30px;
  }
`;

export const CloudImg = styled.img`
  width: 100%;
  min-height: 1200px;
  object-fit: contain;
  position: fixed;
  top: 120px;
  right: -20%;
  z-index: -1;
  user-select: none;
`;
