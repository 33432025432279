const formatBirthday = (date) => {
  const d = new Date(date);
  if (d instanceof Date && !isNaN(d)) {
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    return `${day}/${month}/${year}`;
  } 
  return '';
};
// Telephone: +900539 883 61 03
export const explicitConsent_tr = `
VERİ SAHİBİ AÇIK RIZA METNİ

Kişisel verilerin veri sorumlusu sıfatıyla SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ, 19 Mayıs Mah. Büyükdere Cad. Beytem Plaza Blok No: 20 İç Kapi No: 1 Şişli / İstanbul veya gerekli güvenlik tedbirlerini aldırmak suretiyle yetkilendirdiği veri işleyenler tarafından; “SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ” tarafından ilgili hak ve yükümlülüklerin  yerine  getirilmesi ve veri sorumlusu sıfatıyla Şirketin 6698 sayılı Kişisel Verilerin Korunması Kanunu, 6102 sayılı Türk Ticaret Kanunu, 5237 Sayılı Türk Ceza Kanunu ve ilgili diğer yasal mevzuat uyarınca hak ve yükümlülüklerinin yerine  getirilmesi veya reklam ve katalog düzenleme faaliyetleri, özellikle SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ tarafından düzenlenecek eğitimlerde, reklam ve tanıtım faaliyetlerinde, fiziksel ve online etkinliklerde toplanan ve bu faaliyetlerde kullanılmak amaçlarıyla; veri işleyenler tarafından fiziki, maddi  veya elektronik ortamda, işitsel, görsel, sözlü ve yazılı şekilde toplanması yöntemiyle; başta mevzuatta  öngörülen  veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme ilkesi olmak üzere  6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 4. maddesinde ifade edilen genel ilkelere uygun şekilde işlenebileceğini, üçüncü kişi gerçek ve tüzel kişilerle paylaşılabileceğini, yurt dışına aktarılabileceğini yukarıda belirtilen hususlarla ilgili olarak SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ tarafından bilgilendirildiğimi ve 6698 Sayılı Kanun çerçevesinde açık rızam bulunduğunu kabul ve beyan ederim.

Ayrıca 6698 sayılı Kanun uyarınca işbu belgeyi imzalayarak aşağıda belirtilen kişisel verilerimin ve özel nitelikli kişisel verilerimin SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ veya yetkilendirdiği üçüncü kişiler tarafından Kanuna uygun olarak, bilgime sunulmuş olan "Kişisel Verilerin Korunması Hakkında Veri Sahibini Bilgilendirme Metni” başta olmak üzere SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ’nin ilgili politika ve prosedürleri çerçevesinde, işlenme amacı için gerekli olan süre kadar saklanmak üzere, SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ’nin kullanmakta olduğu bilişim sistemleri ve ilgili birimleri aracılığıyla; otomatik, kısmen otomatik olan veya otomatik olmayan yollarla, açık rızama dayalı olarak yukarıda belirtilen amaçlar kapsamında işlenmesine ve aktarılmasına onay veriyorum.

Son olarak, Kanunun 11. maddesi uyarınca haklarımın bulunduğunu bildiğimi ve SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ tarafından bu hususta bilgilendirildiğimi kabul ve beyan ederim.`;

export const explicitConsent_en = `EXPLICIT CONSENT TEXT OF DATA SUBJECT

SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ residing in 19 Mayıs Mah. Büyükdere Cad. Beytem Plaza No: 20 İç Kapı No: 1 Şişli / İstanbul  as the data controller of personal data, or by data processors authorized by taking the necessary security measures; I hereby accept and declare that I have been informed by SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ and that I have given my explicit consent within the framework of Law No. 6698, General Data Protection Regulation (“GDPR”) that my personal data can be processed in accordance with the general principles expressed in Article 4 of the Law on the Protection of Personal Data No. 6698, for the purpose of fulfilling its obligations or organizing advertising and cataloging activities, especially in trainings to be organized by SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ collected in advertising and promotion activities, physical and online events and used in these events; by the method of data processing in physical, material or electronic media, in audio, visual, verbal and written forms; especially the principle of keeping it for the period required by the legislation or for the purpose for which it is processed, that it can be processed, shared with third-party real and legal persons, transferred abroad, related to the above-mentioned issues; for the fulfillment of the relevant rights and obligations by SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ as a data controller in accordance with the Law on the Protection of Personal Data No. 6698, the Turkish Commercial Code No. 6102, the Turkish Penal Code No. 5237, the GDPR and other relevant legislation. 

In addition, by signing this document in accordance with Law No. 6698 and GDPR, my personal data and sensitive personal data specified below can be obtained by SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ or third parties authorized by it, in accordance with the Law, within the framework of the relevant policies and procedures of SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ, especially the "Information Text on the Protection of Personal Data", which has been submitted to my information, I hereby consent to my personal data’s processing and transfer within the scope of the above-mentioned purposes, based on my explicit consent through the information systems and related units used by SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ to be stored for the period necessary for the purpose of processing by automated, partially automated or non-automated means.

Lastly, I accept and declare that I am aware of my rights in accordance with Article 11 of the Law and that I have been informed by SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ on this matter.`;

export const preContractual_en = (buyer, guests) => `
PRE-CONTRACTUAL INFORMATION FORM
 
1.PARTIES

1.1. Seller Information: Hereinafter referred to as ‘Seller’.
Title: SKY DİNNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ
Address: 19 Mayıs Mah. Büyükdere Cad. Beytem Plaza No: 20 İç Kapı No: 1 Şişli / İstanbul
MERSIS: 0772154293100001
Contact Information: 
Web Site: dinnerintheskycappadocia.com.tr
Telephone: +90 535 470 14 46
E-mail: info@dinnerintheskycappadocia.com.tr
 
1.2.Buyer Information:
 
Name Surname : ${buyer.fullName}
Telephone: ${buyer.phone}
E-mail:${buyer.email}
Country: ${buyer.country}
City: ${buyer.city}
Address:${buyer.address}

1.3.Guest Information:
${guests.map((guest, index) =>
  `${index + 1})
Name Surname: ${guest.name}
Date of Birth: ${formatBirthday(guest.birthday)}\n`).join("")}
 
 
 
Hereinafter referred to as ‘Buyer’.
 
 
In this Agreement, Seller and Buyer shall be referred to as the “Parties” collectively and a “Party” individually.
2. DEFINITIONS
A) Law: The Consumer Protection Law numbered 6502

B) Regulation: Regulation on Distance Contracts dated 27.11.2014,

C) Seller: The legal entity/person who provides the services and/or sell a product to the consumer under its commercial activities,

D) Buyer: :  A natural or legal person who shops for commercial or non-professional purposes through the website on behalf of himself/herself or the participant in order to benefit from the goods or services provided by the seller,

E) Website: Shall mean the domain named “dinnerintheskycappadocia.com.tr” which belongs to the Seller for the purposes of e-trade and commercial activities

F) Product/Ticket: Refers to “Dinner in the Sky” experience ticket,

G) Distance Contract: This agreement entered into by  the Seller and Buyer,

H) Service: Refers to the activity to be offered by the Seller to the Buyer following the date of ticketing,

I) Personal Data: any information that relates to an identified or identifiable natural person.

J) Guest: Refers to the person who will benefit from the product/service subject to the contract made by the Buyer

 

 

3. CONTEXT OF THE FORM
The subject of this Pre-Contractual Information Form(“Form”) is to inform the rights and the liabilities of the interested parties related to the sales of the products and services in accordance with the Law and Regulation. Buyers acknowledge and undertake that consuming products and services means becoming a party of this contract followed by paying the fee of the product, VAT, shipment (if applicable), extra services (if applicable). 
 
 
 
 
4. SERVICE(S)/PRODUCT(S) DESCRIPTIONS AND PRICES
4.1. The basic features (type, quantity) of the Service / Product  are published on The Seller’s website. If a campaign is organized by the Seller, you can review the basic features of the relevant product during the campaign. Valid until the date of the campaign.
4.2. All the prices listed and presented on the website are VAT included. Shipment and other expenses are stated separately if applicable. The announced prices are valid until they are updated or removed. Special offers are valid within the specified period of time unless otherwise stated.
 
4.3. Prices of the contracted commodity and extra expenses (if applicable) are stated below:

Product:
Quantity:
Price per Unit:
 
VAT:
Total:

Method of Payment and Plan:                     
Delivery Address:                     
Receiver:                     
Billing Address:                    
Order Date:                    
Delivery Date:                    
Type of Delivery:

 
      5. GENERAL PROVISIONS
 
5.1. The Buyer acknowledges, declares and undertakes that it has read, on the Seller's website, the main features of the product and services, sale price, method of payment, type of provide and that it has granted the required confirmation in an electronic environment based on full awareness of such information. The Buyer also acknowledges, declares and undertakes that it has truly, completely and accurately obtained all the information that the Seller is required to provide to the Buyer, which includes the address details, primary features of the ordered product/service, tax-included price of the products/services, payment and delivery information before confirming the general conditions in the electronic environment as required for the distance sales contract.
5.2. The Seller will provide the product/service in line with the qualities stated, the information and documentation needed and in good condition free of any defect as stipulated by the applicable laws regulations, perform the job conforming to standards based on the principles of honesty and decency, maintain and enhance the service quality, to extend due care and diligence while performing the job, and act with caution and prudence.
 
5.3. Before the contractual liability of execution expires, the Seller may supply a different product in equal quality and price, provided that the Seller notifies the Buyer and receives its clear approval.
 
5.4. The Seller accepts, declares and undertakes that it will notify the The Buyer under the circumstances of failure to meet its obligations subject to the Agreement before the contractual liability of execution expires and refund the total amount to the Buyer within 14 days at the latest.  The repayment period may differ depending on the Buyer's bank. The Buyer accepts, declares and undertakes that cannot make any request from the Seller in this regard.
 
 
 
5.5. The Buyer is expected to  confirm this Agreement electronically for the delivery of the contracted product/service, and if, for any reason, the price for the contracted product/service is not paid and/or canceled in the bank records, the Seller’s obligation to deliver the contracted product or provide the service wiil be revoked.
 
5.6.  The Seller may change or cancel the date and time of the event subjected to the contract. In cases where it is not possible for the Buyer to participate in the event whose date and time have been changed by the Seller, the Buyer may be refunded. The Seller is not obliged to meet the Buyer's time and date change requests regarding the purchased service. Within the scope of customer satisfaction, the Seller may suggest another time or date that the Buyer can participate in the event at his own discretion. The dinner experience will be Cancelled the refund policy will be as follows: A full refund will apply to their credit/debit card within maximum of 14 days. The repayment procedure may differ depending on your bank therefore you can follow the procedure by contacting your bank.
 
 
 
5.7. The Buyer is expected that the personal and other information provided on the website during the purchase process are not false. The Buyer will be responsible of any false information caused any damage to the Seller, and in this event the Buyer will compensate all of the Seller’ s losses immediately, paid fully in cash.
 
5.8. The Buyer is expected to abide by all the applicable laws and not to violate these rules when using the Seller's website. Otherwise, the Buyer shall be, wholly and exclusively, liable for all legal and punitive obligations that may arise.
 
5.9. Links may be given from the Seller’s own website to other websites and/or other web-contents which are not under the control of the Seller and/or belong to and/or are managed by the third parties. These links are given to facilitate redirection of the Buyer, and they do not support any website or the persons managing the websites and do not constitute any warranty for the information stated in the particular websites.
 
6. RIGHT OF WITHDRAWAL
6.1 All ticket sales are final, except for cancellation and/or postponement of the event. According to subparagraph (g) of Article 15 of the Distance Contracts Regulation titled "exceptions to the right of withdrawal", since the contracts for accommodation, goods transport, car hire,  food and beverage supply, and leisure time utilization for entertainment or recreation purposes, which must be made on a certain date or period, are among the contracts that fall within the exceptions to the right of withdrawal, and since the subject of the contract is related to the sale of event tickets and is among the contracts for the utilization of leisure time for entertainment or recreation purposes, which must be made on a certain date, the Buyer does not have the right of withdrawal by the Regulation.
 
 
6.2.Except for the cancellation of the event by The Seller, tickets sold can not be canceled, changed, or refunded in any way after the sales transaction. However, in the event of cancellation or postponement of the event, the Seller has the right to impose some limitations on the terms of the refund, as well as the right to make changes to the program. However, in cases where it is not possible for the Buyer to attend the rescheduled event, the Buyer may be refunded
 
 
 
 
 
7. TICKET SALE
 
7.1.The Parties acknowledge and declare that the sale of tickets via the Internet Address constitutes only one of the sales and marketing channels of the Seller and that the provisions regarding the tickets may vary according to the sales and marketing channels. The parties agree that the Seller is not responsible for price and content errors arising from typographical and system errors in the Internet Address.

 

7.2. Ticket types, features, and ticket prices are announced via Internet Address. When the buyer selects the ticket that he/she wants to purchase on the payment page of the Internet Address, also can determine the type, feature, and price of the ticket. The Seller reserves the right to unilaterally change the tickets and their prices according to the type, feature, or price of the tickets. The Seller reserves the right to stop or cancel the sale of tickets via the Internet Address at any given time for any given reason.

7.3. When the Buyer selects the ticket to be purchased on the payment page of the Internet Address, the total ticket price including service fee, taxes and charges related to the online sale of the ticket will be displayed.  The Buyer may purchase the ticket by choosing the credit card or debit card options. By purchasing the ticket via the Internet Address, the Buyer is deemed to have agreed to pay all prices shown on the payment page of the Internet Address.

7.4. The Buyer accepts and declares that by processing the credit card or debit card information to the Internet Address, the Buyer consents to the collection of the ticket fee from the relevant credit card or debit card. The electronic copy of the purchased ticket is sent to the e-mail address specified by the Buyer when purchasing the tickets.

 

7.5. During the ticket purchase process, the Buyer is obligated to check whether the ticket selected for purchase is the ticket requested for purchase, and the Seller is not responsible for the Buyer's incorrect ticket purchase. The obligation to control and preserve the purchased tickets belong to the Buyer. The buyer is obligated to present the electronic copy of the ticket to the Seller at the time of the event entrance and to keep the electronic copy of the ticket during the event. The Seller is not responsible for the loss of the electronic copy of the ticket, and the Buyer who cannot present his/her ticket cannot participate in the event. Tickets that are not used on the day are invalid and the fees paid for these tickets will not be refunded or ticket changes will not be made.

7.6. A maximum of 32 tickets can be purchased by a single buyer. If The buyer purchases more than one ticket, he/she must enter the distinguishing information such as name, surname, and date of birth of the participants requested by the Seller into the system. Tickets cannot be transferred to a different person after purchase. For this reason, the Seller has the right to request information and documents confirming the identity of the participants of the event.

7.7.   Tickets are personalized and single-use unless otherwise stated on the Internet Address or in the "Terms and Conditions". The Buyer is obligated to evaluate whether the tickets selected have the qualifications suitable for the ticket type and features by reading the ticket descriptions on the relevant pages on the Internet Address and the descriptions regarding the ticket on the payment page of the Internet Address are only a summary.  In the event that the Buyer does not meet the specifications (e.g. Adult,height) declared when selecting the ticket, the Seller may cancel the Buyer's ticket without any compensation or refund. This provision shall also apply in the event that the Buyer purchases more tickets than the maximum number of tickets determined by the Seller or sells or makes available the tickets purchased by the Buyer to third parties. 

 
8. SETTLEMENT OF DISPUTES
The governing law shall be Turkish law and Turkish Consumer Arbitration Committees and Consumer Courts shall be the competent courts of jurisdiction.
 
 
 
 
 
 
9. TERM OF USE
 
Our terms and conditionsand Sales Agreement have been prepared to satisfy the Buyer and to inform the Buyer about the purchase process. The sales and Agreement also form part of the Terms of Use. The Buyer accepts and declares that he/she has read and understood the Termsand Conditions, , Privacy Notice on the Protection and the Processing of Personal Data , Explicit Consent Text, Pre-Contractual Information Form,Cookie Policy, Electronic Message Confirmation Text,Distance Sales Contract, FaQs text, that he/she shall be deemed to have accepted their contents in full by performing ticket purchase transactions.
 
In the event that the buyer has a request or complaint contrary to the sales policy and/or contract terms, we recommend that the buyer visit the Frequently Asked Questions page and / or contact us at the contact numbers specified above and / or on our site before making any ticket / service purchase transaction and confirming the order.
 
Before the approval of the order and the establishment of the sales contract, the Buyer accepts and declares that the Buyer has been informed in detail in writing within the scope of this Preliminary Information Text and the legislation, as well as prior to the order, the preliminary information approval has been obtained and the preliminary information approval will be an integral part of the Sales Contract.
 
Seller:
Buyer: 
DATE
`;

export const preContractual_tr = (buyer, guests) => `ÖN BILGILENDIRME FORMU
 
1.TARAFLAR

1.1. Satıcı Bilgileri: Bundan sonra ‘Satıcı’ olarak anılacaktır.
Ticaret Unvanı: SKY DİNNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ
Adres: 19 Mayıs Mah. Büyükdere Cad. Beytem Plaza No: 20 İç Kapı No: 1 Şişli / İstanbul
MERSİS: 0772154293100001 
İletişim Bilgileri: 
Web Sitesi: dinnerintheskycappadocia.com.tr
Telefon: +90 535 470 14 46
E- posta Adresi:  info@dinnerintheskycappadocia.com.tr
 
1.2.Alıcı Bilgileri:
Adı Soyadı: ${buyer.fullName}
Telefon: ${buyer.phone}
E-posta Adresi: ${buyer.email}
Ülke/ Eyalet: ${buyer.country}
Şehir: ${buyer.city}
Adres: ${buyer.address}
 
1.3. Katılımcı Bilgileri
${guests.map((guest, index) =>
  `${index + 1})
Adı Soyadı: ${guest.name}
Doğum Tarihi: ${formatBirthday(guest.birthday)}\n`).join("")}

Bundan sonra ‘Alıcı’ olarak anılacaktır.
 
İşbu sözleşmede, Satıcı ve Alıcı birlikte “Taraflar, ayrı ayrı “Taraf” olarak anılacaktır.
 
 
2. TANIMLAR
A) Kanun: 6502 sayılı Tüketicinin Korunması Hakkında Kanunu'nu,
B) Yönetmelik: 27.11.2014 tarihli Mesafeli Sözleşmeler Yönetmeliğini,

C) Satıcı: Ticari amaçla tüketiciye hizmet sunacak kişiyi,

D) Alıcı: Satıcı tarafından sağlanan mal veya hizmetten yararlanmak üzere kendisi veya katılımcı adına internet sitesi üzerinden ticari veya mesleki olmayan amaçlarla alışveriş yapan gerçek veya tüzel kişiyi,

E) Internet Adresi/Web Sitesi: Satıcının eticaret ve benzeri faaliyetlerini yürüttüğü “dinnerintheskycappadocia.com.tr “alan adını,

F) Ürün/Mal/Bilet: Alışverişe konu olan; “Dinner in the Sky” deneyimi bileti,

G) Mesafeli Satış Sözleşmesi: Satıcı ve Alıcı arasında akdedilen işbu satış sözleşmesini,

H) Hizmet: Satıcı tarafından Alıcıya biletleme yapmış olduğu tarih uyarınca sunulacak olan etkinlik faaliyeti,

I) Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,

J) Katılımcı: Alımı Alıcı tarafından yapılan sözleşme konusu ürün veya hizmetten yararlanacak kişiyi

ifade eder.

 

3. KONU
İşbu Ön Bilgilendirme Formu, Satıcı ile Alıcı arasında mal ve/veya hizmet alımı hususunda addedilecek olan mesafeli sözleşmeden doğacak hak ve yükümlülükleri Kanun ve Yönetmelikte belirtildiği üzere ortaya koymaktadır. Alıcı işbu Ön Bilgilendirme Formunu onaylayarak, mal ve/veya hizmet alımını gerçekleştirmek için mesafeli sözleşmeye taraf olması gerektiği, bunu takiben sipariş bedelini, varsa kargo ücreti, vergi ve belirtilen diğer ek ücretleri ödemesi gerektiği hususunda bilgilendirildiğini peşinen kabul eder.
 
 
4. SÖZLEŞMEYE KONU MAL VE HİZMET HAKKINDA BİLGİLER
4.1. Malın/ Hizmetin temel özelliklerini (türü, adedi) Satıcı’ya ait internet adresinde yayınlanmaktadır. Satıcı tarafından kampanya düzenlenmiş ise ilgili ürünün temel özelliklerini kampanya süresince inceleyebilirsiniz. Kampanya tarihine kadar geçerlidir.
 
 
4.2. Web Sitesinde belirtilen ve listenen bütün fiyatlar KDV dahil satış fiyatlardır. Varsa kargo ücreti ve ek masraflar ayrıca belirtilir. Anılı fiyatlar ve vaatler güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
 
4.3. Mesafeli Sözleşmeye konu mal veya hizmetin tüm vergiler ve ek masraflar dair fiyatı aşağıdaki gibidir:

Ürün Açıklaması:
Adet:
Birim Fiyatı:
KDV:
Toplam:

Ödeme Sekli ve Planı:                     
Teslimat Adresi:                     
Teslim Edilecek Kişi:                     
Fatura Adresi:                    
Sipariş Tarihi:                    
Teslimat Tarihi:                    
Teslim Şekli:

 
      5. GENEL HÜKÜMLER
 
5.1. Alıcı, Satıcı’ya ait internet sitesinde sözleşme konusu ürün veya hizmetin temel nitelikleri, satış fiyatı ve ödeme sekli ile sağlama şekline ilişkin ön bilgileri okuyup, bilgi sahibi olduğunu, elektronik ortamda gerekli teyidi verdiğini kabul, beyan ve taahhüt eder. Alıcı’nın; Ön Bilgilendirmeyi elektronik ortamda teyit etmesi, mesafeli satış sözleşmesinin kurulmasından evvel, Satıcı tarafından Alıcı' ya verilmesi gereken adresi, siparişi verilen ürünlere/hizmetlere ait temel özellikleri, ürün/hizmete vergiler dahil fiyatını, ödeme ve teslimat bilgilerini de doğru ve eksiksiz olarak edindiğini kabul, beyan ve taahhüt eder.
 
 
 
 
 
5.2. Satıcı, Sözleşme konusu malı/hizmeti eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri, kullanım kılavuzları işin gereği olan bilgi ve belgeler ile sağlayacak, her türlü ayıptan arî olarak ilgili hukuki düzenlemelere göre sağlam, standartlara uygun bir şekilde işi doğruluk ve dürüstlük esasları dahilinde ifa edecek, hizmet kalitesini koruyup yükseltecek, işin ifası sırasında gerekli dikkat ve özeni gösterecektir.
 
 
5.3. Satıcı, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan Alıcı’yı bilgilendirmek ve açıkça onayını almak suretiyle eşit kalite ve fiyatta farklı bir mal/hizmet tedarik edebilir.
 
5.4. Satıcı, sipariş konusu mal veya hizmetin yerine getirilmesinin imkansızlaşması halinde sözleşme konusu yükümlülüklerini yerine getiremezse, bu durumu, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan Alıcı’ya bildireceğini veen geç 14 gün içerisinde kredi/banka kartlarına tam bir geri ödeme yapacağını kabul, beyan ve taahhüt eder.  Geri ödeme süresi Alıcı’nın bankasına bağlı olarak farklılık gösterebilecektir. Alıcı bu hususta Satıcı’dan herhangi bir talepte bulunamayacağını kabul, beyan ve taahhüt eder.
 
5.5. Alıcı, Sözleşme konusu mal veya hizmetin sunulabilmesi için işbu Sözleşme’yi elektronik ortamda teyit etmesi gerekir; herhangi bir nedenle sözleşme konusu ürün bedelinin ödenmemesi ve/veya banka kayıtlarında iptal edilmesi halinde, Satıcı’nın sözleşme konusu ürünü teslim etme hizmeti ise sunma yükümlülüğünün sona erecektir.
 
5.6.
Satıcı, herhangi bir sebeple sözleşme konusu etkinliğin tarih ve saatini değişebilir, iptal edebilir. Satıcı tarafından tarihi ve saati değiştirilmiş etkinliğe Alıcı’nın katılmasının mümkün olmadığı hallerde, Alıcı’ya ücret iadesi yapılabilir. Satıcı’nın, satın alınan hizmete ilişkin Alıcı’nın saat ve tarih değişikliği taleplerini karşılama zorunluluğu bulunmamaktadır. Satıcı, müşteri memnuniyeti kapsamında keyfiyeti kendisine ait olmak üzere Alıcı’nın etkinliğe dahil olabileceği başka bir saat veya tarih önerebilir. Satıcının etkinliğin ertelenmesine karar vermesi halinde Alıcı’nın tarihi değiştirilmiş etkinliğe katılması mümkün değilse Alıcı’ya en geç 14 gün içerisinde kredi/banka kartlarına tam bir geri ödeme yapılacaktır. Geri ödeme süresi tamamen aracı bankaya bağlı olarak farklılık gösterebilmektedir.
 
5.7. Alıcı, Satıcı’ya ait internet sitesinde alışveriş yaparkenverdiği kişisel ve diğer sair bilgilerin gerçeğe uygun olmalıdır. Alıcı, Satıcı’nın bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları derhal, nakden ve def'aten tazmin edecektir.
 
 
 
5.8. Alıcı, Satıcı’ya ait internet sitesini kullanırken yasal mevzuat hükümlerine riayet etmesi ve bunları ihlal etmemesi beklenmektedir. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler tamamen ve münhasıran Alıcı’yı bağlayacaktır.
 
5.9. Satıcı’ya ait internet sitesinin üzerinden, Satıcı’nın kendi kontrolünde olmayan ve/veya başkaca üçüncü kişilerin sahip olduğu ve/veya işlettiği başka web sitelerine ve/veya başka içeriklere link verilebilir. Bu linkler Alıcı’ya yönlenme kolaylığı sağlamak amacıyla konmuş olup herhangi bir web sitesini veya o siteyi isleten kisiyi desteklememekte ve link verilen web sitesinin içerdiği bilgilere yönelik herhangi bir garanti niteliği taşımamaktadır.
 
        6. CAYMA HAKKI
6.1 Etkinliğin iptali ve/veya ertelenmesi haricinde, tüm hizmet satışları kesindir.Mesafeli Sözleşmeler Yönetmeliği’nin "cayma hakkının istisnaları" başlığını taşıyan 15 inci maddesinin (g) bendi uyarınca belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler, cayma hakkının istisnaları arasına giren sözleşmelerden olduğundan ve sözleşme konusu mal/hizmet etkinlik biletleri satışına ilişkin olup belirli bir tarihte yapılması gereken, eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler arasında olduğundan, Yönetmelik gereğince Alıcı’nın cayma hakkı bulunmamaktadır.
 
6.2.Etkinliğin Satıcı tarafından iptal edilmesi haricinde, satılan biletler, satış işlemi sonrasında hiçbir şekilde iptal edilmez, değiştirilmez ve ücret iadesi yapılmaz. Bununla birlikte, etkinliğin iptali veya ertelenmesi durumunda Satıcı geri ödeme şartları konusunda bazı sınırlandırmalar getirebilme hakkına sahip olduğu gibi, programda değişiklik yapma hakkına da sahiptir. Ancak, Alıcı’nın tarihi değiştirilmiş etkinliğe katılmasının mümkün olmadığı hallerde Alıcı’ya ücret iadesi yapılabilir.
 
7. BİLET SATIŞI
7.1. Taraflar, İnternet Adresi üzerinden bilet satışının Satıcı’nın satış ve pazarlama kanallarından sadece birini oluşturmakta olduğunu ve biletlere ilişkin hükümlerin satış ve pazarlama kanallarına göre değişiklik gösterebileceğini kabul ve beyan ederler. Taraflar, Satıcı’nın Internet Adresindeki dizgi ve sistem hatalarından kaynaklanan fiyat ve içerik hatalarından sorumlu olmadığını kabul ederler.

 

7.2. Bilet türleri, özellikleri ve fiyatları Internet Adresi üzerinden ilan edilmektedir. Alıcı, satın almak istediği bileti Internet Adresinin ödeme sayfasında seçtiğinde, biletin türünü, özelliğini ve fiyatını görebilmektedir. Satıcı, biletlerin türüne, özelliğine veya fiyatına göre biletlerde ve fiyatlarında tek taraflı olarak değişiklik yapma hakkını saklı tutmaktadır. Satıcı’nın Internet Adresi üzerinden bilet satışını dilediği zaman herhangi bir sebeple durdurma ya da iptal etme hakkı saklıdır.

7.3. Alıcı satın almak istediği bileti Internet Adresinin ödeme sayfasında seçtiğinde biletin internetten satılmasına ilişkin hizmet bedeli, vergi ve harçlar dahil, toplam fiyatını görebilecektir.Alıcı, bileti kredi kartı veya banka kartı seçeneklerinden kendisine uygun olanı tercih ederek satın alabilir. Alıcı bileti Internet Adresi üzerinden satın almakla Internet Adresinin ödeme sayfasında gösterilen tüm bedelleri ödemeyi kabul etmiş sayılır.

 

7.4. Alıcı, kredi kartı veya banka kartı bilgilerini Internet Adresine işlemekle bilet ücretinin ilgili kredi kartı veya banka kartından tahsil edilmesine onay verdiğini kabul ve beyan eder. Satın alınan biletin elektronik sureti Alıcının biletleri satın alırken belirttiği e- posta adresine gönderilir.

 

7.5. Alıcı, bilet satın alma sürecinde, satın almak için seçmiş olduğu biletin satın almak istediği bilet olup olmadığını kontrol etmekle yükümlü olup, Alıcı’nın hatalı bilet alımından Satıcı sorumlu değildir. Satın alınan biletleri kontrol ve muhafaza etme yükümlülüğü Alıcı’ya ait olup, Alıcı biletin elektronik suretini Satıcı’ya etkinlik girişince ibraz etmek ve etkinlik süresince biletin elektronik suretini muhafaza etmek zorundadır. Biletin elektronik suretinin kaybedilmesinden Satıcı sorumlu olmayıp, biletini ibraz edemeyen Alıcı etkinliğe katılamaz. Gününde kullanılmayan biletler geçersiz olup, bu biletler için ödenen bedellerin iadesi veya bilet değişikliği yapılmaz.

 

 

7.6 Tek bir alıcı tarafından en fazla 32 adet bilet alımı yapılabilecektir. Alıcı, birden fazla bilet alımı gerçekleştiriyorsa, Satıcı tarafından talep edilen katılımcılara ait isim, soyisim ve doğum tarihi gibi ayırt edici bilgilerin sisteme girişini yapmalıdır. Biletler satın alındıktan sonra farklı kişiye aktarılamaz. Bu sebeple, Satıcı , etkinlik alanın katılımcılardan kimliklerini doğrular bilgi ve belgeleri talep etme hakkına sahiptir.  

7.7. Biletler, aksi Internet Adresinde veya “Hüküm ve Koşullar”da belirtilmedikçe, kişiye özel ve tek kullanımlıktır. Alıcı, kendisinin, seçmiş olduğu biletlerin bilet türü ve özelliklerine uygun nitelikleri haiz olup olmadığını Internet Adresindeki ilgili sayfalardan bilet açıklamaları okuyarak değerlendirmekle yükümlü olup, Internet Adresinin ödeme sayfasındaki bilete ilişkin açıklamalar sadece özet niteliğindedir. Alıcı’nın bilet seçimi yaparken beyanda bulunduğu özellikleri (Örn:Yetişkin, boy) taşımaması halinde, Satıcı Alıcı’nın biletini herhangi bir tazminat ödemeksizin veya geri ödeme yapmaksızın iptal edebilir. Alıcı’nın Satıcı’nın belirlediği azami bilet sayısından fazla bilet alması veya satın almış olduğu biletleri üçüncü kişilere satması ya da kullandırması halinde de bu hüküm uygulanır.

 
​8. UYUŞMAZLIKLARIN ÇÖZÜMÜ
​İşbu Mesafeli Satış Sözleşmesi'nden doğabilecek uyuşmazlıkların çözümünde Türk hukuku uygulanacak olup, Tüketici Hakem Heyetleri ile Tüketici Mahkemeleri yetkilidir.
 
9. KULLANIM KOŞULLARI  
 
Hüküm ve Koşullar ile Satış Sözleşmesi, Alıcı’yı memnun kılacak ve satın alım süreci konusunda Alıcı’yı bilgilendirecek şekilde hazırlanmıştır. Satış Sözleşmesi, Kullanım Koşullarının da bir bölümünü oluşturmaktadır. Alıcı, bilet alım işlemlerini gerçekleştirerek, Hüküm ve Koşulları, Kişisel Verilerin Korunması ve İşlenmesine ilişkin Aydınlatma Metnini, Açık Rıza Metnini, Ön Bilgilendirme Formunu, Çerez politikası, Elektronik İleti Onay Metnini, Mesafeli Satış Sözleşmesini, , SSS metnini okuduğunu, anladığını ve içeriklerini aynen kabul etmiş sayılacağını kabul ve beyan eder.  
 
Alıcı’nın Hüküm ve Koşullar ve/veya sözleşme hükümlerinin aksine bir talebi ya da şikayetinin olması durumunda, herhangi bir bilet/hizmet alım işlemi gerçekleştirmeden ve siparişi onaylamadan önce, Sıkça Sorulan Sorular sayfasını ziyaret etmesini ve/veya tarafımızla yukarıda ve/veya sitemizde belirtilen iletişim numaralarından iletişime geçmesini öneririz.
 
Sipariş onaylanması ve satım sözleşmesi kurulmasından önce, işbu Ön Bilgilendirme Metni ile mevzuat kapsamında Alıcı, yazılı şekilde detaylıca bilgilendirildiğini, ayrıca sipariş öncesinde, ön bilgilendirme onayı alındığını ve ön bilgilendirme onayının, Satış Sözleşmesinin ayrılmaz bir parçası olacağını kabul ve beyan eder.
Satıcı  :          
Alıcı​:          
Tarih  :
`;

export const distanceSale_en = (buyer, guests) => `
DISTANCE SALES CONTRACT
1. PARTIES

1.1. Seller Information: Hereinafter referred to as ‘Seller’.
Title: SKY DİNNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ
Address: 19 Mayıs Mah. Büyükdere Cad. Beytem Plaza No: 20 İç Kapı No: 1 Şişli / İstanbul
MERSIS: 0772154293100001
Contact Information: 
Web Site: dinnerintheskycappadocia.com.tr 
Telephone: +900539 883 61 03
E-mail: info@dinnerintheskycappadocia.com.tr
 
1.2.Buyer Information:
Name Surname: ${buyer.fullName}
Telephone: ${buyer.phone}
E-mail:${buyer.email}
Country: ${buyer.country}
City: ${buyer.city}
Address: ${buyer.address}
 
1.3.Guest Information:
${guests.map((guest, index) =>
  `${index + 1})
Name Surname: ${guest.name}
Date of Birth: ${formatBirthday(guest.birthday)}\n`).join("")}
 
 
 Hereinafter referred to as ‘Buyer’.
In this Agreement, Seller and Buyer shall be referred to as the “Parties” collectively and a “Party” individually.
2. DEFINITIONS
a) Law: The Consumer Protection Law numbered 6502
b) Regulation: Regulation on Distance Contracts dated 27.11.2014,
c) Seller: The legal entity/person who provides the services and/or sell a product to the consumer under its commercial activities,
d) Buyer: A natural or legal person who shops for commercial or non-professional purposes through the website on behalf of himself/herself or the participant in order to benefit from the goods or services provided by the seller
e) Website: The domain named dinnerintheskycappadocia.com.tr which belongs to the Seller for the purposes of e-trade and commercial activities
f) Product/Ticket: Refers to “Dinner in the Sky” experience ticket,
g) Distance Contract: this agreement entered into by the Seller and Buyer,
h) Service: Refers to the activity to be offered by the Seller to the Buyer following the date of ticketing,
i) Personal Data: any information that relates to an identified or identifiable natural person.
j) Guest: Refers to the person who will benefit from the product/service subject to the contract made by the Buyer
 
3. CONTEXT OF THE FORM
The subject of this Agreement is the determination of the rights and obligations of the parties in accordance with Law Code No. 6502 on the  Consumer Protection Law and the Distance Contracts Regulation in relation to the sale and delivery of the product/service whose qualifications and sales price are specified below, which the Buyer ordered electronically via the Internet Address of the Seller's dinnerintheskycappadocia.com.tr The prices listed and advertised on the Website are the selling price. The advertised prices and promises are valid until they are updated and changed. Prices announced for a period of time are valid until the end of the specified period.

The Buyer has applied to the Seller to purchase tickets electronically via the Internet Address in order to benefit from the "Dinner in the Sky" experience services and the Parties have agreed on the terms in the Agreement. The Buyer accepts and declares that the approval of the Agreement and payment means the signature of the Agreement and the emergence of the Buyer's payment obligation.
The Dinner in the Sky experience is an innovative restaurant service that uses a crane to lift the dinner table and the staff 150 feet into the air.  Within the scope of this service, entrance to the event venue and the menu chosen by the Buyer is included in the ticketing and ticket fee and the purchase of the relevant service ticket is required to benefit from different services. Tickets purchased pursuant to the Agreement are only valid for the Buyer to benefit from the relevant package, and other services are subject to additional and different charges.
 
 
4. SERVICE(S)/PRODUCT(S) DESCRIPTIONS AND PRICES
4.1. The basic features (type, quantity) of the Service / Product  are published on The Seller’s website. If a campaign is organized by the Seller, you can review the basic features of the relevant product during the campaign. Valid until the date of the campaign.
4.2. All the prices listed and presented on the website are VAT included. Shipment and other expenses are stated separately if applicable. The announced prices are valid until they are updated or removed. Special offers are valid within the specified period of time unless otherwise stated.
4.3. Prices of the contracted commodity and extra expenses (if applicable) are stated below:

Product:
Quantity:
Price per Unit:
VAT:
Total:

Method of Payment and Plan:                    
Delivery Address:                     
Receiver:                     
Billing Address:                    
Order Date:                    
Delivery Date:                    
Type of Delivery:

​5.SERVICE TERMS
5.1. The Parties agree and declare that the "Preliminary Information Form" and "Terms and Conditions" texts will be directly applicable to the Buyer's use of the contractual services as an integral part of the Contract.
5.2. The Buyer declares that he/she has read the introductory information about the Seller and the "Personal Data Policy" and "Terms and Conditions", " FAQs" Privacy Notice on the Protection and the Processing of Personal Data , Explicit Consent Text, Pre-Contractual Information Form,Privacy and Cookie Policy, Electronic Message Confirmation texts on the Internet Address and accepts that these texts and the explanations on the Internet Address shall be applied in cases where there is no provision in the Agreement, to the extent that they do not contradict the Agreement. The Buyer accepts and declares that, except for the aforementioned texts, the information obtained from any other source (any text including advertisements in all kinds of media including social media), whether belonging to the Seller or not, regarding the "Dinner In The Sky" event cannot be taken as a basis for determining the benefits that the Buyer reasonably expects from the Seller in accordance with the Agreement.
5.3. The Parties acknowledge and agree that the Buyer has read and understood the texts referred to in this clause (3) and that any breach by the Buyer of the provisions of such texts shall be deemed to be a material breach of the Contract by the Buyer, without prejudice to any other material breach.
      6. TICKET SALE
6.1.The Parties acknowledge and declare that the sale of tickets via the Internet Address constitutes only one of the sales and marketing channels of the Seller and that the provisions regarding the tickets may vary according to the sales and marketing channels. The parties agree that the Seller is not responsible for price and content errors arising from typographical and system errors in the Internet Address.
 
6.2. Ticket types, features, and ticket prices are announced via Internet Address. When the buyer selects the ticket that he/she wants to purchase on the payment page of the Internet Address, also can determine the type, feature, and price of the ticket. The Seller reserves the right to unilaterally change the tickets and their prices according to the type, feature, or price of the tickets. The Seller reserves the right to stop or cancel the sale of tickets via the Internet Address at any given time for any given reason.
6.3. When the Buyer selects the ticket to be purchased on the payment page of the Internet Address, the total ticket price including service fee, taxes and charges related to the online sale of the ticket will be displayed.  The Buyer may purchase the ticket by choosing the credit card or debit card options. By purchasing the ticket via the Internet Address, the Buyer is deemed to have agreed to pay all prices shown on the payment page of the Internet Address.
6.4. The Buyer accepts and declares that by processing the credit card or debit card information to the Internet Address, the Buyer consents to the collection of the ticket fee from the relevant credit card or debit card. The electronic copy of the purchased ticket is sent to the e-mail address specified by the Buyer when purchasing the tickets.
 
6.5. During the ticket purchase process, the Buyer is obligated to check whether the ticket selected for purchase is the ticket requested for purchase, and the Seller is not responsible for the Buyer's incorrect ticket purchase. The obligation to control and preserve the purchased tickets belong to the Buyer. The buyer is obligated to present the electronic copy of the ticket to the Seller at the time of the event entrance and to keep the electronic copy of the ticket during the event. The Seller is not responsible for the loss of the electronic copy of the ticket, and the Buyer who cannot present his/her ticket cannot participate in the event. Tickets that are not used on the day are invalid and the fees paid for these tickets will not be refunded or ticket changes will not be made.
6.6. A maximum of 32 tickets can be purchased by a single buyer. If The buyer purchases more than one ticket, he/she must enter the distinguishing information such as name, surname, and date of birth of the participants requested by the Seller into the system. Tickets cannot be transferred to a different person after purchase. For this reason, the Seller has the right to request information and documents confirming the identity of the participants of the event.
6.7.   Tickets are personalized and single-use unless otherwise stated on the Internet Address or in the "Terms and Conditions". The Buyer is obligated to evaluate whether the tickets selected have the qualifications suitable for the ticket type and features by reading the ticket descriptions on the relevant pages on the Internet Address and the descriptions regarding the ticket on the payment page of the Internet Address are only a summary.  In the event that the Buyer does not meet the specifications (e.g. Adult,height) declared when selecting the ticket, the Seller may cancel the Buyer's ticket without any compensation or refund. This provision shall also apply in the event that the Buyer purchases more tickets than the maximum number of tickets determined by the Seller or sells or makes available the tickets purchased by the Buyer to third parties. 
 
7. RIGHT OF WITHDRAWAL
7.1. All ticket sales are final, except for cancellation and/or postponement of the event. According to subparagraph (g) of Article 15 of the Distance Contracts Regulation titled "exceptions to the right of withdrawal", since the contracts for accommodation, goods transport, car hire, food and beverage supply, and leisure time utilization for entertainment or recreation purposes, which must be made on a certain date or period, are among the contracts that fall within the exceptions to the right of withdrawal, and since the subject of the contract is related to the sale of event tickets and is among the contracts for the utilization of leisure time for entertainment or recreation purposes, which must be made on a certain date, the Buyer does not have the right of withdrawal by the Regulation.
7.2.Except in the event of cancellation of the event, tickets sold can not be canceled, changed, or refunded in any way after the sales transaction. However, in the event of cancellation or postponement of the event, the Seller has the right to impose some limitations on the terms of the refund, as well as the right to make changes to the program. However, in cases where it is not possible for the Buyer to attend the rescheduled event, the Buyer may be refunded within 14 days at the latest.  The repayment period may differ depending on the Buyer's bank. The Buyer accepts, declares and undertakes that cannot make any request from the Seller in this regard.
 
 
8. INTELLECTUAL PROPERTY
Regarding the regulation, revision and partially/fully use of any information and content of the Website, all intellectual and industrial property rights of the third parties according to the Seller's agreement belong to the Seller and may not be used without written consent.
 
9.OTHER TERMS
9.1. The Buyer accepts and declares that all information that must be processed in accordance with the relevant legislation without any limitation such as place, time, subject, party and similar information of the transaction carried out through the Internet Address may be recorded by the Seller and that these transaction records will belong exclusively to the Seller, that the Seller may use them both for the purpose of the performance of the contract and external commercial purposes and may share them with third parties, with the authority to dispose of these records.
9.2.  The Seller reserves the right to make changes to the Agreement and the above texts unilaterally by announcing them on the Internet Address. The Parties accept and declare that the announcement of the changes via the Internet Address will be regarded as the notification to the Buyer, the changes will take effect on the seventh day from the date of announcement and the announcement of the changes will be reminded to the Buyer via e -mail on the date of the announcement.
9.3.The Buyer accepts and undertakes the up-to-dateness, validity, and accuracy of the information processed on the Internet Address and the information and documents to be submitted to the Seller within the framework of the Agreement. All physical or electronic notices, announcements, and warnings to be made to these Addresses shall be deemed to have been notified to the Buyer unless the contact information entered in the Internet Address is changed by the Buyer. The Buyer accepts and declares that it shall be deemed to be notified of all notices, warnings and/or announcements made through the Internet Address and permanent data storage and that it consents to the transactions and amendments to the Agreement made through them. The Buyer is liable to notify the Seller in writing of notices and warnings regarding the Contract.
 
9.4. The Parties agree that the books and records of the Seller, computer records, electronic records, server data, data in permanent data storage, telephone and voice records, and other similar documents shall constitute conclusive and exclusive evidence in all transactions pursuant to the Agreement and that the documents and records to be submitted by the Buyer shall be effective only if they are in rapport with the documents and records of the Seller; this provision is in the nature of a written evidence agreement. The Internet Address and the Agreement have been prepared in Turkish and English, and in case of any difference or contradiction between the texts, the Turkish text shall prevail.
 
 
10. SETTLEMENT OF DISPUTES
The governing law shall be Turkish law and Turkish Consumer Arbitration Committees and Consumer Courts shall be the competent courts of jurisdiction.
Istanbul Central (Çağlayan) Courts and Enforcement Offices are authorized to resolve disputes that may arise from this signed Agreement.
 
11. NOTIFICATIONS AND EVIDENTIAL CONTRACT
Any correspondences to be made under this Contract shall be made via email except for the events explicitly stated in the legislation. The parties acknowledge, declare, and undertake that, in the settlement of any disputes arising from this Contract, the Company's official books and commercial records, the electronic and computer and telephone/conversation records held on its databases and servers are binding, precise and exclusive evidence, and this article is in the nature of evidential contract in the sense of Article 193 of the Law on Civil Procedure.
 
 
 
This contract comprising 11 (eleven) articles is concluded and takes immediate effect upon being confirmed by the Buyer in the electronic environment on ../../…..
 
Seller  :          
Buyer​:          
DATE  :          
`;

export const distanceSale_tr = (buyer, guests) => `
MESAFELİ SATIŞ SÖZLEŞMESİ
1. TARAFLAR

1.1. Satıcı Bilgileri: Bundan sonra ‘Satıcı’ olarak anılacaktır.
Ticaret Unvanı: SKY DİNNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ
MERSİS No: 0772154293100001
Adres: 19 Mayıs Mah. Büyükdere Cad. Beytem Plaza No: 20 İç Kapı No: 1 Şişli / İstanbul 
İletişim Bilgileri: 
Web Sitesi: dinnerintheskycappadocia.com.tr
Telefon: +900539 883 61 03
E- posta Adresi: info@dinnerintheskycappadocia.com.tr
 
1.2.Alıcı Bilgileri:
Adı Soyadı: ${buyer.fullName}
Telefon: ${buyer.phone}
E-posta Adresi: ${buyer.email}
Ülke/ Eyalet: ${buyer.country}
Şehir: ${buyer.city}
Adres: ${buyer.address}
 
1.3. Katılımcı Bilgileri
${guests.map((guest, index) =>
  `${index + 1})
Adı Soyadı: ${guest.name}
Doğum Tarihi: ${formatBirthday(guest.birthday)}\n`).join("")}

Bundan sonra ‘Alıcı’ olarak anılacaktır.
İşbu sözleşmede, Satıcı ve Alıcı birlikte “Taraflar, ayrı ayrı “Taraf” olarak anılacaktır.
 
2. TANIMLAR
a) Kanun: 6502 sayılı Tüketicinin Korunması Hakkında Kanunu’nu,
b) Yönetmelik: 27.11.2014 tarihli Mesafeli Sözleşmeler Yönetmeliğini,
c) Satıcı: Ticari amaçla tüketiciye hizmet sunacak kişiyi,
d) Alıcı: Satıcı tarafından sağlanan mal veya hizmetten yararlanmak üzere kendisi veya katılımcı adına internet sitesi üzerinden ticari veya mesleki olmayan amaçlarla  alışveriş yapan gerçek veya tüzel kişiyi,
e) Web Sitesi: Satıcının eticaret ve benzeri faaliyetlerini yürüttüğü dinnerintheskycappadocia.com.tr alan adını,
f) Ürün/Mal/Bilet: Alışverişe konu olan; “Dinner in the Sky” deneyimi bileti,
g) Mesafeli Satış Sözleşmesi: Satıcı ve Alıcı arasında akdedilen işbu satış sözleşmesini,
h) Hizmet: Satıcı tarafından Alıcıya biletleme yapmış olduğu tarih uyarınca sunulacak olan etkinlik faaliyeti,
i) Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,
j) Katılımcı: Alımı Alıcı tarafından yapılan sözleşme konusu ürün veya hizmetten yararlanacak kişiyi
ifade eder.
 
 
3. SÖZLEŞMENİN KONUSU
İşbu Sözleşme’nin konusu, Alıcı’nın Satıcı’ya ait dinnerintheskycappadocia.com.tr alan adlı İnternet Adresi üzerinden elektronik ortamda siparişini verdiği aşağıda nitelikleri ve satış fiyatı belirtilen mal satışı ve hizmet ifası ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Yönetmeliği gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır. Listelenen ve İnternet Adresi'nde ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
 
 
Alıcı, Dinner in the Sky deneyimi hizmetlerinden faydalanmak amacıyla Internet Adresi üzerinden elektronik ortamda bilet satın almak üzere Satıcı’ya başvurmuş ve Taraflar Sözleşmedeki koşullarda anlaşmaya varmışlardır. Alıcı Sözleşmeyi ve ödemeyi onaylamasının, Sözleşmenin imzası ve ödeme yükümlülüğünün doğması anlamına geldiğini kabul ve beyan eder.
 
Dinner in the Sky deneyimi yemek yiyenleri, masayı ve bekleyen personeli 150 fit havaya kaldırmak için bir vinç kullanan, bir yenilikçi restoran hizmetidir. Bu hizmet kapsamında etkinlik alanına giriş ve Alıcı’nın seçmiş olduğu menü biletleme ve bilet ücretine dahil olup farklı hizmetlerinden faydalanılması için ilgili hizmet biletinin satın alınması gerekmektedir. Sözleşme uyarınca satın alınan biletler sadece Alıcı’nın ilgili paketten faydalanmasında geçerli olup, diğer hizmetler ek ve farklı ücretlendirmeye tabidir.
 
 
4. SÖZLEŞMEYE KONU MAL VE HİZMET HAKKINDA BİLGİLER
4.1. Malın/ Hizmetin temel özelliklerini (türü, adedi) Satıcı’ya ait internet adresinde yayınlanmaktadır. Satıcı tarafından kampanya düzenlenmiş ise ilgili ürünün temel özelliklerini kampanya süresince inceleyebilirsiniz. Kampanya tarihine kadar geçerlidir.
4.2 Web Sitesinde belirtilen ve listenen bütün fiyatlar KDV dahil satış fiyatlardır. Varsa kargo ücreti ve ek masraflar ayrıca belirtilir. Anılı fiyatlar ve vaatler güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
4.3. Mesafeli Sözleşmeye konu mal veya hizmetin tüm vergiler ve ek masraflar dahil fiyatı aşağıdaki gibidir:

Ürün Açıklaması:
Adet:
Birim Fiyatı:
KDV:
Toplam:

Ödeme Sekli ve Planı:                     
Teslimat Adresi:                     
Teslim Edilecek Kişi:                     
Fatura Adresi:                    
Sipariş Tarihi:                    
Teslimat Tarihi:                    
Teslim Sekli:
 
 
5. HİZMET KOŞULLARI
5.1. Taraflar, Alıcının sözleşme konusu hizmetlerinden faydalanmasına, “Ön Bilgilendirme Formu” ve “Hüküm ve Koşullar” metinlerinin Sözleşmenin ayrılmaz bir parçası olarak doğrudan uygulanacağını kabul ve beyan edeler.
5.2. Alıcı Internet Adresinde yer alan Satıcı’ya ilişkin tanıtıcı bilgiler ile “     Kişisel Veri Politikası” ve “Hüküm ve Koşullar” “  Sıkça Sorulan Sorular”” Kişisel Verilerin Korunması ve İşlenmesine ilişkin Aydınlatma Metnini, Açık Rıza Metnini, Ön Bilgilendirme Formunu, Çerez politikası, Elektronik İleti Onay metinlerini okuduğunu ve bu metinler ile Internet Adresinde yer alan açıklamaların Sözleşmede hüküm bulunmayan hallerde, Sözleşme ile çelişmediği ölçüde, uygulanmasını kabul ettiğini beyan eder. Alıcı anılan metinler haricinde, Satıcı’ya ait olsun ya da olmasın, herhangi bir açıklamadan (sosyal medya dahil her türlü mecradaki reklam dahil her türlü metin) “Dinner In The Sky” etkinliğine ilişkin edindiği bilgilerin Alıcı’nın Sözleşme uyarınca Satıcı’dan makul olarak beklediği faydaların tayininde esas alınamayacağını kabul ve beyan eder.
 
 
5.3. Taraflar, Alıcının işbu (3) fıkrada anılan metinleri okuduğunu ve anladığını ve Alıcı’nın anılan metinlerin hükümlerini ihlalinin, diğer esaslı ihlal halleri saklı kalmak üzere, Sözleşme’nin Alıcı tarafından esaslı ihlali olarak mütalaa edileceğini kabul ve beyan ederler.
 
6. BİLET SATIŞI
6.1. Taraflar, İnternet Adresi üzerinden bilet satışının Satıcı’nın satış ve pazarlama kanallarından sadece birini oluşturmakta olduğunu ve biletlere ilişkin hükümlerin satış ve pazarlama kanallarına göre değişiklik gösterebileceğini kabul ve beyan ederler. Taraflar, Satıcı’nın Internet Adresindeki dizgi ve sistem hatalarından kaynaklanan fiyat ve içerik hatalarından sorumlu olmadığını kabul ederler.
 
6.2. Bilet türleri, özellikleri ve fiyatları Internet Adresi üzerinden ilan edilmektedir. Alıcı, satın almak istediği bileti Internet Adresinin ödeme sayfasında seçtiğinde, biletin türünü, özelliğini ve fiyatını görebilmektedir. Satıcı, biletlerin türüne, özelliğine veya fiyatına göre biletlerde ve fiyatlarında tek taraflı olarak değişiklik yapma hakkını saklı tutmaktadır. Satıcı’nın Internet Adresi üzerinden bilet satışını dilediği zaman herhangi bir sebeple durdurma ya da iptal etme hakkı saklıdır.
6.3. Alıcı satın almak istediği bileti Internet Adresinin ödeme sayfasında seçtiğinde biletin internetten satılmasına ilişkin hizmet bedeli, vergi ve harçlar dahil, toplam fiyatını görebilecektir.Alıcı, bileti kredi kartı veya banka kartı seçeneklerinden kendisine uygun olanı tercih ederek satın alabilir. Alıcı bileti Internet Adresi üzerinden satın almakla Internet Adresinin ödeme sayfasında gösterilen tüm bedelleri ödemeyi kabul etmiş sayılır.
 
6.4. Alıcı, kredi kartı veya banka kartı bilgilerini Internet Adresine islemekle bilet ücretinin ilgili kredi kartı veya banka kartından tahsil edilmesine onay verdiğini kabul ve beyan eder. Satın alınan biletin elektronik sureti Alıcının biletleri satın alırken belirttiği e- posta adresine gönderilir.
 
6.5. Alıcı, bilet satın alma sürecinde, satın almak için seçmiş olduğu biletin satın almak istediği bilet olup olmadığını kontrol etmekle yükümlü olup, Alıcı’nın hatalı bilet alımından Satıcı sorumlu değildir. Satın alınan biletleri kontrol ve muhafaza etme yükümlülüğü Alıcı’ya ait olup, Alıcı biletin elektronik suretini Satıcı’ya etkinlik girişince ibraz etmek ve etkinlik süresince biletin elektronik suretini muhafaza etmek zorundadır. Biletin elektronik suretinin kaybedilmesinden Satıcı sorumlu olmayıp, biletini ibraz edemeyen Alıcı etkinliğe katılamaz. Gününde kullanılmayan biletler geçersiz olup, bu biletler için ödenen bedellerin iadesi veya bilet değişikliği yapılmaz.
6.6. Tek bir alıcı tarafından en fazla 32 adet bilet alımı yapılabilecektir. Alıcı, birden fazla bilet alımı gerçekleştiriyorsa, Satıcı tarafından talep edilen katılımcılara ait isim, soyisim ve doğum tarihi gibi ayırt edici bilgilerin sisteme girişini yapmalıdır. Biletler satın alındıktan sonra farklı kişiye aktarılamaz. Bu sebeple, Satıcı , etkinlik alanın katılımcılardan kimliklerini doğrular bilgi ve belgeleri talep etme hakkına sahiptir.  
 
6.7. Biletler, aksi Internet Adresinde veya “Hüküm ve Koşullar”da belirtilmedikçe, kişiye özel ve tek kullanımlıktır. Alıcı, kendisinin, seçmiş olduğu biletlerin bilet türü ve özelliklerine uygun nitelikleri haiz olup olmadığını Internet Adresindeki ilgili sayfalardan bilet açıklamaları okuyarak değerlendirmekle yükümlü olup, Internet Adresinin ödeme sayfasındaki bilete ilişkin açıklamalar sadece özet niteliğindedir. Alıcı’nın bilet seçimi yaparken beyanda bulunduğu özellikleri (Örn:Yetişkin,boy) taşımaması halinde, Satıcı Alıcı’nın biletini herhangi bir tazminat ödemeksizin veya geri ödeme yapmaksızın iptal edebilir. Alıcı’nın Satıcı’nın belirlediği azami bilet sayısından fazla bilet alması veya satın almış olduğu biletleri üçüncü kişilere satması ya da kullandırması halinde de bu hüküm uygulanır.
 
 
      7. CAYMA HAKKI
7.1. Etkinliğin iptali ve/veya ertelenmesi haricinde, tüm hizmet satışları kesindir.Mesafeli Sözleşmeler Yönetmeliği’nin "cayma hakkının istisnaları" başlığını taşıyan 15 inci maddesinin (g) bendi uyarınca belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler, cayma hakkının istisnaları arasına giren sözleşmelerden olduğundan ve sözleşme konusu mal/hizmet etkinlik biletleri satışına ilişkin olup belirli bir tarihte yapılması gereken, eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler arasında olduğundan, Yönetmelik gereğince Alıcı’nın cayma hakkı bulunmamaktadır.
 
 
7.2.Etkinliğin iptal edilmesi haricinde, satılan biletler, satış işlemi sonrasında hiçbir şekilde iptal edilmez, değiştirilmez ve ücret iadesi yapılmaz. Bununla birlikte, etkinliğin iptali veya ertelenmesi durumunda Satıcı geri ödeme şartları konusunda bazı sınırlandırmalar getirebilme hakkına sahip olduğu gibi, programda değişiklik yapma hakkına da sahiptir. Ancak, Alıcı’nın tarihi değiştirilmiş etkinliğe katılmasının mümkün olmadığı hallerde Satıcı, Alıcı’ya en geç 14 gün içerisinde kredi/banka kartlarına tam bir geri ödeme yapacağını kabul, beyan ve taahhüt eder.  Geri ödeme süresi Alıcı’nın bankasına bağlı olarak farklılık gösterebilecektir. Alıcı bu hususta Satıcı’dan herhangi bir talepte bulunamayacağını kabul, beyan ve taahhüt eder.
 
 
 
 
8. FİKRİ MÜLKİYET
İnternet Sitesi'ne ait her türlü bilgi ve içerik ile bunların düzenlenmesi, revizyonu ve kısmen/tamamen kullanımı konusunda; Satıcının anlaşmasına göre diğer üçüncü şahıslara ait olanlar hariç; tüm fikri-sınai haklar ve mülkiyet hakları Satıcı’ya aittir, yazılı izin olmaksızın kullanılamaz.
 
9. DİĞER HÜKÜMLER 
9.1. Alıcı, Internet Adresi üzerinden gerçekleştirdiği işlemin yer, zaman, konu, taraf ve benzeri herhangi bir sınırlama olmaksızın ilgili mevzuata uygun olarak işlenmesi gereken tüm bilgilerinin Satıcı tarafından kayıt altına alınabileceğini ve bu işlem kayıtlarının münhasıran Satıcı’ya ait olacağını, Satıcı’nın bu kayıtlar üzerinde tasarruf yetkisini haiz olarak bunları Sözleşme’nin ifası amacı dahili ve harici ticari amaçlarla kullanabileceğini ve 3. kişiler ile paylaşabileceğini kabul ve beyan eder.
9.2. Satıcı Sözleşmede ve yukarıdaki (metinlerde tek taraflı olarak, Internet Adresi üzerinden ilan ederek değişiklik yapma hakkını saklı tutmaktadır. Taraflar değişikliklerin İnternet Adresi üzerinden ilanının Alıcı’ya bildirim yerine geçeceğini, değişikliklerin ilan tarihinden itibaren yedinci günde yürürlüğe gireceğini ve değişikliklerin ilanının Alıcı’ya ilan tarihinde e- posta ile hatırlatılacağını kabul ve beyan ederler.
9.3. Alıcı, Internet Adresine işlemiş olduğu bilgilerin ve Sözleşme çerçevesinde Satıcı’ya sunacağı bilgi ve belgelerin güncelliğini, geçerliliğini ve doğruluğunu kabul ve taahhüt eder. Internet Adresine islenen iletişim bilgileri Alıcı tarafından değiştirilmedikçe bu adreslere yapılacak tüm fiziki veya elektronik bildirim, ilan ve ihtarlar Alıcı’ya tebliğ edilmiş sayılacaktır. Alıcı, Internet Adresi ve kalıcı veri saklayıcısı üzerinden yapılan tüm bildirim, ihtar ve ilanlardan haberdar sayılacağını ve bunlar vasıtasıyla gerçekleştirilen işlem ve Sözleşme değişikliklerine rızası olduğunu kabul ve beyan eder. Alıcı, Sözleşme’ye ilişkin ihbar ve ihtarlarını Satıcı’ya yazılı olarak bildirmekle yükümlüdür.
 
9.4. Taraflar Sözleşme uyarınca tüm işlemlerde Satıcı defter ve kayıtları ile bilgisayar kayıtlarının, elektronik kayıtlarının, sunucu verilerinin, kalıcı veri saklayıcısındaki verilerinin, telefon ve ses kayıtlarının ve benzeri diğer belgelerinin kesin ve münhasır delil teşkil edeceğini ve Alıcı tarafından ibraz edilecek belge ve kayıtların ancak Satıcı belge ve kayıtları ile uyum içinde bulunması halinde hüküm ifade edeceklerini; bu hükmün bir yazılı delil sözleşmesi niteliğinde olduğunu kabul ederler. Internet Adresi ve Sözleşme Türkçe ve İngilizce olarak hazırlanmış, olup metinler arasında bir farklılık veya çelişki olması halinde Türkçe metin esas alınır.
 
10. UYUŞMAZLIKLARIN ÇÖZÜMÜ
İşbu Mesafeli Satış Sözleşmesi'nden doğabilecek uyuşmazlıkların çözümünde Türk hukuku uygulanacak olup, Tüketici Hakem Heyetleri ile Tüketici Mahkemeleri yetkilidir.
Sözleşmeden doğabilecek uyuşmazlıkların çözümünde İstanbul Merkez (Çağlayan) Mahkeme ve İcra daireleri yetkilidir.
 
11. BİLDİRİMLER VE DELİL SÖZLEŞMESİ
İşbu Sözleşme tahtında Taraflar arasında yapılacak her türlü yazışma, mevzuatta sayılan zorunlu haller dışında, e-mail aracılığıyla yapılacaktır. Alıcı, işbu Sözleşme'den doğabilecek ihtilaflarda Satıcı'nın resmi defter ve ticari kayıtlarıyla, kendi veri tabanında, sunucularında tuttuğu elektronik bilgilerin ve bilgisayar ve telefon/görüşme kayıtlarının, bağlayıcı, kesin ve münhasır delil teşkil edeceğini, bu maddenin Hukuk Muhakemeleri Kanunu'nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder.
11 (onbir) maddeden ibaret işbu Sözleşme, Taraflarca okunarak, ../../…. tarihinde, Alıcı tarafından elektronik ortamda onaylanmak suretiyle akdedilmiş ve derhal yürürlüğe girmiştir.
Satıcı  :          
Alıcı​:          
Tarih  :
`;

export const conditions_en = `
TERMS AND CONDITIONS
1) If you make an electronic purchase of the service through the website you are using, you are deemed to have accepted the preliminary information form and the distance sales contract presented to you. As a rule, sales of all services are final. According to subparagraph (g) of Article 15 of the Distance Contracts Regulation titled "exceptions to the right of withdrawal", since the contracts for accommodation, goods transport, car hire, car hire, food and beverage supply, and leisure time utilization for entertainment or recreation purposes, which must be made on a certain date or period, are among the contracts that fall within the exceptions to the right of withdrawal, and since the subject of the contract is related to the sale of event tickets and is among the contracts for the utilization of leisure time for entertainment or recreation purposes, which must be made on a certain date, the Buyer does not have the right of withdrawal by the Regulation. Moreover, before purchasing the ticket by approving your order; you’re accepting, stating, and committing in written form that you are aware of
a) the basic characteristics of the ticket that you want to purchase which is the subject of the contract,
b) that you have met the 150cm height requirement within the scope of the "Dinner in the Sky" safety rule otherwise, you will not be able to participate in the event on the event date,
c) the total ticket/service price including all taxes and all additional expenses –if exists-,
d) that you don’t have right of withdrawal based on article 15/g of the Distance Contracts Regulation, and eventually all the provisions regulated in the contract. Before approving your orders, your pre-briefing approval is received in written form within the scope of your approval. In addition, we would like you to know that that you are informed clearly and apprehensibly by this contract and pre-approval regarding that “you will enter into the obligation of making payment in case of approval of your ticket purchase order”. 
2) Buyers are subjected to the provisions of Law Code No. 6502 on the Consumer Protection Law and the Regulation on Distance Contracts (OG: 27.11.2014/29188) and other applicable laws regarding the service they purchase.
3) The event ticket for the service subject to the contract will be sent to the Buyer via sms and/or e-mail in accordance with the method preferred by the Buyer and no physical delivery will be made. The Buyer is only entitled to the place specified in the sms and/or e-mail sent to him/her, and the Seller reserves the right to provide the Buyer a different place than the place specified in the sms and e-mail.
4) The Seller may change or cancel the date and time of the event subjected to the contract. In cases where it is not possible for the Buyer to participate in the event whose date and time have been changed by the Seller, the Buyer may be refunded. The Seller is not obliged to meet the Buyer's time and date change requests regarding the purchased service. Within the scope of customer satisfaction, the Seller may suggest another time or date that the Buyer can participate in the event at his own discretion. The dinner experience will be Cancelled the refund policy will be as follows: A full refund will apply to their credit/debit card within maximum of 14 days. The repayment procedure may differ depending on your bank therefore you can follow the procedure by contacting your bank.
5) The purchased tickets can not be used for commercial purposes. The individual purchasing the ticket accepts and commits that he had purchased the ticket for his exclusive personal use without the intention of any commercial purpose, and that he will not use the ticket for any commercial works or commercial activities. Within this scope the ticket that is purchased can not be sold to third parties in any manner, its sales by additional fee or in any other manner are prohibited, it can not be used for promotional or other commercial purposes including advertising, contests and sweepstakes. Selling or attempting to sell the ticket with a price over the one written on it constitutes a rightful reason for the confiscation and/or cancellation of the ticket by the authorities without making any refund or without entitling for other requests.
6) A maximum of 32 tickets can be purchased by a single buyer. If the buyer purchases more than one ticket, he/she must enter the distinguishing information such as name, surname, and date of birth of the participants requested by the Seller into the system. Tickets cannot be transferred to a different person after purchase. For this reason, the Seller has the right to request information and documents confirming the identity of the participants of the event.
7)In case of any dispute during the implementation of the aforementioned terms and conditions, the resolution of disputes is subject to Turkish Law. All consumers are informed by dinnerintheskycappadocia.com.tr regarding the provisions and content of the contract before purchasing ticket, and we would like you to know that you will be deemed to have accepted the performance of pre-briefing regarding the regulations of the contract within the frame of Distance Contracts Regulation.  This article is a means of proof thus has he quality of written evidence. In case of any conflict due to this Contract and/or due to the service or ticket purchase operations, it is our basic objective to first try to resolve the conflict amicably.  On the other hand, in cases that can not be resolved amicably, you can refer the possible arising problems to the Consumer Arbitration Boards and/or to Consumer Courts within the frame of monetary limits issued in the relevant legislation.
NOTE: Our web site has been prepared in Turkish and English languages. In case of any difference and/or discrepancy in between the Turkish and English texts, the Turkish text shall prevail.
`;

export const conditions_tr = `
HÜKÜM VE KOŞULLAR
1) Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda hizmete ilişkin satın alım yaptığınız takdirde, size sunulan ön bilgilendirme formunu ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız. Kural olarak tüm hizmetlere ilişkin satışlar kesindir. Mesafeli Sözleşmeler Yönetmeliği’nin "cayma hakkının istisnaları" başlığını taşıyan 15 inci maddesinin (g) bendi uyarınca belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler, cayma hakkının istisnaları arasına giren sözleşmelerden olduğundan ve sözleşme konusu hizmet, etkinlik biletleri satışına ilişkin olup belirli bir tarihte yapılması gereken, eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler arasında olduğundan, Yönetmelik gereğince cayma hakkınız bulunmamaktadır. Ayrıca, siparişinizi onaylayarak; bileti satın almadan önce;
a) sözleşme konusu olan satın almak istediğiniz hizmetin/biletin temel niteliklerini
b) Dinner in the Sky güvenlik kuralı kapsamında 150cm boy şartını sağladığınızı, aksi takdirde etkinlik tarihinde etkinliğe katılım sağlayamayacağınızı,
b) ayrıca tüm vergiler dahil toplam bilet/hizmet fiyatını ve varsa tüm ek masraflarını
d) Mesafeli Sözleşmeler Yönetmeliği'nin 15/g. maddesine istinaden cayma hakkınızın olmadığını ve neticeten sözleşmede düzenlenen tüm hükümleri bildiğinizi yazılı olarak kabul, beyan ve taahhüt etmektesiniz. Siparişlerinizi onaylamadan önce, onayınız kapsamında, yazılı şekilde ön bilgilendirme onayınız alınmıştır. İlaveten, işbu sözleşme ve ön onay ile “bilet satın alma siparişinizin onaylanması durumunda ödeme yükümlülüğü altına gireceğiniz” konusunda açık ve anlaşılır şekilde bilgilendirildiğinizi de önemle bilmenizi isteriz.
2) Alıcılar, satın aldıkları hizmet ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer yasalara tabidir.
3) Sözleşme konusu hizmete ilişkin etkinlik bileti Alıcıya tercih ettiği yöntem doğrultusunda sms ve/veya e-posta ile iletilecek olup ayrıca fiziken teslimat yapılmayacaktır. Alıcı, sadece kendisine gönderilen sms ve/veya e-postada belirtilen yer üzerinde hak sahibi olup, Satıcı, Alıcıya sms ve e-postada belirtilen yerden farklı bir yer verme hakkını saklı tutmaktadır.
4) Satıcı, herhangi bir sebeple sözleşme konusu etkinliğin tarih ve saatini değişebilir, iptal edebilir. Satıcı tarafından tarihi ve saati değiştirilmiş etkinliğe Alıcının katılmasının mümkün olmadığı hallerde, Alıcıya ücret iadesi yapılabilir. Satıcının, satın alınan hizmete ilişkin Alıcının saat ve tarih değişikliği taleplerini karşılama zorunluluğu bulunmamaktadır. Satıcı, müşteri memnuniyeti kapsamında keyfiyeti kendisine ait olmak üzere Alıcının etkinliğe dahil olabileceği başka bir saat veya tarih önerebilir. Satıcının etkinliğin ertelenmesine karar vermesi halinde Alıcı’nın tarihi değiştirilmiş etkinliğe katılması mümkün değilse Alıcı’ya en geç 14 gün içerisinde kredi/banka kartlarına tam bir geri ödeme yapılacaktır. Geri ödeme süresi tamamen aracı bankaya bağlı olarak farklılık gösterebilmektedir.
5)Satın alınan mal veya hizmet ticari amaçla kullanılamaz. Alıcı, satın aldığı mal veya hizmeti hiçbir ticari amaç gütmeksizin, münhasıran şahsi kullanımı için satın aldığını, hiçbir ticari iş veya ticari faaliyet için kullanmayacağını beyan ve taahhüt eder. Bu kapsamda satın alınan mal veya hizmet üçüncü kişilere her ne şekilde olursa olsun satılamaz, ilave ücretle ya da başkaca şekilde satışı teklif edilemez, satın alınan mal veya hizmet, reklam, yarışma ve çekilişler de dahil olmak üzere promosyon veya diğer ticari maksatlarla kullanılamaz. Mal ve hizmetin, etkinlik bileti üzerinde yazılı fiyattan daha yüksek bir fiyatla satılması veya satışına teşebbüs edilmesi, hiçbir ücret iadesi yapılmaksızın veya başkaca taleplere hak kazandırmaksızın, yetkililerce bilete el koyma ve/veya etkinlik biletinin iptali için haklı bir sebep teşkil eder.
6)Tek bir alıcı tarafından en fazla 32 adet bilet alımı yapılabilecektir. Alıcı, birden fazla bilet alımı gerçekleştiriyorsa, Satıcı tarafından talep edilen katılımcılara ait isim, soyisim ve doğum tarihi gibi ayırt edici bilgilerin sisteme girişini yapmalıdır. Biletler satın alındıktan sonra farklı kişiye aktarılamaz. Bu sebeple, Satıcı , etkinlik alanın katılımcılardan kimliklerini doğrular bilgi ve belgeleri talep etme hakkına sahiptir.
7)Yukarıda belirtilen şart ve koşulların uygulanması sırasında herhangi bir uyuşmazlık durumunda uyuşmazlıkların çözümü Türk Hukukuna tabidir. Sözleşme konusu mal veya hizmeti satın almadan önce sözleşme hükümleri ve içeriği konusunda, tüm Alıcılar “dinnerintheskycappadocia.com.tr “ tarafından bilgilendirilmiş olup, etkinlik biletini satın alarak ve siparişi onaylayarak, işbu sözleşmenin düzenlemeleri hakkında tarafınıza Mesafeli Sözleşmeler Yönetmeliği çerçevesinde ön bilgilendirme yapıldığını da kabul etmiş sayılacağınızı bilmenizi isteriz. Bu madde yazılı delil vasfında ispat vasıtasıdır. İşbu Sözleşmeden ve/veya hizmet ya da etkinlik bileti satın alma işlemlerinden dolayı herhangi bir ihtilafın doğması durumunda, uyuşmazlığı öncelikle sulhen gidermeye çalışmak temel hedefimizdir. Buna karşın, sulh ile çözülemeyen durumlarda, doğabilecek olası sorunları ilgili mevzuatta yayımlanan parasal sınırlar çerçevesinde Tüketici Hakem Heyetlerine ve/veya Tüketici Mahkemelerine götürebilirsiniz.
NOT: Sitemiz, Türkçe ve İngilizce dillerinde hazırlanmıştır. Türkçe ve İngilizce metinler arasında herhangi bir farklılık ve/veya çelişki olması halinde Türkçe metin esas alınacaktır.
`;

export { enText as kvkk_en, trText as kvkk_tr } from 'pages/PrivacyPolicy/text'

export const electronic_en = `
COMMERCIAL ELECTRONIC MESSAGE STATEMENT OF CONSENT
I hereby approve the sending of commercial electronic messages to my contact addresses, which I declared above and which I will share with SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ (“Company”) in the future, within the scope of publicity, advertisement, campaign, offer, promotion, announcement, celebration, survey, sweepstakes, contest, sending e-bulletins and similar marketing activities, through e-mail, and the processing of my personal data limited to the identity, communication, professional experience and marketing data categories within the framework of relevant processes, for the purposes of company / products / services loyalty processes, conducting of communication activities, conducting / inspection of business activities, organization and event management, conducting of marketing analysis studies, carrying out of advertisement / campaign / promotion processes, carrying out of storage and archiving activities and the conducting of product / service marketing processes, and the transferring to your service providers located / domiciled in Turkey and/or abroad.`;

export const electronic_tr = `
Ticari Elektronik İleti Onay Metni
Burada beyan ettiğim ve ilerleyen dönemlerde SKY DINNER RESTORAN TURİZM VE TİCARET ANONİM ŞİRKETİ (“Şirket”) ile paylaşacağım iletişim bilgilerime, Şirket tarafından tanıtım, reklam, kampanya, teklif, promosyon, duyuru, kutlama, anket, çekiliş, yarışma, e-bülten gönderimi ve benzeri pazarlama faaliyetleri kapsamında e-posta vasıtasıyla ticari elektronik ileti gönderilmesine ve söz konusu süreçler çerçevesinde kimlik, iletişim, müşteri işlem ve pazarlama veri kategorileri ile sınırlı kişisel verilerimin, firma / ürün / hizmetlere bağlılık süreçlerinin yürütülmesi, iletişim faaliyetlerinin yürütülmesi, müşteri memnuniyetine yönelik aktivitelerin yürütülmesi, organizasyon ve etkinlik yönetimi, reklam / kampanya / promosyon süreçlerinin yürütülmesi ve ürün / hizmetlerin pazarlama süreçlerinin yürütülmesi amaçlarıyla işlenmesine ve yurt içinde ve/veya yurt dışında yerleşik Şirket’in hizmet temin ettiği tedarikçilere, topluluk şirketlerine ve iş ortaklarına aktarılmasına onay veriyorum.`;
