import AppLayout from 'layouts/AppLayout';
import {
  HomePage,
  BookingPage,
  SuccessPaymentPage,
  PrivacyPolicyPage,
  FaqPage,
  CookieManagementPage,
  TermsPage,
  NotFoundPage,
  FailedPaymentPage
} from 'pages';

export const PATHS = Object.freeze({
  HOME: '/',
  BOOKING: '/booking',
  PRIVACY_POLICY: '/privacy-policy',
  SUCCESS_PAYMENT: '/success-payment',
  FAILED_PAYMENT: '/failed-payment',
  COOKIE_POLICY: '/cookie-policy',
  FAQ: '/faq',
  COOKIE_MANAGEMENT: '/cookie-management',
  TERMS: '/terms-conditions',
});

export const ROUTES = Object.freeze([
  {
    key: 'hc',
    name: 'hc',
    path: '/hc',
    component: () => <div>ok</div>
  },
  {
    key: 'home',
    name: 'home',
    path: PATHS.HOME,
    layout: AppLayout,
    component: HomePage
  },
  {
    key: 'booking',
    name: 'booking',
    path: PATHS.BOOKING,
    layout: AppLayout,
    component: BookingPage
  },
  {
    key: 'success-payment',
    name: 'success-payment',
    path: PATHS.SUCCESS_PAYMENT,
    layout: AppLayout,
    component: SuccessPaymentPage
  },
  {
    key: 'failed-payment',
    name: 'failed-payment',
    path: PATHS.FAILED_PAYMENT,
    layout: AppLayout,
    component: FailedPaymentPage
  },
  {
    key: 'faq',
    name: 'faq',
    path: PATHS.FAQ,
    layout: AppLayout,
    component: FaqPage
  },
  {
    key: 'privacy-policy',
    name: 'privacy-policy',
    path: PATHS.PRIVACY_POLICY,
    layout: AppLayout,
    component: PrivacyPolicyPage
  },
  {
    key: 'cookie-management',
    name: 'cookie-management',
    path: PATHS.COOKIE_MANAGEMENT,
    layout: AppLayout,
    component: CookieManagementPage
  },
  {
    key: 'terms',
    name: 'terms',
    path: PATHS.TERMS,
    layout: AppLayout,
    component: TermsPage
  },
  {
    key: 'not-found',
    name: 'not found',
    path: '*',
    layout: AppLayout,
    component: NotFoundPage
  }
]);
