import React from 'react'
import {
  BookContainer,
  Text,
  Title,
  ButtonsRow
} from './style';
import { LanguageContext } from 'contexts/Languages';
import Button from 'components/Button';

const PHONE_NUMBER = '905354701446';
const EMAIL = 'info@dinnerintheskycappadocia.com.tr';

const Booking = () => {
  const { t } = React.useContext(LanguageContext);

  const handleEmail = () => {
    // window.location.href = 'mailto:info@dinnerintheskycappadocia.com.tr?subject=Booking Request&body=';
    window.location.href = `mailto:${EMAIL}?subject=Booking Request`;
  }

  const handleWhatsApp = () => {
    window.open(`https://wa.me/${PHONE_NUMBER}?text=Booking Request`, '_blank');
  }

  return (
    <BookContainer>
      <Title>{t('bookingModal.title')}</Title>
      <Text>{t('bookingModal.description')}</Text>

      <ButtonsRow>
        <Button
          title={'WhatsApp'}
          onClick={handleWhatsApp}
          backgroundColor={'#139CD7'}
          overlayColor={'#002424'}
          fullSize
        />
        <Button
          title={'E-MAIL'}
          onClick={handleEmail}
          backgroundColor={'#139CD7'}
          overlayColor={'#002424'}
          fullSize
        />
      </ButtonsRow>
    </BookContainer>
  )
}

export default Booking
