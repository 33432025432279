import PropTypes from 'prop-types';
// import { ReactComponent as Arrow } from 'assets/img/icons/arrow.svg';
import {
  ButtonUI,
  TextButtonUI,
  Loader,
  Dot,
  ButtonOverlay,
  ArrowIcon
} from './style';

const Button = ({
  title,
  onClick,
  fullSize,
  containerStyle,
  loading,
  size,
  disabled,
  overlayColor,
  backgroundColor,
  textColor,
  responsive,
  ...props
}) => {
  return (
    <ButtonUI
      onClick={onClick}
      $fullSize={fullSize}
      $loading={loading}
      disabled={loading || disabled}
      $containerStyle={containerStyle}
      $size={size}
      $bg={backgroundColor}
      $textColor={textColor}
      $responsive={responsive}
      {...props}
    >
      <ButtonOverlay
        $color={overlayColor}
        preserveAspectRatio={'xMinYMin slice'}
      />
      {!loading ?
        title
        :
        <Loader>
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
        </Loader>
      }
    </ButtonUI>
  )
};

export const TextButton = ({
  title,
  onClick,
  fullSize,
  containerStyle,
  loading,
  size,
  textButton,
  disabled,
  hasArrow,
  color,
  responsive,
  dotColor,
  ...props
}) => {
  return (
    <TextButtonUI
      onClick={onClick}
      $fullSize={fullSize}
      $loading={loading}
      disabled={loading || disabled}
      $containerStyle={containerStyle}
      $size={size}
      $color={color}
      $responsive={responsive}
      {...props}
    >
      {title}
      {/* {hasArrow && <Arrow />} */}
      {(hasArrow && !loading) && <ArrowIcon $size={size} />}
      {(loading) && <Loader $sx={{ marginLeft: '14px' }}>
        <Dot $dotColor={dotColor ? dotColor : '#139cd7'} />
        <Dot $dotColor={dotColor ? dotColor : '#139cd7'} />
        <Dot $dotColor={dotColor ? dotColor : '#139cd7'} />
        <Dot $dotColor={dotColor ? dotColor : '#139cd7'} />
        <Dot $dotColor={dotColor ? dotColor : '#139cd7'} />
        <Dot $dotColor={dotColor ? dotColor : '#139cd7'} />
      </Loader>}
    </TextButtonUI>
  )
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  fullSize: PropTypes.bool,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  size: PropTypes.oneOf(['sm', 'md']),
};

Button.defaultProps = {
  title: '',
  onClick: () => { },
  fullSize: false,
  containerStyle: {},
  size: 'md',
};

TextButton.defaultProps = {
  title: '',
  onClick: () => { },
  fullSize: false,
  containerStyle: {},
  size: 'md',
};

export default Button;
