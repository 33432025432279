import React from 'react';
import SectionImage from 'assets/img/image/section-dits.png';
import {
  Container,
  Content,
  Image,
  Title,
  SubTitle,
  Description
} from './style';

const About = ({
  sectionId,
  t
}) => {
  return (
    <Container id={sectionId}>
      <Content>
        <Title>{t('common.cappadocia')}</Title>
        <SubTitle>Dinner in the sky 2.0</SubTitle>
        <Description>
          {t('pages.home.about_section.text')}
        </Description>
      </Content>
      <Image src={SectionImage} alt={'Section'} />
    </Container>
  )
};

export default About;
