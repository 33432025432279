import styled from 'styled-components';
import { ReactComponent as Arrow } from 'assets/img/icons/arrow_down.svg';
import { Select, InputLabel, MenuItem as MenuItemMui } from '@mui/material';

export const StyledSelect = styled(Select)`
  &:focus {
    background-color: transparent;
  }
  &:active {
    background-color: transparent;
  }

  .MuiSelect-select {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #002424;
    opacity: 0.8;

    &:focus {
      background-color: transparent;
    }
  }

  &.MuiInputBase-root::after {
    border-bottom-color: ${({ theme }) => theme.colors.brand};
  }
`;

export const Label = styled(InputLabel)`
  margin-top: 2px !important;
  /* position: relative !important; */
  /* top: -15px !important; */
`;

export const ArrowIcon = styled(Arrow)`
  position: absolute;
  right: 0;
  width: 14px;
  height: 14px;
  cursor: default;
  transition: transform 0.3s linear;
  transform: scale3d(2, 2, 2);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  user-select: none;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;

  path {
    fill: ${({ theme, $open }) => $open ? theme.colors.brand : '#002424'};
  }
`;

export const MenuItem = styled(MenuItemMui)`
  
`;
