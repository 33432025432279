import styled, { css } from 'styled-components';
import TextField from '@mui/material/TextField';

export const Wrapper = styled.div`
  margin-bottom: 15px;
  width: ${({ $fullWidth }) => $fullWidth ? '100%' : 'fit-content'};
  ${({ $containerStyle }) => $containerStyle};
  ${({ $fullWidth }) => !$fullWidth && css`
    min-width: min-content;
  `};
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  ${({ $inputStyle }) => $inputStyle}
`;

export const Input = styled(TextField)`
  outline: none;
  margin-top: 5px;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #002424;
  opacity: 0.8;

  ${({ $inputStyle }) => $inputStyle};
  caret-color: ${({ theme }) => theme.colors.brand};

  ${({ $error }) => $error && css`
    :not(:focus) {
      box-shadow: 0px 12px 23px rgba(255, 86, 48, 0.06);
    }
  `}

  .MuiInput-root.Mui-disabled:before {
    border-bottom-style: solid;
  }

  .MuiInput-underline::after {
    border-bottom-color: ${({ theme }) => theme.colors.brand};
  }
  /* .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused */
  & > label.Mui-focused {
    color: ${({ theme }) => theme.colors.brand};
  }
  & > label {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    color: #002424;
  }

  /* MuiInputBase-input MuiInput-input css-1x51dt5-MuiInputBase-input-MuiInput-input */
  .MuiInputBase-input { 
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.brand};
  }

  :disabled {
    cursor: not-allowed;
    opacity: .8;
  };

  &[type=password] {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #54565B;
  };

  ::placeholder {
    user-select: none;
    color: #B0B7C3;
  }
`;

export const ErrorText = styled.span`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: .5px;
  color: ${({ theme }) => theme.colors.error};
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  position: absolute;
`;
