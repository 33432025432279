import axios from 'axios';
import { getLocalStorage } from 'helpers';

export const BASE_API = process.env.REACT_APP_BASE_API;
const defaultLang = getLocalStorage('lang');

export const withToken = async (config) => {
  const tokens = getLocalStorage('tokens');

  if (tokens?.access_token) {
    config.headers.Authorization = `Bearer ${tokens.access_token}`;
  }
  return config;
};

const _appInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}`
});

export const appInstance = ({ lang = defaultLang }) => {
  _appInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}`;
  _appInstance.interceptors.request.use(withToken);
  return _appInstance;
};

const _bookingInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}`
});

export const bookingInstance = ({ lang = defaultLang }) => {
  _bookingInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/booking`;
  _bookingInstance.interceptors.request.use(withToken);
  return _bookingInstance;
};
