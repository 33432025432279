import styled from 'styled-components';
import { ReactComponent as IyzicoImg } from 'assets/img/logo/iyzico.svg'

export const Container = styled.footer`
  padding-bottom: 35px;
  max-width: 1900px;
  margin: 0 auto;
`;

export const Content = styled.div`
  padding: 0 52px;
  padding-top: 40px;
  width: 85%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 90%;
    padding: 0px 0px 0px 32px;
  }
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    padding: 0;
    padding-top: 11px;
    border-top: 4px solid #C8C8C8;
  }
`;

export const RightText = styled.span`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #002424;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    text-align: center;
  }  
`;

export const TopArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 30px; */
  padding-bottom:10px;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const BottomArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const LinkArea = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  height: 100%;
  align-items: center;
  &>:not(:last-child) {
    margin-right: 20px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-top: 30px;
    flex-direction: column;
    gap: 30px;
    &>:not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const LinkItem = styled.li`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #002424;
`;

export const SocialMediaArea = styled.ul`
  display: flex;
  list-style: none;
  position: relative;
  /* top: -20px; */
  padding: 38px 0;
  &>:not(:last-child) {
    margin-right: 24px;
  }
  a {
    display:block;
    svg {
      display:block
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    top: 0px;
    padding-top: 20px;
    margin: 0 auto;
  }
`;

export const SocialMediaItem = styled.li`

`;

export const IyzicoLogo = styled(IyzicoImg)`
  width: 350px;
  height: 100px;
  padding-right: 20px;
`;
