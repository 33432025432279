import styled, { css } from 'styled-components';
// import { ReactComponent as Logo } from 'assets/img/logo/Logo.svg';
import { ReactComponent as Logo } from 'assets/img/logo/dits-logo-full.svg';
import { motion } from 'framer-motion';

export const Container = styled.header`
  position: fixed;
  z-index: 22;
  top: 0;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 54px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  ${({ $mobile }) => $mobile && css`
    padding: 12px 24px;
  `}
`;

export const HeaderLogo = styled(Logo)`
  cursor: pointer;
  width:auto;
  height:60px;
`;

export const SectionsContainer = styled.ul`
  display: flex;
  list-style-type: none;
  align-items: center;
  & > li:not(:first-child) {
    margin-left: 28px;
  }
`;

export const SectionText = styled.li`
  cursor: pointer;
  text-transform: uppercase;
  color: #231F20;
  white-space: nowrap;

  &::after {
    content: "";
    transition: width .5s cubic-bezier(.785,.135,.15,.86);
    display: block;
    height: 2px;
    width: 0;
    position: relative;
    bottom: -10px;
    background-color: #231F20;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 34px;
  background: #C8C8C8;
  margin-left: 18px;
  margin-right: 13.73px;
`;

export const SelectContainer = styled.select`
  border: none;
  outline: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7' fill='none'%3E%3Cpath d='M5.72881 6.875C5.61214 6.875 5.49547 6.854 5.37881 6.812C5.26214 6.77067 5.16214 6.70833 5.07881 6.625L0.553808 2.075C0.370475 1.90833 0.278809 1.69167 0.278809 1.425C0.278809 1.15833 0.370475 0.941666 0.553808 0.775C0.720475 0.591666 0.937142 0.504 1.20381 0.512C1.47047 0.520667 1.69547 0.608333 1.87881 0.775L5.72881 4.65L9.60381 0.775C9.77048 0.591666 9.98714 0.5 10.2538 0.5C10.5205 0.5 10.7455 0.591666 10.9288 0.775C11.0955 0.941666 11.1788 1.15833 11.1788 1.425C11.1788 1.69167 11.0955 1.90833 10.9288 2.075L6.37881 6.625C6.27881 6.725 6.17481 6.79167 6.06681 6.825C5.95814 6.85833 5.84548 6.875 5.72881 6.875Z' fill='%23C8C8C8'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0 center;
  
  /* height: 100%; */
  padding-right: 20px;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #C8C8C8;
  cursor: pointer;
  ${({ $sx }) => $sx && $sx};
`;

export const HamburgerButton = styled.button`
  border: none;
  outline: none;
  background: none;
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const MobileOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: #1E1E1E;
  /* opacity: .7; */
  z-index: 12;
`;

export const MobileContainer = styled(motion.div)`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-top: 59px;
  height: ${({ $headerHeight }) => `calc(100vh - ${$headerHeight}px)`};
  width: ${({ $isMobile }) => ($isMobile ? '100vw' : '416px')};
  background: #fff;
  top: ${({ $headerHeight }) => $headerHeight || 0}px;
  right: 0;
  z-index: 22;
  overflow-x: hidden;
  overflow-y: auto;

  @media only screen and (orientation: landscape) {
    padding-top: 0;
  }
`;

export const MobileSectionsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  align-items: center;
  margin-top: 40px;
  & > li:not(:first-child) {
    margin-top: 28px;
  }
`;

export const MobileLangContainer = styled.select`
  border: none;
  outline: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='9' viewBox='0 0 12 7' fill='none'%3E%3Cpath d='M5.72881 6.875C5.61214 6.875 5.49547 6.854 5.37881 6.812C5.26214 6.77067 5.16214 6.70833 5.07881 6.625L0.553808 2.075C0.370475 1.90833 0.278809 1.69167 0.278809 1.425C0.278809 1.15833 0.370475 0.941666 0.553808 0.775C0.720475 0.591666 0.937142 0.504 1.20381 0.512C1.47047 0.520667 1.69547 0.608333 1.87881 0.775L5.72881 4.65L9.60381 0.775C9.77048 0.591666 9.98714 0.5 10.2538 0.5C10.5205 0.5 10.7455 0.591666 10.9288 0.775C11.0955 0.941666 11.1788 1.15833 11.1788 1.425C11.1788 1.69167 11.0955 1.90833 10.9288 2.075L6.37881 6.625C6.27881 6.725 6.17481 6.79167 6.06681 6.825C5.95814 6.85833 5.84548 6.875 5.72881 6.875Z' fill='%23002424'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0 center;
  
  padding-right: 27px;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 18px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #002424;
  cursor: pointer;
  ${({ $sx }) => $sx && $sx};
`;

export const MobileSectionText = styled.li`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  color: #002424;
  cursor: pointer;
  white-space: nowrap;

  &::after {
    content: "";
    transition: width .5s cubic-bezier(.785,.135,.15,.86);
    display: block;
    height: 2px;
    width: 0;
    position: relative;
    bottom: -10px;
    background-color: #231F20;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
`;

export const MobileMenuTop = styled.div`
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 40px; */
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
`;

