import React from 'react';
// import MenuImage from 'assets/img/image/menu-section.png';
import MenuImage from 'assets/img/image/menu-section-2.png';
import CloudImg from 'assets/img/image/menu-cloud.png';
// import LunchImg from 'assets/img/menu-types/lunch.png';
// import DinnerImg from 'assets/img/menu-types/dinner.png';
// import CocktailImg from 'assets/img/menu-types/cocktail.png';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal';
import MenuModal from './components/MenuModal';
import DinnerAsset from 'assets/img/image/menu/dinner_menu.png';
import LunchAsset from 'assets/img/image/menu/lunch_menu.png';

import menuOne1 from 'assets/img/image/menu/menu1-1.png';
import menuOne2 from 'assets/img/image/menu/menu1-2.png';
import menuOne3 from 'assets/img/image/menu/menu1-3.png';
import menuOne4 from 'assets/img/image/menu/menu1-4.png';

import menuTwo1 from 'assets/img/image/menu/menu2-1.png';
import menuTwo2 from 'assets/img/image/menu/menu2-2.png';
import menuTwo3 from 'assets/img/image/menu/menu2-3.png';
import menuTwo4 from 'assets/img/image/menu/menu2-4.png';

import menuThree1 from 'assets/img/image/menu/menu3-1.png';
import menuThree2 from 'assets/img/image/menu/menu3-2.png';
import menuThree3 from 'assets/img/image/menu/menu3-3.png';
import menuThree4 from 'assets/img/image/menu/menu3-4.png';

import menuFour1 from 'assets/img/image/menu/menu4-1.png';
import menuFour2 from 'assets/img/image/menu/menu4-2.png';
import menuFour3 from 'assets/img/image/menu/menu4-3.png';
import menuFour4 from 'assets/img/image/menu/menu4-4.png';


import CocktailAsset from 'assets/img/image/menu/cocktail_menu.png';
import { LanguageContext } from 'contexts/Languages';
import {
  Container,
  TopSection,
  Content,
  Image,
  BackTitle,
  Title,
  SubText,
  BriefText,
  MenuList,
  MenuContainer,
  MenuCard,
  MenuContent,
  MenuMeal,
  MenuItemName,
  MenuBrief,
  MenuTypeImage,
  CloudImage,
  ImageContainer
} from './style';

const menuOneEN = [
  {
    type: 'salad',
    foods: [
      {
        imageElem: menuOne1,
      },
      {
        foodDescription: 'Garden Salad'
      },
      {
        foodName: 'Mediterranean greens, iceberg lettuce, rocket leaves, dill, parsley, fresh mint, tomato, cucumber, beetroot, olive oil-lemon dressing'
      }
    ]
  },
  {
    type: 'starter',
    foods: [
      {
        imageElem: menuOne2,
      },
      {
        foodDescription: 'Vegetable Nachos'
      },
      {
        foodName: 'Jalapeno pepper, Mexican beans, cheddar cheese, hot sauce, guacamole and sour cream'
      }
    ]
  },
  {
    type: 'main',
    foods: [
      {
        imageElem: menuOne3,
      },
      {
        foodDescription: 'Seabass Medallions'
      },
      {
        foodName: 'Buttered potatoes, onion rocket salad and garlic bread'
      }
    ]
  },
  {
    type: 'dessert',
    foods: [
      {
        imageElem: menuOne4,
      },
      {
        foodDescription: 'Chilled Minty Lemonade Cake'
      },
      {
        foodName: 'Lemon sauce, strawberry and mango flavoured tapioca pearls'
      }
    ]
  }
];

const menuOne = [
  {
    type: 'salad',
    foods: [
      {
        imageElem: menuOne1,
      },
      {
        foodDescription: 'Bahçe Salata'
      },
      {
        foodName: 'Akdeniz yeşillikleri, göbek marul, roka, dereotu, maydanoz, taze nane, domates, salatalık, pancar, zeytinyağı ve limon sos'
      }
    ]
  },
  {
    type: 'starter',
    foods: [
      {
        imageElem: menuOne2,
      },
      {
        foodDescription: 'Sebzeli Nachos'
      },
      {
        foodName: 'Jalapeno biberi, Meksika fasülyesi, cheddar peynir, aci sos, guacamole ve ekşi krema ile'
      }
    ]
  },
  {
    type: 'main',
    foods: [
      {
        imageElem: menuOne3,
      },
      {
        foodDescription: 'Levrek Lokum'
      },
      {
        foodName: 'Tereyağlı patates, soğanlı roka salatası ve sarımsaklı ekmek'
      }
    ]
  },
  {
    type: 'dessert',
    foods: [
      {
        imageElem: menuOne4,
      },
      {
        foodDescription: 'Soğuk Naneli Limonatalı Pasta'
      },
      {
        foodName: 'Limon sos, çilekli ve mangolu tapyoka topları'
      }
    ]
  }
];



const menuTwoEN = [
  {
    type: 'salad',
    foods: [
      {
        imageElem: menuTwo1,
      },
      {
        foodDescription: 'Panzanella'
      },
      {
        foodName: 'Fresh mozzarella, iceberg lettuce, red onion, mixed olives, cherry tomatoes, fresh basil, cucumber, crutons, pomegranate molasess and olive dressing'
      }
    ]
  },
  {
    type: 'starter',
    foods: [
      {
        imageElem: menuTwo2,
      },
      {
        foodDescription: 'Peri Peri Marinated Eggplant With Hummus'
      },
      {
        foodName: 'Crispy tortillas, cherry tomatoes and fresh rocket leaves'
      }
    ]
  },
  {
    type: 'main',
    foods: [
      {
        imageElem: menuTwo3,
      },
      {
        foodDescription: 'Entrecote With Mushroom Sauce'
      },
      {
        foodName: 'Oven-baked mix vegetables, creamy potatoes and green salad with mung beans'
      }
    ]
  },
  {
    type: 'dessert',
    foods: [
      {
        imageElem: menuTwo4,
      },
      {
        foodDescription: 'Profiterol With Hazelnut Cream'
      },
      {
        foodName: 'Hazelnut cream filled profiterolles with chocolate and hazelnut sauce'
      }
    ]
  }
];

const menuTwo = [
  {
    type: 'salad',
    foods: [
      {
        imageElem: menuTwo1,
      },
      {
        foodDescription: 'Panzanella'
      },
      {
        foodName: 'Akdeniz yeşillikleri, göbek marul, roka, dereotu, maydanoz, taze nane, domates, salatalık, pancar, zeytinyağı ve limon sos'
      }
    ]
  },
  {
    type: 'starter',
    foods: [
      {
        imageElem: menuTwo2,
      },
      {
        foodDescription: 'Peri Peri Soslu Patlıcan ve Humus'
      },
      {
        foodName: 'Kıtır tortilla ekmekleri, çeri domates ve roka yaprakları'
      }
    ]
  },
  {
    type: 'main',
    foods: [
      {
        imageElem: menuTwo3,
      },
      {
        foodDescription: 'Mantar Soslu Antrikot'
      },
      {
        foodName: 'Fırınlanmış sebzeler, kremali patates, maş piyazlı yeşil salata'
      }
    ]
  },
  {
    type: 'dessert',
    foods: [
      {
        imageElem: menuTwo4,
      },
      {
        foodDescription: 'Fındık Kremalı Profiterol'
      },
      {
        foodName: 'Fındık ezmesi kremalı profiterol ve çikolata sos'
      }
    ]
  }
];


const menuThreeEN = [
  {
    type: 'salad',
    foods: [
      {
        imageElem: menuThree1,
      },
      {
        foodDescription: 'Panzanella'
      },
      {
        foodName: 'Fresh mozzarella, iceberg lettuce, red onion, mixed olives, cherry tomatoes, fresh basil, cucumber, crutons, pomegranate molasess and olive dressing'
      }
    ]
  },
  {
    type: 'starter',
    foods: [
      {
        imageElem: menuThree2,
      },
      {
        foodDescription: '"Mücver" and Grilled Zucchini Rolls'
      },
      {
        foodName: 'Minty yoghurt dip and rocket leaves'
      }
    ]
  },
  {
    type: 'main',
    foods: [
      {
        imageElem: menuThree3,
      },
      {
        foodDescription: 'Chicken Cutlet With Lemon Sauce'
      },
      {
        foodName: 'Rosemary baby potatoes and rocket &amp; red onion salad'
      }
    ]
  },
  {
    type: 'dessert',
    foods: [
      {
        imageElem: menuThree4,
      },
      {
        foodDescription: "Chef's Chocolate Tart"
      },
      {
        foodName: 'Chocolate tart, crispy crepe pieces and vanilla sauce'
      }
    ]
  }
];

const menuThree = [
  {
    type: 'salad',
    foods: [
      {
        imageElem: menuThree1,
      },
      {
        foodDescription: 'Panzanella'
      },
      {
        foodName: 'Taze Mozzerella, göbek marul, kırmızı soğan, zeytin çeşitleri, çeri domates, taze fesleğen, salatalık, kruton ve nar ekşili zeytin ezmesi sos'
      }
    ]
  },
  {
    type: 'starter',
    foods: [
      {
        imageElem: menuThree2,
      },
      {
        foodDescription: 'Sebze Mücver ve Izgara Sakız Kabağı Sarma'
      },
      {
        foodName: 'Naneli yoğurt sos ve roka yaprakları'
      }
    ]
  },
  {
    type: 'main',
    foods: [
      {
        imageElem: menuThree3,
      },
      {
        foodDescription: 'Limon Soslu Tavuk Külbastı'
      },
      {
        foodName: 'Biberiyeli patates ve soğanlı roka salatası'
      }
    ]
  },
  {
    type: 'dessert',
    foods: [
      {
        imageElem: menuThree4,
      },
      {
        foodDescription: 'Şefin Çikolatalı Tartı'
      },
      {
        foodName: 'Çok çikolatalı tart, çıtır krep kırıkları ve vanilya sos'
      }
    ]
  }
];





const menuFourEN = [
  {
    type: 'salad',
    foods: [
      {
        imageElem: menuFour1,
      },
      {
        foodDescription: 'Garden Salad'
      },
      {
        foodName: 'Mediterranean greens, iceberg lettuce, rocket leaves, dill, parsley, fresh mint, tomato, cucumber, beetroot, olive oil-lemon dressing'
      }
    ]
  },
  {
    type: 'starter',
    foods: [
      {
        imageElem: menuFour2,
      },
      {
        foodDescription: 'Mushroom Stuffed Falafel'
      },
      {
        foodName: 'Mushroom stuffed falafel, fresh greens, tomato with balsamic, tahini sauce'
      }
    ]
  },
  {
    type: 'main',
    foods: [
      {
        imageElem: menuFour3,
      },
      {
        foodDescription: 'Gluten Free Pasta'
      },
      {
        foodName: 'With fresh tomato sauce (It has a cooking period of 15-20 minutes as it is prepared on demand)'
      }
    ]
  },
  {
    type: 'dessert',
    foods: [
      {
        imageElem: menuFour4,
      },
      {
        foodDescription: "Sugar Free, Gluten Free Brownie"
      },
      {
        foodName: 'With seasonal fruits, sugar-free gluten-free chocolate sauce'
      }
    ]
  }
];

const menuFour = [
  {
    type: 'salad',
    foods: [
      {
        imageElem: menuFour1,
      },
      {
        foodDescription: 'Bahçe Salata'
      },
      {
        foodName: 'Akdeniz yeşillikleri, göbek marul, roka, dereotu, maydanoz, taze nane, domates, salatalık, pancar, zeytinyağı ve limon sos'
      }
    ]
  },
  {
    type: 'starter',
    foods: [
      {
        imageElem: menuFour2,
      },
      {
        foodDescription: 'Mantar Dolgulu Falafel'
      },
      {
        foodName: 'Mantar dolgulu falafel, yeşillik, balzamik domates ve tahin sos ile'
      }
    ]
  },
  {
    type: 'main',
    foods: [
      {
        imageElem: menuFour3,
      },
      {
        foodDescription: 'Glutensiz Makarna'
      },
      {
        foodName: 'Taze domates sos ile (Kişiye özel hazırlandığı için 15-20 dakikalık pişirme süresi vardır.)'
      }
    ]
  },
  {
    type: 'dessert',
    foods: [
      {
        imageElem: menuFour4,
      },
      {
        foodDescription: 'Şekersiz, Glutensiz Brownie'
      },
      {
        foodName: 'Mevsim meyveleri,şekersiz glutensiz çikolata sos ile'
      }
    ]
  }
];



const MENU = [
  {
    meal: 'lunch',
    key: "menuOne",
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    // image: LunchImg,
    image: LunchAsset,
    menu: menuOne
  },
  {
    meal: 'dinner',
    key: "menuTwo",
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    // image: DinnerImg,
    image: DinnerAsset,
    menu: menuTwo
  },
  {
    meal: 'cocktail',
    key: 'menuThree',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    // image: CocktailImg,
    image: CocktailAsset,
    menu: menuThree
  },
  {
    meal: 'cocktail',
    key: 'menuFour',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    // image: CocktailImg,
    image: CocktailAsset,
    menu: menuFour
  }
]

const MENU_EN = [
  {
    meal: 'lunch',
    key: "menuOne",
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    // image: LunchImg,
    image: LunchAsset,
    menu: menuOneEN
  },
  {
    meal: 'dinner',
    key: "menuTwo",
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    // image: DinnerImg,
    image: DinnerAsset,
    menu: menuTwoEN
  },
  {
    meal: 'cocktail',
    key: 'menuThree',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    // image: CocktailImg,
    image: CocktailAsset,
    menu: menuThreeEN
  },
  {
    meal: 'cocktail',
    key: 'menuFour',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    // image: CocktailImg,
    image: CocktailAsset,
    menu: menuFourEN
  }
]

const Menu = ({
  sectionId,
  t
}) => {
  const { language } = React.useContext(LanguageContext);
  const [selectedMeal, setSelectedMeal] = React.useState(null);
  const menuModalRef = React.useRef(null);

  const openMenuDetail = (meal) => {
    setSelectedMeal(meal);
    menuModalRef.current.open();
  };

  function replacePlaceholder(text, placeholder, replacement) {
    return text.replace(new RegExp(placeholder, 'g'), replacement);
  };

  const renderMenuTitle = (k) => {
    //menu_section
    const translated = t(`pages.home.menu_section_secondary.${k}`);
    let result = replacePlaceholder(translated, '<0>', '<span class="menu-meal-title">');
    result = replacePlaceholder(result, '</0>', '</span>');
    return result;
  };

  const menuWithLang = React.useMemo(() => {
    if (language.code === 'tr') {
      return MENU;
    } else {
      return MENU_EN;
    }
  }, [language.code]);

  return (
    <Container id={sectionId}>
      <TopSection>
        <Content>
          <BackTitle>{t('common.project_name')}</BackTitle>
          <Title>{t('pages.home.menu_section.menu')}</Title>
          <SubText>{t('pages.home.menu_section.title')}</SubText>
          <BriefText>{t('pages.home.menu_section.sub_title')}</BriefText>
          
          <MenuList>
          {menuWithLang.map((item, idx) => (
            <li key={item.key}
            onClick={() => openMenuDetail(item)}>
              <MenuItemName dangerouslySetInnerHTML={{ __html: renderMenuTitle(item.key) }}></MenuItemName>
            </li>
               ))}
          </MenuList>
       
        </Content>
        <Image src={MenuImage} alt={'Section'} />
      </TopSection>

      <Modal
        ref={menuModalRef}
        size={'lg'}
      >
        <MenuModal
          data={selectedMeal}
          t={t}
        />
      </Modal>

      {/* <MenuContainer>
        {menuWithLang.map((item, idx) => (
          <MenuCard
            key={item.key}
            $color={idx === 1 && '#139CD712'}
            onClick={() => openMenuDetail(item)}
          > */}
            {/* <MenuMeal>{t(`pages.home.menu_section.${item.key}`)}</MenuMeal> açma*/}
             {/* <MenuMeal
              dangerouslySetInnerHTML={{ __html: renderMenuTitle(item.key) }}
            />
            <MenuContent>
              <MenuBrief>{t(`pages.booking.checkout_section.${item.meal}_description`)}</MenuBrief>
              <ImageContainer>
                <MenuTypeImage src={item.image} alt={item.meal} />
              </ImageContainer>
            </MenuContent>
          </MenuCard>
        ))}
        <CloudImage src={CloudImg} alt={'Menu Cloud'} />
      </MenuContainer> */}
    </Container>
  )
};

export default Menu;
