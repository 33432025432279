import styled, { css } from 'styled-components';
import CorporateBack from 'assets/img/image/corporate-back.png';

export const Section = styled.section`
  /* padding: 0 52px; */
  padding-top: 76px;
  padding-bottom: 76px;
  width: 96%;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 100%;
    padding: 0 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
    padding: 0 16px;
  }
`;

export const CorporateContainer = styled.div`
  width: 100%;
  min-height: 475px;
  background-image: url(${CorporateBack});
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;  

  padding-top: 60px;
  display: flex;
  align-items: flex-start;
  /* justify-content: space-around; */

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    flex-direction: column;
    padding-bottom: 60px;
  }
`;

export const CorporateImage = styled.img`
  width: 40%;
  min-width: 400px;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  /* padding-left: 68px; */
  padding-left: 30px;
  padding-bottom: 68px;
  position: relative;
  top: -60px;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    padding-left: 0;
    min-width: 320px;
    margin: 0 auto;
    padding-bottom: 0;
  }
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    min-width: 520px;
    padding-bottom: 0;
  }
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    min-width: 220px;
    width: 100%;
  }
`;

export const CorporateContact = styled.div`
  padding-left: 23px;
  padding-right: 140px;
  /* padding-bottom: 65px; */
  
  padding-bottom: 30px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){ 
    /* padding-right: 0; */
    padding: 0 25px;
    margin: 0 auto;
  }
`;

export const Title = styled.p`
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 56px;
  text-transform: capitalize;
  color: #139CD7;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 32px;
    line-height: 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 24px;
    line-height: 24px;
  }
`;

export const SubTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 80px;
  line-height: 94px;
  letter-spacing: -1.4px;
  color: #002424;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 50px;
    line-height: 66px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.582741px;
  }
`;

export const Description = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: -1.4px;
  color: #002424;
  padding: 24px 0;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 20px;
    line-height: 22px;
    padding: 14px 0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.582741px;
    padding: 10px 0;
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Form = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;

  .consent-btn {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Row = styled.div`
  display: flex;

  ${({ $responsive }) => $responsive && css`
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
      flex-direction: column;
    }
  `}
`;

export const SendButton = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 863px){
    & > :not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;
