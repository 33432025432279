import React from 'react';
import { LanguageContext } from 'contexts/Languages';
import moment from "moment";
// import BalloonImg from "assets/img/image/booking-balloon.png";
import BalloonImg from "assets/img/image/booking-balloon-2.png";
import Cloud1Img from "assets/img/image/clouds/cloud1.png";
import Cloud2Img from "assets/img/image/clouds/cloud2.png";
import Cloud3Img from "assets/img/image/clouds/cloud3.png";
import Modal from 'components/Modal';
import { setLocalStorage, getLocalStorage } from 'helpers';
import TimesUp from './components/TimesUp';
import { PATHS } from 'routes';
import { BookingContext } from 'contexts/Booking';
import ReturnFirst from './components/ReturnFirst';
import Loader from 'components/Loader';
import {
  Stepper,
  DateSection,
  SeatSection,
  // SummarySection,
  CheckoutSection,
} from './components';
import {
  Container,
  Header,
  Content,
  BackTitle,
  Title,
  ImageArea,
  BalloonImage,
  CloudImage
} from './style';

const STEPS = [
  { label: 'date', canGoBack: true },
  { label: 'seats', canGoBack: true },
  // { label: 'summary', canGoBack: true },
  { label: 'checkout', canGoBack: true },
];

const ORDER = {
  card: null,
  date: null,
  session: null,
  guests: 1,
  guestsInfo: [],
  specialRequest: '',
  specialOccasion: null,
  billingInfo: {
    fullName: { value: '', error: '' },
    surname: { value: '', error: '' },
    email: { value: '', error: '' },
    phone: { value: '', error: '' },
    country: { value: '', error: '' },
    state: { value: '', error: '' },
    city: { value: '', error: '' },
    postalCode: { value: '', error: '' },
    fullAddress: { value: '', error: '' },
    currency: { value: 'EUR', error: '' },
    dialCode: { value: '', error: '' },
    // terms: {}
  },
};

let intervalId = 0;
const C_LIMIT = 15 * 60 * 1000;
// const C_LIMIT = 1;
const Booking = () => {
  const { t, _t, language } = React.useContext(LanguageContext);
  const { getCard, getCurrencyList, deleteCard, getSingleBooking } = React.useContext(BookingContext);
  const [loading, setLoading] = React.useState(true);
  const [activeStep, setActiveStep] = React.useState(1);
  const [order, setOrder] = React.useState(ORDER);
  const [counter, setCounter] = React.useState(C_LIMIT);
  const progressRef = React.createRef();
  const modalRef = React.createRef();
  const firstStepWarningRef = React.createRef();

  React.useEffect(() => {
    getCurrencyList();
    return () => {
      stopCounter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (async () => {
      if (order?.card?.booking?.id) {
        const data = await getSingleBooking(order.card.booking?.id);
        console.log('changed language', data);
        setOrder((prev) => ({
          ...prev,
          card: { ...prev.card, booking: data },
          session: { ...prev.session, ...data },
        }));
      }
    })();
  }, [language]);

  const getLocalCard = async (cardId) => {
    try {
      const data = await getCard(cardId);
      handleChangeOrder('card', data);
      return data;
    } catch (error) {
      console.log('error', error);
      deleteCardData();
      throw error;
    }
  };

  const initOrder = async () => {
    setLoading(true);
    const localData = getLocalStorage('order');
    if (!localData || !localData?.card?.id) {
      setLoading(false);
      return;
    }
    // TODO(3) :: get api data to check card status session
    // localData.

    setOrder(localData || ORDER);
    try {
      await getLocalCard(localData?.card?.id);
      checkTimerStatus();
      if (localData?.step) {
        setActiveStep(localData.step);
      } else {
        setActiveStep(2);
      }
    } catch (error) {
      setActiveStep(1);
      deleteCardData();
    }
    setLoading(false);
  }

  React.useEffect(() => {
    initOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLocalData = (step) => {
    const _order = JSON.parse(JSON.stringify(order));
    // _order.step = activeStep + 1;
    _order.step = step;
    console.log('_order.step', _order.step);
    setLocalStorage('order', _order);
  };

  React.useEffect(() => {
    const _order = JSON.parse(JSON.stringify(order));
    _order.card = order.card;
    setLocalStorage('order', _order);
    // setLocalStorage('order', order);
    // return;
    // console.log('order set');
    // setLocalStorage('order', order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.card])
  // }, [order])

  React.useEffect(() => {
    if (counter <= 0) {
      modalRef.current.open();
      setTimeout(() => {
        document.querySelector("button[role='close-modal']")?.addEventListener('click', (e) => {
          window.location.href = PATHS.BOOKING;
        });
      }, 300);
    }
  }, [counter, modalRef]);

  const checkTimerStatus = () => {
    if (order.card) {
      // TODO :: Card end date control
      const endTime = moment.utc(order.card.end_date).format('YYYY-MM-DD HH:mm:ss');
      const now = moment().format('YYYY-MM-DD HH:mm:ss');
      if (moment(now).isAfter(endTime)) {
        modalRef.current.open();
        setTimeout(() => {
          document.querySelector("button[role='close-modal']")?.addEventListener('click', (_) => {
            window.location.href = PATHS.BOOKING;
          });
        }, 300);
        stopCounter();
      } else {
        console.count('started counter');
        // const diffSecs = moment(endTime).diff(moment(now), 'seconds');
        // setCounter(diffSecs * 1000);
        // const diffSecs = moment(endTime).diff(moment(now), 'milliseconds');
        const diffSecs = moment(endTime).diff(moment(now), 'seconds');
        setCounter(diffSecs);
        startCounter();
      }
    } else if (activeStep > 1) {
      modalRef.current.open();
      setTimeout(() => {
        document.querySelector("button[role='close-modal']")?.addEventListener('click', (_) => {
          window.location.href = PATHS.BOOKING;
        });
      }, 300);
    }
  };

  React.useEffect(() => {
    checkTimerStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.card])

  const nextSection = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    progressRef.current?.next();
    console.log('activeStep + 1', activeStep + 1);
    setLocalData(activeStep + 1);
  };

  const prevSection = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    progressRef.current?.prev();
    setLocalData(activeStep - 1);
  };

  const handleChangeOrder = (key, value) => {
    setOrder(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeBillingInfo = (key, value) => {
    setOrder(prev => ({
      ...prev,
      billingInfo: {
        ...prev.billingInfo,
        [key]: { value: value, error: '' }
      },
    }));
  };

  const startCounter = () => {
    console.log('intervalId', intervalId);
    if (!!intervalId) {
      return;
    }
    intervalId = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);
  };

  const stopCounter = () => {
    clearInterval(intervalId);
    intervalId = 0;
  };

  const formatTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const remainingSeconds = secs % 60;
    return `${minutes < 10 ? '0' + minutes : minutes}:${remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds}`;
  };

  const counterMemo = React.useMemo(() => {
    return formatTime(counter);
  }, [counter]);

  const stepperSetStep = (s) => {
    setActiveStep(s);
    setLocalData(s);
  };

  const deleteCardData = () => {
    window.localStorage.removeItem('order');
    setOrder(ORDER);
    setActiveStep(1);
    deleteCard(order.card.id);
  };

  const returnFirstStep = () => {
    firstStepWarningRef.current?.close?.();
    // refresh
    window.location.reload();
    // deleteCard(order.card.id);
    deleteCardData();
    progressRef.current?.overrideStep(1);
  };

  const renderSteps = () => {
    switch (STEPS[activeStep - 1].label) {
      case STEPS[0].label:
        return (
          <DateSection
            nextSection={nextSection}
            order={order}
            handleChangeOrder={handleChangeOrder}
            t={t}
          />
        );
      case STEPS[1].label:
        return <SeatSection
          nextSection={nextSection}
          prevSection={prevSection}
          order={order}
          handleChangeOrder={handleChangeOrder}
          t={t}
          counter={counterMemo}
        />;
      // case STEPS[2].label:
      //   return <SummarySection
      //     nextSection={nextSection}
      //     prevSection={prevSection}
      //     order={order}
      //     handleChangeOrder={handleChangeOrder}
      //     counter={counterMemo}
      //     t={t}
      //   />;
      case STEPS[2].label:
        return <CheckoutSection
          nextSection={nextSection}
          prevSection={prevSection}
          order={order}
          handleChangeBillingInfo={handleChangeBillingInfo}
          handleChangeOrder={handleChangeOrder}
          counter={counterMemo}
          stopCounter={stopCounter}
          startCounter={startCounter}
          t={t}
          _t={_t}
        />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <CloudImage
        src={Cloud2Img}
        alt={'cloud1'}
        $sx={{
          top: '10%',
          right: 0
        }}
        $responsive={{
          lg: {
            width: '70%',
            top: '5%'
          }
        }}
      />
      <CloudImage
        src={Cloud1Img}
        alt={'cloud2'}
        $sx={{
          top: '30%',
          left: 0
        }}
        $responsive={{
          lg: {
            width: '70%',
          }
        }}
      />
      <CloudImage
        src={Cloud3Img}
        alt={'cloud3'}
        $sx={{
          width: '40%',
          top: '70%',
          right: 0
        }}
        $responsive={{
          lg: {
            width: '60%',
            top: '90%'
          }
        }}
      />

      <Modal
        ref={firstStepWarningRef}
        size={'sm'}
      >
        <ReturnFirst
          close={() => firstStepWarningRef.current.close()}
          returnFirst={returnFirstStep}
        />
      </Modal>

      <Modal
        ref={modalRef}
        size={'sm'}
        isCloseOutsideClick={false}
        onClose={deleteCardData}
      >
        <TimesUp deleteCardData={deleteCardData} />
      </Modal>

      <Container>
        <ImageArea>
          <BalloonImage
            src={BalloonImg}
            alt={'booking-bg'}
          />
        </ImageArea>

        <Content>
          {/* <Header> */}
          <Header>
            <BackTitle>{t('common.project_name')}</BackTitle>
            <Title>{t('common.unique_experience')}</Title>
          </Header>
          {/* </Header> */}

          <Stepper
            ref={progressRef}
            // setActiveStep={setActiveStep}
            setActiveStep={stepperSetStep}
            activeStep={activeStep}
            steps={STEPS}
            containerStyle={{ maxWidth: '950px', paddingBottom: '50px' }}
            firstStepWarn={() => firstStepWarningRef.current.open()}
          />
          {!loading ? renderSteps() : <Loader />}
        </Content>
      </Container>
    </React.Fragment>
  )
};

export default Booking;
