import React from 'react';
import AboutHistoryImage from 'assets/img/image/detail-img.png';
import DitsLogo from 'assets/img/logo/dits-logo.png';
import {
  Container,
  TopSection,
  Content,
  Image,
  BackTitle,
  Title,
  SubText,
  ImageArea,
  TextContainer,
  TextsArea,
  Text,
  DitsBanner,
  DistImage
} from './style';

const MoreAboutHistory = ({
  t
}) => {

  const formatText = (text) => {
    text = text.replace(/<link>/g, '<a href="https://www.dinnerinthesky.com/" style="color: #139cd7" target="_blank">');
    text = text.replace(/<\/link>/g, '</a>');
    text = text.replace(/<color>/g, '<span style="color: #139cd7">');
    text = text.replace(/<\/color>/g, '</span>');
    text = text.replace(/<strong>/g, '<span style="font-weight: 500;">');
    text = text.replace(/<\/strong>/g, '</span>');
    return text;
  };

  const renderHistory = React.useMemo(() => {
    const res = t('pages.home.more_about_history_section.history');
    return Array.isArray(res) ? res : [];
  }, [t]);

  return (
    <Container>


      <TopSection>
        <Content>
          <BackTitle>{t('common.project_name')}</BackTitle>
          <Title>{t('pages.home.more_about_history_section.title')}</Title>
          <SubText>{t('pages.home.more_about_history_section.sub_title')}</SubText>
        </Content>
        <Image src={AboutHistoryImage} alt={'Know more about our history'} />
      </TopSection>





      <TextContainer>
        <TextsArea>
          {renderHistory.map((text, index) => Math.round(renderHistory.length / 2) - 1 >= index && (
            <Text key={index} dangerouslySetInnerHTML={{ __html: formatText(text) }} />
          ))}
        </TextsArea>
        <TextsArea>
          {renderHistory.map((text, index) => Math.round(renderHistory.length / 2) - 1 < index && (
            <Text key={index} dangerouslySetInnerHTML={{ __html: formatText(text) }} />
          ))}
        </TextsArea>
        <DitsBanner>
          <DistImage src={DitsLogo} alt={'Dinner in the Sky logo'} />
        </DitsBanner>
      </TextContainer>
    </Container>
  )
};

export default MoreAboutHistory;
