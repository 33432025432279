import React from 'react';
import { SECTION_IDX } from 'constants/sections';
import { LanguageContext } from 'contexts/Languages';
import {
  BannerSection,
  AboutSection,
  ExperienceSection,
  MenuSection,
  CorporateSection,
  GallerySection,
  MoreAboutHistory,
  ContactSection
} from './components';
import {
  Wrapper,
  // Sections
} from './style';
import SidePanel from 'components/SidePanel';

const Home = () => {
  const { t } = React.useContext(LanguageContext);

  return (
    <Wrapper>
      <SidePanel />
      <BannerSection sectionId={SECTION_IDX.banner} t={t} />
      {/* <Sections> */}
      <AboutSection sectionId={SECTION_IDX.dits2} t={t} />
      <ExperienceSection sectionId={SECTION_IDX.experience} t={t} />
      <MenuSection sectionId={SECTION_IDX.menu} t={t} />
      <CorporateSection sectionId={SECTION_IDX.corporate_booking} t={t} />
      <GallerySection sectionId={SECTION_IDX.gallery} t={t} />
      <MoreAboutHistory t={t} />
      <ContactSection sectionId={SECTION_IDX.contact} t={t} />
      {/* </Sections> */}
    </Wrapper>
  )
};

export default Home;
