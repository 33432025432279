import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
// import MasterImg from 'assets/img/image/hero.png';
import MasterImg from 'assets/img/image/hero-2.png';
import { TextButton } from 'components/Button';
import {
  Container,
  HeroImage,
  OnBoardingContainer,
  SubTitle,
  Title
} from './style';
import Modal from 'components/Modal';

const Banner = ({
  sectionId,
  t
}) => {
  const nav = useNavigate();
  const bookingModalRef = React.useRef();

  const handleBookModal = () => {
    bookingModalRef.current.open();
  }

  return (
    <Container id={sectionId}>
      <Modal
        ref={bookingModalRef}
        size={'md'}
        modalType={'booking'}
      />
      <HeroImage src={MasterImg} alt='Master Image' />
      <OnBoardingContainer>
        <SubTitle>{t('pages.home.banner_section.title')}</SubTitle>
        <Title>{t('pages.home.banner_section.sub_title')}</Title>
        <TextButton
          color={'#FFFFFF'}
          hasArrow
          title={t('common.book_now')}
          onClick={() => nav(PATHS.BOOKING)}
          // onClick={handleBookModal}
          containerStyle={{ marginTop: '36px' }}
          responsive
        />
      </OnBoardingContainer>
    </Container>
  )
};

export default Banner;
