import styled from 'styled-components';

const activeColor = '#000';
const passiveColor = '#C8C8C8';
const swHeight = 32;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;
  /* background-color: ${({ $checked }) => $checked ? 'red' : 'white'}; */
  background-color: #fff;
  border-radius: 15px;
  transition: 0.4s;
  border: 1px solid ${({ $checked }) => $checked ? activeColor : passiveColor};
  
  &:before {
    content: '';
    position: absolute;
    left: 2px;
    /* bottom: 2px; */
    bottom: 1px;
    /* width: 20px; */
    /* height: 20px; */
    width: ${swHeight - 4}px;
    height: ${swHeight - 4}px;
    border-radius: 100%;
    background-color: ${({ $checked }) => $checked ? activeColor : passiveColor};
    transition: 0.4s;
  }
`;

export const Input = styled.input`
  display: none;
  &:checked + ${Slider} {
    background-color: #fff;
  }
  &:checked + ${Slider}:before {
    transform: translateX(110%);
  } 
`;

export const SwitchBtn = styled.label`
  position: relative;
  display: inline-block;
  /* width: 48px; */
  /* height: 24px; */
  width: ${swHeight * 2}px;
  height: ${swHeight}px;
  min-width: ${swHeight * 2}px;
  min-height: ${swHeight}px;
  & ${Input} {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;