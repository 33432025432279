import React from 'react';
import { LanguageContext } from 'contexts/Languages';
import {
  MainContainer,
  StepContainer,
  Step,
  StepBox,
  StepTitle,
  StepLine
} from './style';

const Stepper = React.forwardRef(({
  steps,
  containerStyle,
  setActiveStep,
  activeStep,
  firstStepWarn
}, ref) => {
  const { t } = React.useContext(LanguageContext);

  const stepData = React.useMemo(() => {
    return steps.map((steps, idx) => ({
      ...steps,
      step: idx + 1
    }))
  }, [steps]);

  const setStep = (s) => {
    if (s === 1) {
      return firstStepWarn?.();
    }
    if (s > 0 && s <= steps.length) setActiveStep(s)
  };

  const nextStep = () => {
    if (activeStep < steps.length) {
      setActiveStep(activeStep + 1)
    }
  };

  const prevStep = () => {
    if (activeStep - 1 === 1) {
      return firstStepWarn?.();
    }
    if (activeStep > 1) {
      setActiveStep(activeStep - 1)
    }
  };

  React.useImperativeHandle(ref, () => ({
    next: nextStep,
    prev: prevStep,
    getCurrentStep: () => activeStep,
    setStep: (idx) => setStep(idx),
    overrideStep: (idx) => setActiveStep(idx),
    step: activeStep
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [activeStep]);

  const stepCondition = React.useCallback((step, canGoBack) => activeStep > step && canGoBack, [activeStep])

  if (steps.length === 0) {
    return null;
  }

  return (
    <MainContainer ref={ref} $containerStyle={containerStyle}>
      <StepContainer>
        {stepData.map(({ label, step, canGoBack }) => (
          <React.Fragment key={`${label}_${step}`}>
            <StepBox
              $step={activeStep >= step ? 'completed' : 'incomplete'}
            >
              <Step
                $step={activeStep >= step ? 'completed' : 'incomplete'}
                onClick={() => {
                  setStep(step)
                }}
                disabled={!stepCondition(step, canGoBack)}
              >
                <span>{step}</span>
              </Step>
              <StepTitle>{t(`pages.booking.steps.${label}`)}</StepTitle>
            </StepBox>
            {stepData.length !== step &&
              <StepLine $step={activeStep >= (step + 1) ? 'completed' : 'incomplete'} />
            }
          </React.Fragment>
        ))}
      </StepContainer>
    </MainContainer>
  )
});

Stepper.defaultProps = {
  steps: []
};

export default React.memo(Stepper, (prevProps, nextProps) => {
  return prevProps.steps.length === nextProps.steps.length;
});
