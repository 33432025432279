import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    &>div:last-child {
      margin-right: 0;
      margin: auto;
      width: 50%;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const CardArea = styled.div`
  width: 100%;
  gap: 30px;
`;

export const ErrorText = styled.div`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: .5px;
  color: ${({ theme }) => theme.colors.error};
`;