
import { createRef } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  InputContainer,
  Input,
  ErrorContainer,
  ErrorText,
} from './style';

const TextInput = ({
  type,
  label,
  placeholder,
  value,
  onChange,
  error,
  required,
  disabled,
  fullWidth,
  containerStyle,
  inputStyle,
  ...props
}) => {
  const inputRef = createRef();

  return (
    <Wrapper $containerStyle={containerStyle} $fullWidth={fullWidth}>
      <InputContainer $inputStyle={inputStyle}>
        <Input
          ref={inputRef}
          type={type}
          label={label}
          placeholder={placeholder}
          variant={"standard"}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          error={!!error}
          fullWidth={fullWidth}
          {...props}
        />
        {!!error &&
          <ErrorContainer>
            <ErrorText>{error}</ErrorText>
          </ErrorContainer>
        }
      </InputContainer>

    </Wrapper>
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  containerStyle: PropTypes.object,
  fullWidth: PropTypes.bool,
  inputStyle: PropTypes.object,
};

TextInput.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  onChange: () => { },
  required: false,
  disabled: false,
  containerStyle: {},
  fullWidth: false,
  inputStyle: {},
};

export default TextInput;