import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    text-decoration: none;
    ::selection {
      background-color: #48CAE4;
      color: #fff;
    }
  }
  html {
    overflow-x: hidden;
  }
  body {
    overflow-x: hidden;
  }
  textarea {
    :focus {
      outline: none;
    }
  }
  p,h1,h2,h3,h4,a {
    font-family: 'Roboto Condensed';
    color: ${theme.colors.text};
  }
  button {
    cursor: pointer;
  }
  img {
    border-style: none;
  }

  .section-w {
    width: 85%;
    margin: 0 auto;
  }
`;

export default GlobalStyle;
