import styled, { css } from 'styled-components';

const stepPassive = '#898C8D';

export const MainContainer = styled.div`
  width: 100%;
  ${({ $containerStyle }) => $containerStyle};
  /* min-width: 400px; */
`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2px;
  border-radius: 12px;
`;

export const Step = styled.button`
  outline: none;
  width: 32px;
  height: 32px;
  min-width: 32px;
  border: 1px solid ${({ theme, $step }) => $step === 'completed' ? theme.colors.brand : stepPassive};
  background-color: ${({ theme, $step }) => $step === 'completed' ? theme.colors.brand : stepPassive};
  border-radius: 100%;
  transition-property: color,opacity,background-color,height,border;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.4,0,0.2,1);
  transition-delay: 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:disabled {
    cursor: not-allowed;
  }

  span {
    transition: color 1s cubic-bezier(0.4,0,0.2,1) 500ms;
    color: #fff;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    user-select: none;
  }
`;

export const StepBox = styled.div`
  display: grid;
  place-items: center;
  color: ${({ theme, $step }) => $step === 'completed' ? theme.colors.brand : stepPassive};
  position: relative;
`;

export const StepTitle = styled.span`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 14.8197px;
  line-height: 17px;
  text-align: center;
  color: inherit;
  margin-top: 6px;
  position: absolute;
  top: 100%;
`;

export const StepLine = styled.div`
  width: 100%;
  height: 3px;
  margin: 0 8px;
  background: ${stepPassive};
  border-radius: 12px;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    border-radius: 12px;
    transition: all 1s cubic-bezier(0.4,0,0.2,1);
    background: ${({ theme }) => stepPassive};
  }

  ${({ theme, $step }) => $step === 'completed' && css`
    &::after {
      transition: all 1s cubic-bezier(0.4,0,0.2,1);
      background: ${({ theme }) => theme.colors.brand};
      width: 100%;
      z-index: 1;
    }
  `};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    margin: 0 4px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    margin: 0 0px;
  }

`;
