import styled from 'styled-components';

const sizeGenenarate = (size, type, br) => {
  const modalSizes = {
    lg: { width: '1448px', height: '596px' },
    md: { width: '448px', height: '353px' },
    sm: { width: '463px', height: '406px' },
    default: { width: 'auto', height: 'auto' },
  }
  switch (size) {
    case 'lg':
      return modalSizes.lg;
    case 'md':
      return modalSizes.md;
    case 'sm':
      return modalSizes.sm;
    default:
      return modalSizes.default;
  };
};

export const ModalContainer = styled.div`
  position: absolute;
  border: none;
  outline: none;
  display: -webkit-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: #fff;
  box-shadow: 0px 12px 52px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  padding: 30px;
  box-sizing: border-box;

  width: ${({ $size, theme }) => sizeGenenarate($size, theme.breakpoints).width};

  min-height: ${({ $size, theme }) => sizeGenenarate($size, theme.breakpoints).height};
  /* height: ${({ $size, theme }) => sizeGenenarate($size, theme.breakpoints).height}; */

  max-height: 75vh;
  max-width: 90vw;
  min-width: 300px;
  min-height: 300px;
  ${({ $sx }) => $sx};
`;

export const ModalContent = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  
  ${({ $customContentStyle }) => $customContentStyle}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 99;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  svg {
    width: 45px;
    height: 45px;
  }
`;
