import React from 'react';
import { ReactComponent as WarningIcon } from 'assets/img/icons/warning.svg';
import SeatsDitsSrc from 'assets/img/image/seats-dits.png';
import GuestInfo from './components/GuestInfo';
import TextInput from 'components/TextInput';
import { TextButton } from 'components/Button';
import {
  Container,
  Card,
  CardContent,
  WarningArea,
  TopWarningArea,
  Line,
  GuestInfoContainer,
  ButtonContainer,
  SeatImage,
} from './style';


const GUEST_SCHEMA = {
  name: '',
  birthday: '',
  salad: { value: '', label: '' },
  main: { value: '', label: '' },
  starter: { value: '', label: '' },
  dessert: { value: '', label: '' },
  cocktail: { value: '', label: '' },
};

const SeatSection = ({
  nextSection,
  prevSection,
  order,
  handleChangeOrder,
  counter,
  t
}) => {

  React.useEffect(() => {
    // const fillGuestsInfo = Array.from({ length: order.guests || 2 }, () => (
    //   JSON.parse(JSON.stringify(GUEST_SCHEMA))
    // ));

    let filledGuestsInfo = [];
    if (order.guestsInfo.length > 0 /* && order.guestsInfo.length === order.guests */) {
      const diff = order.guestsInfo.length - order.guests;
      let newGuestsInfo = [];
      if (diff >= 0) {
        newGuestsInfo = order.guestsInfo.slice(0, Math.abs(order.guests));
      } else {
        newGuestsInfo = [...order.guestsInfo];
        for (let i = 0; i < Math.abs(diff); i++) {
          newGuestsInfo.push(JSON.parse(JSON.stringify(GUEST_SCHEMA)));
        }
      }
      filledGuestsInfo = newGuestsInfo;
    } else {
      const fillGuestsInfo = Array.from({ length: order.guests }, () => {
        return JSON.parse(JSON.stringify(GUEST_SCHEMA));
      });
      filledGuestsInfo = fillGuestsInfo;
    }
    handleChangeOrder('guestsInfo', JSON.parse(JSON.stringify(filledGuestsInfo)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeGuest = (index, key, value) => {
    const newGuestsInfo = [...order.guestsInfo];
    newGuestsInfo[index][key] = value;
    handleChangeOrder('guestsInfo', newGuestsInfo);
  };

  const handleContinue = () => {
    nextSection();
  };

  const handleBack = () => {
    prevSection();
  };

  const checkGuestsInfo = React.useCallback(() => {
    let hasError = false;
    const orderType = order?.card?.booking?.booking_type;

    order.guestsInfo.forEach((guest) => {
      if (!guest.name || !guest.birthday) {
        hasError = true;
      }
      if (orderType === 'cocktail') {
        if (!guest.cocktail.value) {
          hasError = true;
        }
      } else {
        if (!guest.salad.value || !guest.main.value || !guest.starter.value || !guest.dessert.value) {
          hasError = true;
        }
      }
    });
    return hasError;

  }, [order?.card?.booking?.booking_type, order.guestsInfo]);

  return (
    <Container>
      <Card>
        <TopWarningArea>
          <WarningIcon />
          <p>{t('common.remaining_time')} <strong>{counter}</strong></p>
        </TopWarningArea>

        {/* <CardHeader>
          <CardTitle>Your {order.guests || 1} seats</CardTitle>
        </CardHeader> */}

        <CardContent>
          <SeatImage src={SeatsDitsSrc} alt={'Dits seats'} />
          <WarningArea>
            <WarningIcon />
            {/* <p>Please note that your guests will be seated across multiple tables.</p> */}
            <p>{t('pages.booking.seats_step.warning')}</p>
          </WarningArea>

          {/* <Desription>{DESCRIPTION}</Desription> */}

          <Line />

          <GuestInfoContainer>
            {order.guestsInfo.map((guest, idx) => (
              <GuestInfo
                key={idx}
                idx={idx}
                guest={guest}
                t={t}
                handleChange={({ key, value }) => handleChangeGuest(idx, key, value)}
                orderType={order?.card?.booking?.booking_type}
                foodOptions={{
                  salad: order.session?.salad_food?.map?.((item) => {
                    return { value: item.id, label: item.name }
                  }),
                  main: order.session?.main_food?.map?.((item) => {
                    return { value: item.id, label: item.name }
                  }),
                  starter: order.session?.starter_food?.map?.((item) => {
                    return { value: item.id, label: item.name }
                  }),
                  dessert: order.session?.dessert_food?.map?.((item) => {
                    return { value: item.id, label: item.name }
                  }),
                  cocktail: order.session?.cocktail_food?.map?.((item) => {
                    return { value: item.id, label: item.name }
                  }),
                }}
              />
            ))}
          </GuestInfoContainer>

          <TextInput
            multiline
            maxRows={3}
            // label={'Meal Related Special Requests (Optional)*'}
            label={t('textboxes.meal_request_title')}
            placeholder={t('textboxes.meal_request_placeholder')}
            value={order.fillGuestsInfo}
            onChange={(e) => handleChangeOrder('fillGuestsInfo', e.target.value)}
            fullWidth
            containerStyle={{ marginTop: '10px' }}
          />

        </CardContent>
      </Card>
      <ButtonContainer>
        <TextButton
          title={t('buttons.back')}
          color={'#002424'}
          onClick={handleBack}
        />
        <TextButton
          title={t('buttons.continue')}
          hasArrow
          color={'#139CD7'}
          onClick={handleContinue}
          disabled={checkGuestsInfo()}
        />
      </ButtonContainer>

    </Container>
  )
};

export default SeatSection;
