import { ReactComponent as Facebook } from 'assets/img/icons/social-media/FacebookLogo.svg';
// import { ReactComponent as Twitter } from 'assets/img/icons/social-media/TwitterLogo.svg';
import { ReactComponent as Twitter } from 'assets/img/icons/social-media/XLogo.svg';
import { ReactComponent as Instagram } from 'assets/img/icons/social-media/InstagramLogo.svg';

export const SECTION_IDX = Object.freeze({
  // home: 'dits2_0',
  banner: 'banner',
  dits2: 'dits2_0',
  experience: 'experience',
  menu: 'menu',
  corporate_booking: 'corporate_booking',
  gallery: 'gallery',
  contact: 'contact',
});

export const SECTIONS = Object.freeze([
  // {
  //   key: 'home',
  //   title: 'DITS 2.0',
  //   path: SECTION_IDX.home,
  // },
  {
    key: 'dits2',
    title: 'dits2',
    path: SECTION_IDX.dits2,
  },
  {
    key: 'experience',
    title: 'experience',
    path: SECTION_IDX.experience,
  },
  {
    key: 'menu',
    title: 'menu',
    path: SECTION_IDX.menu,
  },
  {
    key: 'corporate_booking',
    title: 'corporate_booking',
    path: SECTION_IDX.corporate_booking,
  },
  {
    key: 'gallery',
    title: 'gallery',
    path: SECTION_IDX.gallery,
  },
  {
    key: 'contact',
    title: 'contact',
    path: SECTION_IDX.contact,
  },
]);

export const FOOTER_LINKS = Object.freeze([
  {
    key: 'faq',
    path: '/faq',
  },
  {
    key: 'privacy_policy',
    path: '/privacy-policy',
  },
  {
    key: 'cookie_management',
    path: '/cookie-management',
  },
  {
    key: 'terms',
    path: '/terms-conditions',
  },
]);

export const SOCIAL_MEDIA = Object.freeze([
  {
    key: 'facebook',
    asset: Facebook,
    path: 'https://www.facebook.com/people/Dinner-in-the-Sky-Cappadocia/100091142280876/'
  },
  {
    key: 'twitter',
    asset: Twitter,
    path: 'https://x.com/skydinnerc15015'
  },
  {
    key: 'instagram',
    asset: Instagram,
    path: 'https://www.instagram.com/skydinnercappadocia/'
  }
]);
