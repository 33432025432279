import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
`;

export const Title = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: -1.4px;
  color: #002424;
  padding-bottom: 4px;
  padding-top: 21px;
`;

export const SubText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #002424;
  padding-bottom: 30px;
`;
