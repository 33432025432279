import React from 'react'
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTheme } from 'styled-components';
import TextInput from 'components/TextInput';
import Dropdown from 'components/Dropdown';
import {
  Container,
  Row,
  Col,
  Guest,
  Line
} from './style';

const GuestInfo = ({
  guest,
  handleChange,
  foodOptions,
  orderType,
  idx,
  t
}) => {
  const theme = useTheme();

  const changeGuest = (key, value) => {
    handleChange({ key, value });
  };

  if (orderType === 'cocktail') {
    return (
      <Container>
        <Guest>
          <Line />
          <span>{t('common.guest')} {idx + 1}</span>
          <Line />
        </Guest>
        <Col>
          <TextInput
            name={'fullName'}
            label={t('textboxes.guest_name')}
            inputProps={{ maxLength: 30 }}
            value={guest.name}
            onChange={(e) => changeGuest('name', e.target.value)}
            containerStyle={{
              marginRight: 20,
              minWidth: 155,
              width: 'calc(50% - 20px)'
            }}
            inputStyle={{
              marginBottom: 0
            }}
            fullWidth
            className={'guest-name'}
          // error={form.firstName.error}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={guest.birthday ? moment(guest.birthday) : null}
              // onChange={(newValue) => setValue(newValue)}
              onChange={(val) => changeGuest('birthday', val)}
              maxDate={moment()}
              // minDate={moment().date(1).month(0).year(1900)}
              minDate={moment().date(1).month(0).year(1900)}
              sx={{
                width: 'calc(50% - 20px)',
                justifyContent: 'center'
              }}
            />
          </LocalizationProvider>
        </Col>
        <Row>
          {foodOptions.cocktail?.length > 0 && (
            <Dropdown
              label={t('textboxes.cocktail')}
              containerStyle={{ width: '100%', marginRight: '20px', paddingTop: '16px' }}
              value={guest.cocktail}
              setValue={(value) => changeGuest('cocktail', value)}
              options={foodOptions.cocktail}
            />
          )}
        </Row>
      </Container>
    )
  }

  return (
    <Container>
      <Guest>
        <Line />
        <span>{t('common.guest')} {idx + 1}</span>
        <Line />
      </Guest>
      <Col>
        <TextInput
          name={'fullName'}
          label={t('textboxes.guest_name')}
          inputProps={{ maxLength: 30 }}
          value={guest.name}
          onChange={(e) => changeGuest('name', e.target.value)}
          containerStyle={{
            marginRight: 20,
            minWidth: 155,
            width: 'calc(50% - 20px)'
          }}
          inputStyle={{
            marginBottom: 0
          }}
          fullWidth
          className={'guest-name'}
        // error={form.firstName.error}
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={guest.birthday ? moment(guest.birthday) : null}
            // onChange={(newValue) => setValue(newValue)}
            onChange={(val) => changeGuest('birthday', val)}
            maxDate={moment()}
            minDate={moment().date(1).month(0).year(1900)}
            sx={{
              width: 'calc(50% - 20px)',
              justifyContent: 'center'
            }}
          />
        </LocalizationProvider>
      </Col>

      <Row>
        {foodOptions.salad?.length > 0 && (
          <Dropdown
            label={t('textboxes.salad')}
            // containerStyle={{ width: 'calc(50vw / 4)', marginRight: '20px', paddingTop: '16px' }}
            containerStyle={{ /* width: '155px', */ marginRight: '20px', paddingTop: '16px', width: '50%' }}
            setValue={(value) => changeGuest('salad', value)}
            value={guest.salad}
            options={foodOptions.salad}
          />
        )}
        {foodOptions.starter?.length > 0 && (
          <Dropdown
            label={t('textboxes.starter')}
            // containerStyle={{ width: 'calc(50vw / 4)', marginRight: '20px', paddingTop: '16px' }}
            containerStyle={{ /* width: '155px', */ marginRight: '20px', paddingTop: '16px', width: '50%' }}
            value={guest.starter}
            setValue={(value) => changeGuest('starter', value)}
            options={foodOptions.starter}
          />
        )}

      </Row>
      <Row $mr>
        {foodOptions.main?.length > 0 && (
          <Dropdown
            label={t('textboxes.main')}
            // containerStyle={{ width: 'calc(50vw / 4)', marginRight: '20px', paddingTop: '16px' }}
            containerStyle={{ /* width: '155px', */ marginRight: '20px', paddingTop: '16px', width: '50%' }}
            value={guest.main}
            setValue={(value) => changeGuest('main', value)}
            options={foodOptions.main}
          />
        )}
        {foodOptions.dessert?.length > 0 && (
          <Dropdown
            label={t('textboxes.dessert')}
            // containerStyle={{ width: 'calc(50vw / 4)', paddingTop: '16px' }}
            containerStyle={{ /* width: '155px', */ marginRight: '20px', paddingTop: '16px', width: '50%' }}
            value={guest.dessert}
            setValue={(value) => changeGuest('dessert', value)}
            options={foodOptions.dessert}
          />
        )}
      </Row>
    </Container>
  )
}

export default GuestInfo;

// export default React.memo(GuestInfo, (prevProps, nextProps) => {
//   return prevProps.guest !== nextProps.guest && prevProps.foodOptions !== nextProps.foodOptions && prevProps.handleChange !== nextProps.handleChange;
// });

// export default React.memo(GuestInfo, (prevProps, nextProps) => {
//   return prevProps.guest !== nextProps.guest;
// });
