import { Fragment, useContext } from 'react';
import { LanguageContext } from 'contexts/Languages';
import image from 'assets/img/image/bg.png';
import Cloud from 'assets/img/image/cloud-layout.png';
import { enText, trText } from './text';
import {
  Container,
  HeaderArea,
  BackImage,
  HeaderContent,
  HeaderTitle,
  ContextContainer,
  Context,
  Title,
  Text,
  List,
  ListItem,
  Line,
  CloudImg
} from './style';

const PrivacyPolicy = () => {
  const { language } = useContext(LanguageContext);
  return (
    <Fragment>
      <CloudImg
        src={Cloud}
        alt='cloud'
        width={'100%'}
        height={'100%'}
      />
      <Container>
        <HeaderArea>
          <BackImage
            src={image}
            alt='Background'
            width={'100%'}
            height={'100%'}
          />
          <HeaderContent>
            <HeaderTitle>Privacy Policy</HeaderTitle>
          </HeaderContent>
        </HeaderArea>

        <ContextContainer>
          <Context>
            <Text>
              {language.code === 'tr' ? trText : enText}
            </Text>
          </Context>
        </ContextContainer>
      </Container>
      <Line />
    </Fragment>
  )
};

export default PrivacyPolicy;
