import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 20px;
`;

export const Title = styled.span`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 27.8557px;
  line-height: 35px;

  text-transform: uppercase;
  color: #231F20;
`;

export const SubTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 20.2587px;
  line-height: 30px;
  color: #231F20;
  margin-top: 5px;
  margin-bottom: 35px;
`;

export const Text = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: ${({ $fw }) => $fw || 300};
  font-size: 20.2587px;
  line-height: 30px;
  color: #231F20;
`;
