import styled from 'styled-components';
import ForwardIcon from 'assets/img/icons/forward-arrow.svg';

export const Container = styled.section`
  position: relative;
  padding-top: 40px;
  padding-bottom: 240px;
  /* width: 85%; */
  /* margin: 0 auto; */
  box-sizing: border-box;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-around;
  width: 85%;
  margin: 0 auto;
  padding: 0 52px;
  box-sizing: border-box;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 100%;
    padding: 0 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
    flex-direction: column-reverse;
    padding: 0 16px;
  }
`;

export const Content = styled.div`
  width: 75%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 60%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
  }
`;

export const BackTitle = styled.span`
  position: relative;
  user-select: none;
  top: 5px;
  z-index: -1;
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  /* font-size: 144px; */
  font-size: clamp(2.75rem, 0.2596rem + 5.6923vw, 7.375rem);
  line-height: 144px;
  text-transform: capitalize;
  color: #E0E0E0;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    /* font-size: 84px; */
    line-height: 84px;
    top: 0px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    /* font-size: 60px; */
    line-height: 60px;
  }
`;

export const Title = styled.h1`
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 56px;
  text-transform: capitalize;
  color: #139CD7;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 40px;
    line-height: 40px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 24px;
    line-height: 24px;
  }
`;

export const SubText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 65px;
  line-height: 94px;
  letter-spacing: -1.4px;
  color: #002424;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    font-size: 48px;
    line-height: 54px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 32px;
    line-height: 38px;
  }
`;

export const BriefText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 44px;
  letter-spacing: -1.4px;
  color: #002424;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  position: relative;
`;

export const ImageContainer = styled.div`
  position: relative;
  padding-left: 54px;
  &::after {
    transition: width 0.2s ease-in-out;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    /* width: 30%; */
    width: 0;
    height: 96%;
    background: #139CD7;
    opacity: 0.72;
    border-radius: 32px 0px 0px 32px;
    background-image: url(${ForwardIcon});
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
`;

export const MenuCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: transparent;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    /* background-color: #139CD712; */
    background-color: #139CD722;
    ${ImageContainer} {
      &::after {
        /* content: ''; */
        width: 30%;
      }
    }
  }
  box-sizing: border-box;
  padding: 30px calc(7.5% + 52px);
`;

export const MenuContent = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuMeal = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 80px;
  line-height: 94px;
  letter-spacing: -1.4px;
  color: #002424;
`;

export const MenuBrief = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #002424;
`;


export const Image = styled.img`
  width: 25%;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  padding-left: 68px;
  max-width: 500px;
  margin: 0 auto;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    box-sizing: border-box;
    padding: 0 30px;
    padding-bottom: 30px;
    width: 100%;
  }
`;

export const ImageArea = styled.div`
  position: absolute;
  right:100px;
  z-index: -1;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    position: unset;
  }
`;

export const TextContainer = styled.div`
  width: 85%;
  margin: 0 auto;
  padding: 40px 52px;
  padding-bottom: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  /* & > :nth-child(2) {
    margin-left: 10px;
  } */

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    padding: 40px 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    grid-template-columns: 1fr;
    padding: 40px 16px;
  }
`;

export const TextsArea = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: 24px;
`;

export const Text = styled.span`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #231F20;
  white-space: break-spaces;
`;

export const DitsBanner = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: grid;
  pointer-events: none;
  place-items: center;
  z-index: -1;
`;

export const DistImage = styled.img`
  width: 80%;
  user-select: none;
  pointer-events: none;
`;
