import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Text = styled.p`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #231f20;
  white-space: break-spaces;
`;

export const Title = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 27.8557px;
  line-height: 35px;
  text-transform: uppercase;
  color: #231F20;
  padding-bottom: 20px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  &>:not(:last-child) {
    margin-right: 10px;
  }
`;

export const BookContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
`;
