import styled from 'styled-components';
import PanelBack from 'assets/img/image/side-panel.png';

export const Container = styled.aside`
  position: fixed;
  z-index: 11;
  right: 0;
  top: 30%;
  /* background-image: url(${PanelBack});
  background-repeat: no-repeat;
  background-size: cover; */
  /* right: -8%; */
  /* right: -127px; */
  /* right: ${({ $len }) => `-${$len * 14}px`};
  transition-property: right;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  :hover {
    right: 0;
  } */
  right: 0;
  /* right: -89px; */
  right: -${({ $len }) => $len * 6.3}px;
  /* transform: rotateZ(90deg); */
`;

export const SocialMediaArea = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    padding: 15px 4px;
  }
`;

export const SocialMediaItem = styled.li`

`;
