import React from 'react';
import Cloud from 'assets/img/image/cloud-layout.png';
import { LanguageContext } from 'contexts/Languages';
import { TextButton } from 'components/Button';
import { PATHS } from 'routes';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Content,
  CloudImg,
  Title,
  SubTitle,
  Description
} from './style';

const NotFoundPage = () => {
  const { t } = React.useContext(LanguageContext)
  const nav = useNavigate();

  return (
    <Container>
      <Content>
        <Title>Dinner in The Sky</Title>
        <SubTitle>404</SubTitle>
        <Description>
          {t('pages.not_found.description')}
        </Description>
        <TextButton
          title={t('pages.not_found.go_home')}
          hasArrow
          color={'#139CD7'}
          onClick={() => nav(PATHS.HOME)}
          containerStyle={{ marginTop: '36px' }}
        />
      </Content>
      <CloudImg
        src={Cloud}
        alt='cloud'
        width={'100%'}
        height={'100%'}
      />
    </Container>
  )
};

export default NotFoundPage;
