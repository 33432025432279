import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
// import CorporateImg from 'assets/img/image/coorparate.png';
import CorporateImg from 'assets/img/image/coorparate-3.png';
import Button, { TextButton } from 'components/Button';
import TextInput from 'components/TextInput';
import Success from './components/Success';
import CheckBox, { propagation } from 'components/CheckBox';
import Modal from 'components/Modal';
import { BookingContext } from 'contexts/Booking';
import {
  Section,
  CorporateContainer,
  CorporateImage,
  CorporateContact,
  Title,
  SubTitle,
  Description,
  ButtonsArea,
  Form,
  Row,
  SendButton
} from './style';

const colorProps = {
  active: {
    overlayColor: '#139CD7',
    backgroundColor: '#002424',
    textColor: '#FFFFFF'
  },
  passive: {
    overlayColor: '#F4F4F4',
    backgroundColor: '#139CD7',
    textColor: '#C8C8C8'
  }
};

const FORM = {
  firstName: { value: '', error: '' },
  lastName: { value: '', error: '' },
  email: { value: '', error: '' },
  phone: { value: '', error: '' },

  company_name: { value: '', error: '' },
  num_of_seat: { value: '', error: '' },


  isCompany: false,
  consents: {
    terms: { value: false, error: null, isRequired: true }
  }
};

const Corporate = ({
  sectionId,
  t
}) => {
  const reCaptchaRef = React.useRef();
  const consentModalRef = React.useRef(null);
  const { sendReservationForm } = React.useContext(BookingContext);
  const [form, setForm] = React.useState(FORM);
  const [status, setStatus] = React.useState({ success: false, error: false, isProcess: false });

  const handleChangeType = (type) => {
    setForm({ ...form, isCompany: type === 'company' });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: { value, error: '' } });
  };

  const sendForm = async () => {
    const newForm = { ...form };
    let hasError = false;
    Object.keys(newForm).forEach((key) => {
      if (typeof newForm[key] === 'boolean') {
        return;
      }
      if (key === 'consents') {
        Object.keys(newForm[key]).forEach((consentKey) => {
          if (newForm[key][consentKey].isRequired && !newForm[key][consentKey].value) {
            // newForm[key][consentKey].error = 'This field is required';
            newForm[key][consentKey].error = t('textboxes.required');
            hasError = true;
          }
        });
        return;
      }
      if (!newForm.isCompany) {
        if (key === 'company_name' || key === 'num_of_seat') {
          return;
        }
      }
      if (key === 'num_of_seat' && Number(newForm[key].value) <= 3) {
        console.log(newForm[key].value);
        newForm[key].error = t('textboxes.min_seat');
        hasError = true;
      }
      if (!newForm[key].value) {
        // newForm[key].error = 'This field is required';
        newForm[key].error = t('textboxes.required');
        hasError = true;
      }
    });

    if (hasError) {
      setForm(newForm);
      return;
    }

    const token = reCaptchaRef.current.getValue();
    if (token === "") {
      reCaptchaRef.current.reset();
      return;
    };

    const payload = {
      privacy_policy: newForm.consents.terms.value,
      name: newForm.firstName.value,
      surname: newForm.lastName.value,
      email: newForm.email.value,
      phone: newForm.phone.value,
      customer_type: newForm.isCompany ? 'company' : 'person',
      ...(newForm.isCompany && { unit: newForm.num_of_seat.value, company_name: newForm.company_name.value })
    };

    setStatus(prev => ({ success: false, error: null, isProcess: true }));
    try {
      await sendReservationForm({ payload });
      setStatus({ success: true, error: false, isProcess: false });
    } catch (error) {
      setStatus({ success: false, error, isProcess: false });
    }
  };

  const reSendForm = () => {
    setStatus(prev => ({ success: false, error: null, isProcess: false }));
    setForm(FORM);
    reCaptchaRef.current?.reset?.();
  };

  const handleCapchaChange = (value) => {
    console.log("Captcha value:", value);
  }

  const asyncScriptOnLoad = () => {
    console.log('init recaptcha');
  };

  const openConsentModal = () => {
    consentModalRef.current.open();
  };

  const renderConsentText = (k) => {
    const translated = t(`pages.home.contact_section.${k}`);
    const clickStart = translated.indexOf('<0>') + 3;
    const clickEnd = translated.indexOf('</0>');
    const clickText = translated.substring(clickStart, clickEnd);
    const beforeClick = translated.substring(0, clickStart - 3);
    const afterClick = translated.substring(clickEnd + 4);
    // const clickableElem = React.createElement('span', { className: 'consent-btn', onClick: () => console.log('click') }, beforeClick + clickText + afterClick);
    const clickableElem = React.createElement('span', null,
      // React.createElement('span', {},
      React.createElement('span', {}, beforeClick),
      React.createElement('span', {
        className: 'consent-btn', onClick: (e) => {
          propagation(e);
          openConsentModal();
        }
      }, clickText),
      React.createElement('span', {}, afterClick)
      // )
    );
    return clickableElem;
  };

  return (
    <Section id={sectionId}>
      <CorporateContainer>
        <CorporateImage src={CorporateImg} alt={'Corporate Backgrund'} />
        <Modal
          modalType={'consent'}
          subProp={{
            type: 'kvkk'
          }}
          ref={consentModalRef}
        />
        <CorporateContact>
          <Title>{t('pages.home.corporate_section.title')}</Title>
          <SubTitle>{t('pages.home.corporate_section.sub_title')}</SubTitle>
          <Description>{t('pages.home.corporate_section.description')}</Description>
          <ButtonsArea>
            <Button
              // title={'A private individual person'}
              title={t('buttons.special_event')}
              onClick={() => handleChangeType('person')}
              containerStyle={{ marginRight: 15 }}
              responsive={'sm'}
              {...colorProps[form.isCompany ? 'passive' : 'active']}
              disabled={status.isProcess || status.success}
            />
            <Button
              // title={'A company'}
              title={t('buttons.company_event')}
              onClick={() => handleChangeType('company')}
              responsive={'sm'}
              {...colorProps[form.isCompany ? 'active' : 'passive']}
              disabled={status.isProcess || status.success}
            />
          </ButtonsArea>
          {(status.success) &&
            <Success
              reSendForm={reSendForm}
              t={t}
            />
          }

          {(!status.success) && (
            <Form>
              {form.isCompany && (
                <Row>
                  <TextInput
                    name={'company_name'}
                    value={form.company_name.value}
                    onChange={handleChange}
                    placeholder={t('textboxes.company_name')}
                    label={t('textboxes.company_name')}
                    containerStyle={{ marginRight: 20 }}
                    fullWidth
                    error={form.company_name.error}
                  />
                  <TextInput
                    name={'num_of_seat'}
                    value={form.num_of_seat.value}
                    // onChange={handleChange}
                    placeholder={t('textboxes.num_of_seat')}
                    label={t('textboxes.num_of_seat')}
                    fullWidth
                    error={form.num_of_seat.error}
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        handleChange(e);
                      }
                    }}
                  />
                </Row>
              )}

              <Row>
                <TextInput
                  name={'firstName'}
                  value={form.firstName.value}
                  onChange={handleChange}
                  // placeholder={'First Name'}
                  placeholder={t('textboxes.first_name')}
                  label={t('textboxes.first_name')}
                  containerStyle={{ marginRight: 20 }}
                  fullWidth
                  error={form.firstName.error}
                />
                <TextInput
                  name={'lastName'}
                  value={form.lastName.value}
                  onChange={handleChange}
                  // placeholder={'Last Name'}
                  placeholder={t('textboxes.last_name')}
                  label={t('textboxes.last_name')}
                  fullWidth
                  error={form.lastName.error}
                />
              </Row>
              <Row $responsive>
                <TextInput
                  name={'email'}
                  value={form.email.value}
                  onChange={handleChange}
                  // placeholder={'Email'}
                  placeholder={t('textboxes.email')}
                  label={t('textboxes.email')}
                  containerStyle={{ marginRight: 20 }}
                  fullWidth
                  error={form.email.error}
                />
                <TextInput
                  // type={'number'}
                  name={'phone'}
                  autoComplete={'tel'}
                  inputMode={'tel'}
                  value={form.phone.value}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      handleChange(e);
                    }
                  }}
                  // placeholder={'Phone'}
                  placeholder={t('textboxes.phone')}
                  label={t('textboxes.phone')}
                  fullWidth
                  error={form.phone.error}
                />
              </Row>
              <CheckBox
                checked={form.consents.terms.value}
                setChecked={e => {
                  setForm({ ...form, consents: { ...form.consents, terms: { ...form.consents.terms, value: e, error: null } } });
                }}
                label={renderConsentText('consent_text')}
                error={form.consents.terms.error}
              />
              <SendButton>
                <ReCAPTCHA
                  style={{ display: "inline-block" }}
                  ref={reCaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={handleCapchaChange}
                  asyncScriptOnLoad={asyncScriptOnLoad}
                />
                <TextButton
                  title={t('buttons.send_now')}
                  hasArrow
                  onClick={sendForm}
                  containerStyle={{ float: 'right' }}
                  loading={status.isProcess}
                  dotColor={'#002424'}
                />
              </SendButton>
            </Form>
          )}
        </CorporateContact>
      </CorporateContainer>
    </Section>
  )
};

export default Corporate;
