import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  box-sizing: border-box;
  /* padding-left: 15%; */
  padding-left: 12%;
  overflow: hidden;
  /* TODO :: fix */
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    padding-left: 10%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    flex-direction: column;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    padding: 0;
  }
`;

export const Header = styled.div`
  /* width: 85%;
  margin: 0 auto;
  padding: 0 52px;
  padding-top: 20px;
  box-sizing: border-box; */
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    padding-bottom: 20px;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 75%;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    /* min-width: 434px; */
    width: 90%;
    top: -80px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 90%;
    /* min-width: none; */
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    width: 100%;
    box-sizing: border-box;
    padding: 0 14px;
    top: -20px;
  }
`;

export const BackTitle = styled.span`
  position: relative;
  user-select: none;
  top: 20px;
  z-index: -1;
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: clamp(2rem, 0.2596rem + 5.6923vw, 7.375rem);
  line-height: 144px;
  text-transform: capitalize;
  color: #E0E0E0;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    /* font-size: 75px; */
    line-height: 76px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    /* font-size: 60px; */
    line-height: 60px;
  }
`;

export const Title = styled.h1`
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 63px;
  color: #139CD7;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 34px;
    line-height: 34px;
    padding-top: 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 24px;
    line-height: 24px;
  }
`;

export const ImageArea = styled.div`
  position: relative;
  z-index: -1;
  top: 10vh;
  width: 100%;
  width: 30%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 40%;
    top: 20vh;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    top: 0;
    width: 100%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){

  }
`;

export const BalloonImage = styled.img`
  /* width: 45%; */
  width: 100%;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  position: absolute;
  right: 0px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    position: relative;
    /* max-width: 300px; */
    width: 20%;
    /* width: 50%; */
    right: -50%;
  }
`;

export const CloudImage = styled.img`
  width: 45%;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
  z-index: -2;
  position: fixed;
  position: absolute;
  top: 0px;
  right: 0px;
  ${({ $sx }) => $sx};
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    ${({ $responsive }) => $responsive?.lg};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    ${({ $responsive }) => $responsive?.md};
  }
`;