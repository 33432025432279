import styled from 'styled-components';

export const Container = styled.section`
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  width: 85%;
  margin: 0 auto;
  padding: 0 52px;
  padding-top: 40px;
  padding-bottom: 40px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 100%;
    padding-right: 32px;
    padding-left: 32px;
  }
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    flex-direction: column-reverse;
    padding: 0 16px;
    padding-top: 20px;
    padding-bottom: 40px;
  }
`;

export const Content = styled.div`
  width: 60%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    width: 50%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 40%;
  object-fit: contain;
  user-select: none;
  pointer-events: none;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 43%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    width: 50%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    margin: 0 auto;
    width: 100%;
    margin-bottom: 20px;
    max-width: 500px;
  }
`;

export const Title = styled.h1`
  font-family: 'Ananda';
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  /* line-height: 180%; */
  text-transform: capitalize;
  color: #139CD7;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 62px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 42px;
    line-height: 42px;
  }
`;

export const SubTitle = styled.h2`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 80px;
  line-height: 94px;
  display: flex;
  align-items: center;
  letter-spacing: -2px;
  color: #002424;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
    font-size: 50px;
    line-height: 58px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 44px;
    line-height: 50px;
  }
`;

export const Description = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 21px;
  color: #002424;
  /* width: 65%; */
  width: 95%;
  white-space: break-spaces;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    font-size: 14px;
    line-height: 16px;
  }
`;
