import moment from 'moment';
import 'moment/locale/tr';

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getLocalStorage = (key) => {
  const data = window.localStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (_) {
    return data;
  }
};

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key) => {
  window.localStorage.removeItem(key);
};

export const formatDateTime = (date) => {
  if (!isValidDate(new Date(date))) {
    return '';
  }
  return moment(date).utc().locale('en').format('MMMM DD, YYYY [at] h:mm A');
};

export const formatPrice = (price) => {
  // return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return (Math.round(price * 100) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const currencySymbol = (type) => {
  switch (type?.toUpperCase?.()) {
    case 'TRY':
      return '₺';
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    default:
      return '€';
  }
};
