import React from 'react'
import { Input, Slider, SwitchBtn } from './style';

const Switch = ({
  checked = false,
  toggle
}) => {
  return (
    <SwitchBtn>
      <Input
        type='checkbox'
        checked={checked}
        onChange={(e) => {
          toggle(!e.target.checked);
        }}
      />
      <Slider
        $checked={checked}
        onClick={() => toggle(!checked)}
      />
    </SwitchBtn>
  )
}

export default Switch;
