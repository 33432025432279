import styled, { css } from 'styled-components';
import { ReactComponent as Back } from 'assets/img/icons/back.svg';
import { ReactComponent as Arrow } from 'assets/img/icons/arrow.svg';

const SIZES = {
  sm: {
    height: '24px',
    // fontSize: '12px',
    fontSize: '16px',
    pb: '0px',
    border: '1px',
    responsive: { height: '14px', fontSize: '8px' }
  },
  md: {
    height: '48px',
    fontSize: '24px',
    pb: '6px',
    border: '2px',
    responsive: { height: '26px', fontSize: '12px' }
  }
};

export const ButtonOverlay = styled(Back)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 102%;
  object-fit: cover;
  z-index: -1;
  path {
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    fill: ${({ $color }) => $color || '#F4F4F4'};
  }
`;

// export const ButtonOverlay = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   /* width: 100%; */
//   width: 70%;
//   height: 100%;
//   background-color: #F4F4F4;
//   z-index: -1;
//   transform: matrix(2.1, 0.1, 0.1, 1.1, 48, -3.5);
// `;

export const ButtonUI = styled.button`
  overflow: hidden;
  position: relative;
  outline: none;
  border: none;
  z-index: 1;
  width: ${({ $fullSize }) => $fullSize ? '100%' : 'fit-content'};
  color: ${({ $textColor }) => $textColor || '#fff'};
  background-color: ${({ theme, $bg }) => $bg || theme.colors.brand};

  padding: 7.25px 18px;
  /* transition: background-color 0.2s ease, color 0.2s ease; */
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color, color;
  transition-duration: 0.3s;

  /* border-radius: 16px; */
  user-select: none;
  font-size: ${({ $size }) => SIZES[$size].fontSize || '24px'};
  font-family: 'Roboto Condensed';
  min-width: 80px;
  height: ${({ $size }) => SIZES[$size].height};
  min-height: ${({ $size }) => SIZES[$size].height};
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: 700; */
  font-weight: 400;
  letter-spacing: 0.005em;
  white-space: nowrap;
  min-width: auto;
  ${({ $containerStyle }) => $containerStyle}
  text-transform: uppercase;

  /* background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDA5IiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgNDA5IDQ4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMCAwSDQwOVY0OEw2LjkzMjIgNDVMMCAwWiIgZmlsbD0iI0Y0RjRGNCIvPgo8L3N2Zz4K");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;
  background-position: 0% 100%;
  background-size: cover; */

  :disabled {
    cursor: default;
    transform: unset;
    background-color: #B0B7C3;
  }

  :focus-within, :hover {
    transition: box-shadow 0.4s linear;
    -webkit-box-shadow: 0px 4px 24px -12px ${({ theme }) => theme.colors.brand};
    -moz-box-shadow: 0px 4px 24px -12px ${({ theme }) => theme.colors.brand};
    box-shadow: 0px 4px 24px -12px ${({ theme }) => theme.colors.brand};
  }

  ${({ $responsive, theme }) =>
    $responsive &&
    css`
      @media (max-width: ${theme.breakpoints[$responsive] + 'px'}) {
        height: ${({ $size }) => SIZES[$size].responsive.height};
        font-size: ${({ $size }) => SIZES[$size].responsive.fontSize || '12px'};
      }
  `}
`;

export const TextButtonUI = styled.button`
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: ${({ $fullSize }) => $fullSize ? '100%' : 'fit-content'};

  transition: color 0.2s ease;

  user-select: none;
  
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ $size }) => SIZES[$size].fontSize || '18px'};
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: ${({ $color }) => $color || '#002424'};
  /* border-bottom: 2px solid ${({ $color }) => $color || '#002424'}; */
  border-bottom: ${({$size}) => SIZES[$size]?.border} solid ${({ $color }) => $color || '#002424'};
  /* padding-bottom: 6px; */
  padding-bottom: ${({ $size }) => SIZES[$size]?.pb};
  background: transparent;

  white-space: nowrap;
  min-width: auto;

  ${({ $containerStyle }) => $containerStyle}

  :disabled {
    cursor: not-allowed;
    transform: unset;

    opacity: .6;
    &:focus-within, :hover {
      & > svg {
        transform: none;
      }
    }
  }

  &:focus-within, :hover {
    transition: box-shadow 0.4s linear;
    & > svg {
      transform: rotate(45deg);
    }
  }

  & > svg {
    margin-left: 10px;
    transition: transform 0.2s linear;
    path {
      fill: ${({ $color }) => $color || '#002424'};
    }
  }

  ${({ $responsive, theme, $size }) =>
    $responsive &&
    css`
      @media (max-width: ${theme.breakpoints.md + 'px'}) {
        font-size: ${SIZES[$size].responsive.fontSize || '18px'};
        & > svg {
          width: 18px;
          height: 18px;
        }
      }
  `}
  ${({ $loading }) => $loading && css`  
    cursor: not-allowed;
  `}
`;

export const Loader = styled.div`
  /* width: 20px; */
  /* height: 20px; */
  width: ${({ $dotSize }) => $dotSize || '20px'};
  height: ${({ $dotSize }) => $dotSize || '20px'};
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  ${({ $sx }) => $sx || {}};
`;

export const Dot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: ${({ $dotColor }) => $dotColor || '#fff'};
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  &:nth-child(1) { animation-delay: -1.1s; }
  &:nth-child(2) { animation-delay: -1.0s; }
  &:nth-child(3) { animation-delay: -0.9s; }
  &:nth-child(4) { animation-delay: -0.8s; }
  &:nth-child(5) { animation-delay: -0.7s; }
  &:nth-child(6) { animation-delay: -0.6s; }
  &:nth-child(1):before { animation-delay: -1.1s; }
  &:nth-child(2):before { animation-delay: -1.0s; }
  &:nth-child(3):before { animation-delay: -0.9s; }
  &:nth-child(4):before { animation-delay: -0.8s; }
  &:nth-child(5):before { animation-delay: -0.7s; }
  &:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }
`;

const ICON_SIZE = {
  sm: {
    width: '16px',
    responsive: { width: '16px' }
  },
  md: {
    width: 'unset',
    responsive: { width: 'unset' }
  }
};

export const ArrowIcon = styled(Arrow)`
  width: ${({ $size }) => ICON_SIZE[$size]?.width};
  min-width: ${({ $size }) => ICON_SIZE[$size]?.width};  
`;
