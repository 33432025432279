import React from 'react';
import Cloud from 'assets/img/image/cloud-layout.png';
import { LanguageContext } from 'contexts/Languages';
import { TextButton } from 'components/Button';
import { PATHS } from 'routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Container,
  Content,
  CloudImg,
  Title,
  SubTitle,
  Description,
  WarningArea,
  WarningIcon,
} from './style';

const FailedPayment = () => {
  const { t } = React.useContext(LanguageContext)
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [err, setErr] = React.useState(null)

  React.useEffect(() => {
    if (!searchParams.get('paymentError')) {
      return navigate(PATHS.HOME)
    }
    setErr(searchParams.get('paymentError'))
    searchParams.delete('paymentError');
    setSearchParams(prev => ([...prev.entries()]));
  }, [])

  return (
    <Container>
      <Content>
        <Title>Dinner in The Sky</Title>
        <WarningArea>
          <WarningIcon />
        </WarningArea>
        <SubTitle>{t('pages.payment_failure.title')}</SubTitle>
        <Description>
          {err || t('pages.payment_failure.description')}
        </Description>
        <TextButton
          title={t('pages.payment_failure.try_again')}
          hasArrow
          onClick={() => navigate(PATHS.BOOKING)}
          color={'#139CD7'}
          containerStyle={{ marginTop: '36px' }}
        />
      </Content>
      <CloudImg
        src={Cloud}
        alt='cloud'
        width={'100%'}
        height={'100%'}
      />
    </Container>
  )
}

export default FailedPayment
