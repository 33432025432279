import React from 'react';
import { LanguageContext } from 'contexts/Languages';
import {
  Container,
  Text
} from './style';
import {
  explicitConsent_en,
  explicitConsent_tr,

  preContractual_en,
  preContractual_tr,

  distanceSale_en,
  distanceSale_tr,
  
  conditions_en,
  conditions_tr,
  
  kvkk_en,
  kvkk_tr,

  electronic_en,
  electronic_tr
} from './text';

const Consents = ({
  consentType,
  props
}) => {
  const { language } = React.useContext(LanguageContext);

  const textData = React.useMemo(() => {
    switch (consentType) {
      case 'explicit': // explicit
        return language.code === 'tr' ? explicitConsent_tr : explicitConsent_en;
      case 'information': // Ön Bilgilendirme
        return language.code === 'tr' ? preContractual_tr(props.buyer, props.guests) : preContractual_en(props.buyer, props.guests);
      case 'terms': // Mesafeli Satış Sözleşmesi
        return language.code === 'tr' ? distanceSale_tr(props.buyer, props.guests) : distanceSale_en(props.buyer, props.guests);
      case 'privacy_policy': // Hüküm ve Koşulları
        return language.code === 'tr' ? conditions_tr : conditions_en;
      case 'kvkk': // Kişisel verilerinizin
        return language.code === 'tr' ? kvkk_tr : kvkk_en;
      case 'electronic': // Dorak tarafından
      return language.code === 'tr' ? electronic_tr : electronic_en;
      default:
        return '';
    }
  }, [consentType, language.code, props.buyer, props.guests]);

  return (
    <Container>
      <Text>{textData}</Text>
    </Container>
  )
}

export default Consents;
