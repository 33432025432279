import { Fragment, useContext, useState } from 'react';
import Cloud from 'assets/img/image/cloud-layout.png';
import image from 'assets/img/image/bg.png';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { LanguageContext } from 'contexts/Languages';
import {
  Container,
  HeaderArea,
  BackImage,
  HeaderContent,
  HeaderTitle,
  ContextContainer,
  Context,
  // Title,
  Text,
  List,
  ListItem,
  Line,
  AccordionArea,
  ExpandMoreIcon,
  CloudImg
} from './style';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))((e) => ({
  '&': {
    borderBottom: e.expanded ? 'none' : '1px solid #002424',
    backgroundColor: 'transparent'
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(() => ({
  padding: 0
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme, ...props }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid #002424',
}));

const data = {
  tr: [
    {
      title: 'Dinner the Sky nerede gerçekleştiriliyor?',
      body: 'Siz değerli müşterilerimize, Cumhuriyet mahallesi, Zelve cad. No :2 Taşköprü Avanos Nevsehir. Bigchefs Otoparkı, Nevşehir/TÜRKİYE adresinde hizmet vermekteyiz.'
    },
    {
      title: 'Biletimi iade veya başka birine devredebilir miyim?',
      body: 'Biletler rezerve edildikten sonra iade edilemez, farklı bir tarih veya kişiye aktarılamaz. Şöyle ki, Mesafeli Sözleşmeler Yönetmeliği’nin "cayma hakkının istisnaları" başlığını taşıyan 15 inci maddesinin (g) bendi uyarınca belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler, cayma hakkının istisnaları arasına giren sözleşmelerden olduğundan ve sözleşme konusu mal/hizmet etkinlik biletleri satışına ilişkin olup belirli bir tarihte yapılması gereken, eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler arasında olduğundan, Yönetmelik gereğince  cayma hakkınız bulunmamaktadır.'
    },
    {
      title: 'Kötü hava koşulları nedeniyle etkinlik gerçekleştirilemiyorsa ne olacak?',
      body: 'Dinner in the Sky bir açık hava yemek deneyimidir. Kötü hava koşulları devam ederse, Satıcı’nın kararı doğrultusunda yemek deneyimi iptal edilecek veya yeniden planlanacaktır. Satıcı etkinliğin ertelenmesine karar vermesi halinde Alıcı’nın tarihi değiştirilmiş etkinliğe katılması mümkün değilse Alıcı’ya en geç 14 gün içerisinde kredi/banka kartlarına tam bir geri ödeme yapılacaktır. Geri ödeme süreci bankanıza bağlı olarak farklılık gösterdiğinden, iadelerinizi bankanızla iletişime geçerek takip edebilirsiniz.'
    },
    {
      title: 'Dinner in the Sky etkinliği için bir yaş sınırı var mıdır? ',
      body: 'Dinner in the Sky kuralları güvenlik sebepli olarak, yaşla değil misafirlerin boylarıyla ilintilidir. Müşterilerimizi koltuklarına güvenli bir şekilde sabitleyebilmemiz için misafirlerimizin boylarının en az 150 cm olması gerekmektedir. Aktivitemize katılacak 18 yaşından küçük kişilere, mutlaka yakını olan ve sorumluluğu üstlenebilecek bir yetişkin eşlik etmelidir.'
    },
    {
      title: 'Dinner in the Sky etkinliği güvenli midir? ',
      body: `Evet, güvenlidir. Dinner in the Sky, Events in the Sky tarafından iki anahtar kelimeye göre geliştirilmiş bir üründür: Seçkinlik ve Güvenlik. Müşterilerimize hayal edebilecekleri en özel deneyimleri tamamen güvenli bir şekilde sunmak istiyoruz. Tüm konseptlerimiz EN13814 normuna uygun olarak tasarlanmıştır. Ürünler Belçika'da Rheinland TÜV'ün kontrolü altında üretilmektedir.
      Bu güvenlik takıntısı, Dinner in the Sky'ın şu anda 60'tan fazla ülkede faaliyet göstermesinin ve ABD, Kanada, Avustralya, Güney Afrika gibi en zorlu ülkelerden resmi izinler almasının nedenlerinden biridir. Dinner in the Sky farklı ülkelerde ilk olarak 2006 yılında hizmet vermeye başlamış ve 15 yılı aşkın bir süredir faaliyet göstermektedir. Bu süre zarfında müşterilerimizce olumsuz tek bir olay dahi yaşanmamıştır.`
    },
    {
      title: 'Kıyafet zorunluluğu bulunuyor mu? ',
      body: 'Dinner in the Sky nefes kesici bir yaşam deneyimidir, o sırada kıyafetiniz en az endişelendiğiniz şey olmalıdır. Sonuç olarak, soğuk ve esintili gecelerde rahat ve sıcak tutacak şekilde giyinmeli, etek ve açık ayakkabılardan kaçınmayı düşünebilirsiniz.'
    },
    {
      title: 'Hamileler etkinliğe katılabiliyor mu?',
      body: 'Etkinliğimiz hamileler için de güvenlidir. Ancak misafirleri bağlamak için kullanılan üç emniyet kemeri ile sandalyede rahatsız olabilecekleri için altı ay ve üzeri hamile kadınlar için tavsiye etmiyoruz. Ancak bu durum konukların takdirine bağlıdır.'
    },
    {
      title: 'Etkinlik sırasında yanıma alabileceğim şeyler nelerdir?',
      body: 'Misafirlerimizi, gökyüzündeyken yaşadıkları deneyimin fotoğraflarını çekebilmeleri için bir kamera veya telefon getirmeye teşvik ediyoruz. Masaya çanta veya diğer eşyaların konulmasına izin verilmeyecektir, ancak aktiviteye kabul edemediğimiz günlük olağan eşyalarınızı resepsiyon vestiyerine güvenli bir şekilde bırakılabilirsiniz.'
    },
    {
      title: 'Sigara içilebilir mi? ',
      body: 'Etkinliğimizde, güvenlik nedeniyle sigara içilmememektedir. '
    },
    {
      title: 'Engelli misafirler Dinner in the Sky deneyimi yaşayabilir mi?',
      body: 'Tekerlekli sandalye kullanıcıları ve hafif engelli kişilerin etkinliğe katılması önünde bir engel bulunmamaktadır. Ancak güvenlik görevlimizin değerlendirmesi ve tavsiyesi için bilet alımı yapmadan önce engel durumunuza ilişkin ayrıntılı bir açıklama ile bize yazın. Haber verilmesi halinde her türlü talebiniz için misafir ilişkileri ekibimiz size yardıma hazırdır.'
    },
    {
      title: 'Başka bir sorum varsa ? ',
      body: 'Yukarıda yer alan açıklamalarımız size yeterli gelmediyse bize aşağıdaki mail adresimiz aracılığı ile doğrudan ulaşabilirsiniz.'
    }
  ],
  en: [
    {
      title: 'Where is Dinner the Sky being held?',
      body: 'Address: Adnan Menderes Cad. No:34 Göreme, Nevşehir/TURKEY'
    },
    {
      title: 'What is the refund policy?',
      body: 'Once booked, tickets are non-refundable and cannot be transferred to a different date. Customers cannot transfer tickets to another person if they are no longer able to attend. According to section g of article 15 of Mesafeli Sözleşmeler Yönetmeliği (Distance Contracts Regulation) contracts for accommodation, transportation, car rental, food and drink supply and entertainment and recreation purposes that needs to be provided at a certain date or time period are exceptions to the right of withdrawal. Since the contract is about the ticket purchase for an event that should be performed at a certain time the buyer doesn’t have the right of withdrawal. Should we have to cancel the experience due to inclement weather or if we are unable to start the lift, guests will be offered a full refund or the opportunity to rebook during the event.'
    },
    {
      title: 'What happens if bad weather is forecast for the day of the event?',
      body: 'Dinner in the Sky is an outdoor dining experience and as such we are open to the elements.  If the bad weather does not stop during, the dinner experience will be Cancelled or reschedule and refund policy will be as follows: A full refund will apply to their credit/debit card within maximum of 14 days. The repayment procedure may differ depending on your bank therefore you can follow the procedure by contacting your bank.'
    },
    {
      title: 'What is the minimum age to attend Dinner in The Sky?',
      body: 'Dinner in the sky is not age related but height related, we need to be able to secure you safely in your seat, minimum height is 150 cm. Children shall be accompanied by an adult.'
    },
    {
      title: 'Is The Experience Safe?',
      body: `Yes it is. Dinner in the Sky® is a product developed by Events in the Sky according to two key words: Exclusivity and Safety. We want to provide our clients with the most exclusive experiences ever dreamt of in a completely safe way. All our concepts are designed in conformity with the EN13814 norm. Products are built in Belgium under the control of Rheinland TÜV.
      This obsession for safety is probably one of the reasons why Dinner in the Sky® is now operated in more than 60 countries and got the official authorizations from the toughest countries such as US, Canada, Australia, South-Africa etc. Dinner in the Sky first started to serve in different countries in 2006 and has been operating for more than 15 years. During this time, not a single negative incident has been experienced by our customers.`
    },
    {
      title: 'Is There Any Dress Code?',
      body: 'Dinner in the sky is a breathtaking life experience, your clothing at that time should be the last thing you worry about. As a result, you should get dressed comfortably and warm during cold & breezy nights, while you could think of avoiding skirts and open shoes.'
    },
    {
      title: 'Can I participate if I’m pregnant?',
      body: 'Dinner in the Sky is safe for pregnant ladies, but we don’t recommend it for women more than six months pregnant as they may be uncomfortable in the chair with the three seat belts used to fasten in guests. This is however down to the guest’s discretion.'
    },
    {
      title: 'What can I take up with me on the sky table?',
      body: 'We encourage guests to bring a camera or phone to take pictures of your experience whilst in the sky. No bags or other items will be allowed on the table but can be left securely in the reception cloakroom.'
    },
    {
      title: 'Is Smoking Allowed?',
      body: 'Dinner in the sky is a non- smoking event for safety reasons.'
    },
    {
      title: 'Can Someone with A Disability Experience Dinner In The Sky?',
      body: 'Experience is possible for wheelchair users and people with mild disabilities, **BUT** please write to us in advance **BEFORE** purchasing your seat with a detailed explanation on your condition for our safety officer to evaluate and advise accordingly.'
    },
    {
      title: 'Any other Question?',
      body: 'Just write to us : info@dinnerintheskycappadocia.com.tr'
    }
  ]
};

const Questions = () => {
  const { language } = useContext(LanguageContext);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <AccordionArea>
      {(language.code === 'tr' ? data.tr : data.en).map((item, index) => (
        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Text>{item.body}</Text>
          </AccordionDetails>
        </Accordion>
      ))}
    </AccordionArea>
  )
};

const Faq = () => {
  const { t } = useContext(LanguageContext);
  
  return (
    <Fragment>
      <CloudImg
        src={Cloud}
        alt='cloud'
        width={'100%'}
        height={'100%'}
      />
      <Container>
        <HeaderArea>
          <BackImage
            src={image}
            alt='Background'
            width={'100%'}
            height={'100%'}
          />
          <HeaderContent>
            <HeaderTitle>{t('pages.faq.title')}</HeaderTitle>
          </HeaderContent>
        </HeaderArea>

        <ContextContainer>
          {/* <Context>
            <Text>
              Hakuna Matata sprl, a company having its registered office at Avenue Emile de Béco, 47 - 1050 Brussels (Belgium), is the controller of the personal data collected through this website, meaning it determines the purposes and means to process such personal data.{'\n'}{'\n'}
              Dinner in the Sky is committed to protecting your personal data in a transparent, secure and lawful manner.{'\n'}{'\n'}
              The legislation applicable to such personal data processing by Dinner in the Sky is EU Regulation 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data.
            </Text>
          </Context> */}

          <Questions />
        </ContextContainer>
      </Container>
      <Line />
    </Fragment>
  )
};

export default Faq;
