import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import theme from 'styleConfig/theme';
import { ThemeProvider } from 'styled-components';
import { AppControlProvider } from 'contexts/AppControl';
import { LanguageProvider } from 'contexts/Languages';
import { BookingProvider } from 'contexts/Booking';
import GlobalStyle from 'styleConfig/GlobalStyle';
import Seo from 'helpers/Seo';
import { getLocalStorage } from 'helpers';
import { CookiesProvider } from 'react-cookie';
import CookieBanner from 'components/CookieBanner';

if (process.env.NODE_ENV === 'production' && !getLocalStorage('debug')) {
  console.log(`%Dinner in The Sky\n© ${new Date().getFullYear()}, made with by ♥️ Kodzilla https://kodzillaistanbul.com`, "color:#139CD7; font-size:20px;font-weight: bold");
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppControlProvider>
      <LanguageProvider>
        <BookingProvider>
          <ThemeProvider theme={theme}>
            <CookiesProvider
              // cookies={
              //   {
              //     'cookie-banner-accepted': getLocalStorage('cookie-banner-accepted'),
              //     'cookie-banner-closed': getLocalStorage('cookie-banner-closed'),
              //   }
              // }
            >
              <Seo />
              <GlobalStyle />
              <CookieBanner />
              <App />
            </CookiesProvider>
          </ThemeProvider>
        </BookingProvider>
      </LanguageProvider>
    </AppControlProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
