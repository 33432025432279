import styled from 'styled-components';

export const SettingsTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  margin-bottom: 15px;
  color: #231F20;
`;

export const CookieArea = styled.div`
  padding-top: 38px;
`;

export const SectionHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const SectionTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #231F20;
`;

export const SectionText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #231F20;
  ${({ $sx }) => $sx};
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #002424;
  margin-top: 24px;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 12px;
  & > * {
    margin-bottom: 12px;
  }
`;
