import React from 'react';
import {
  Container,
  GuestContent,
  GuestName,
  Menu,
  List,
  ListItem
} from './style';

const GuestMenu = ({
  guests = [],
  session,
  t
}) => {

  const guestMenu = React.useCallback((guest) => {
    const guestInfo = { ...guest };
    const sessionFoods = [
      ...(session?.main_food || []),
      ...(session?.dessert_food || []),
      ...(session?.cocktail_food || []),
      ...(session?.salad_food || []),
      ...(session?.starter_food || []),
    ]
    delete guestInfo.name;
    const menu = [];
    Object.values(guestInfo).forEach((val) => {
      if (val?.label) {
        const food = sessionFoods.find((food) => food?.id === val?.value);
        if (food) {
          // val.label = food?.name;
          menu.push(food?.name);
        }
      }
      // val?.label && menu.push(val?.label);
    });

    return menu;
    // return menu?.join?.(', ')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guests, session, t]);

  if (guests.length === 0) {
    return (
      <Container>
        <p>no guest</p>
      </Container>
    )
  }

  return (
    <Container>
      {guests.map((guest, index) => (
        <GuestContent key={`guest_${index}`}>
          <GuestName>{guest.name}</GuestName>
          <Menu>
            <List>
              {guestMenu(guest).map((menu, index) => (
                <ListItem key={`menu_${index}`}>{menu}</ListItem>
              ))}
            </List>
          </Menu>
        </GuestContent>
      ))}
    </Container>
  )
};

export default GuestMenu;
