export const termsEn = `
1) If you make an electronic purchase of the service through the website you are using, you are deemed to have accepted the preliminary information form and the distance sales contract presented to you. As a rule, sales of all services are final. According to subparagraph (g) of Article 15 of the Distance Contracts Regulation titled "exceptions to the right of withdrawal", since the contracts for accommodation, goods transport, car hire, car hire, food and beverage supply, and leisure time utilization for entertainment or recreation purposes, which must be made on a certain date or period, are among the contracts that fall within the exceptions to the right of withdrawal, and since the subject of the contract is related to the sale of event tickets and is among the contracts for the utilization of leisure time for entertainment or recreation purposes, which must be made on a certain date, the Buyer does not have the right of withdrawal by the Regulation. Moreover, before purchasing the ticket by approving your order; you’re accepting, stating, and committing in written form that you are aware of
a) the basic characteristics of the ticket that you want to purchase which is the subject of the contract,
b) that you have met the 150cm height requirement within the scope of the "Dinner in the Sky" safety rule otherwise, you will not be able to participate in the event on the event date,
c) the total ticket/service price including all taxes and all additional expenses –if exists-,
d) that you don’t have right of withdrawal based on article 15/g of the Distance Contracts Regulation, and eventually all the provisions regulated in the contract. Before approving your orders, your pre-briefing approval is received in written form within the scope of your approval. In addition, we would like you to know that that you are informed clearly and apprehensibly by this contract and pre-approval regarding that “you will enter into the obligation of making payment in case of approval of your ticket purchase order”. 
2) Buyers are subjected to the provisions of Law Code No. 6502 on the Consumer Protection Law and the Regulation on Distance Contracts (OG: 27.11.2014/29188) and other applicable laws regarding the service they purchase.
3) The event ticket for the service subject to the contract will be sent to the Buyer via sms and/or e-mail in accordance with the method preferred by the Buyer and no physical delivery will be made. The Buyer is only entitled to the place specified in the sms and/or e-mail sent to him/her, and the Seller reserves the right to provide the Buyer a different place than the place specified in the sms and e-mail.
4) The Seller may change or cancel the date and time of the event subjected to the contract. In cases where it is not possible for the Buyer to participate in the event whose date and time have been changed by the Seller, the Buyer may be refunded. The Seller is not obliged to meet the Buyer's time and date change requests regarding the purchased service. Within the scope of customer satisfaction, the Seller may suggest another time or date that the Buyer can participate in the event at his own discretion. The dinner experience will be Cancelled the refund policy will be as follows: A full refund will apply to their credit/debit card within maximum of 14 days. The repayment procedure may differ depending on your bank therefore you can follow the procedure by contacting your bank.
5) The purchased tickets can not be used for commercial purposes. The individual purchasing the ticket accepts and commits that he had purchased the ticket for his exclusive personal use without the intention of any commercial purpose, and that he will not use the ticket for any commercial works or commercial activities. Within this scope the ticket that is purchased can not be sold to third parties in any manner, its sales by additional fee or in any other manner are prohibited, it can not be used for promotional or other commercial purposes including advertising, contests and sweepstakes. Selling or attempting to sell the ticket with a price over the one written on it constitutes a rightful reason for the confiscation and/or cancellation of the ticket by the authorities without making any refund or without entitling for other requests.
6) A maximum of 32 tickets can be purchased by a single buyer. If the buyer purchases more than one ticket, he/she must enter the distinguishing information such as name, surname, and date of birth of the participants requested by the Seller into the system. Tickets cannot be transferred to a different person after purchase. For this reason, the Seller has the right to request information and documents confirming the identity of the participants of the event.
7)In case of any dispute during the implementation of the aforementioned terms and conditions, the resolution of disputes is subject to Turkish Law. All consumers are informed by dinnerintheskycappadocia.com.tr regarding the provisions and content of the contract before purchasing ticket, and we would like you to know that you will be deemed to have accepted the performance of pre-briefing regarding the regulations of the contract within the frame of Distance Contracts Regulation.  This article is a means of proof thus has he quality of written evidence. In case of any conflict due to this Contract and/or due to the service or ticket purchase operations, it is our basic objective to first try to resolve the conflict amicably.  On the other hand, in cases that can not be resolved amicably, you can refer the possible arising problems to the Consumer Arbitration Boards and/or to Consumer Courts within the frame of monetary limits issued in the relevant legislation.
NOTE: Our web site has been prepared in Turkish and English languages. In case of any difference and/or discrepancy in between the Turkish and English texts, the Turkish text shall prevail.
`;

export const termsTr = `
1) Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda hizmete ilişkin satın alım yaptığınız takdirde, size sunulan ön bilgilendirme formunu ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız. Kural olarak tüm hizmetlere ilişkin satışlar kesindir. Mesafeli Sözleşmeler Yönetmeliği’nin "cayma hakkının istisnaları" başlığını taşıyan 15 inci maddesinin (g) bendi uyarınca belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler, cayma hakkının istisnaları arasına giren sözleşmelerden olduğundan ve sözleşme konusu hizmet, etkinlik biletleri satışına ilişkin olup belirli bir tarihte yapılması gereken, eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler arasında olduğundan, Yönetmelik gereğince cayma hakkınız bulunmamaktadır. Ayrıca, siparişinizi onaylayarak; bileti satın almadan önce;
a) sözleşme konusu olan satın almak istediğiniz hizmetin/biletin temel niteliklerini
b) Dinner in the Sky güvenlik kuralı kapsamında 150cm boy şartını sağladığınızı, aksi takdirde etkinlik tarihinde etkinliğe katılım sağlayamayacağınızı,
b) ayrıca tüm vergiler dahil toplam bilet/hizmet fiyatını ve varsa tüm ek masraflarını
d) Mesafeli Sözleşmeler Yönetmeliği'nin 15/g. maddesine istinaden cayma hakkınızın olmadığını ve neticeten sözleşmede düzenlenen tüm hükümleri bildiğinizi yazılı olarak kabul, beyan ve taahhüt etmektesiniz. Siparişlerinizi onaylamadan önce, onayınız kapsamında, yazılı şekilde ön bilgilendirme onayınız alınmıştır. İlaveten, işbu sözleşme ve ön onay ile “bilet satın alma siparişinizin onaylanması durumunda ödeme yükümlülüğü altına gireceğiniz” konusunda açık ve anlaşılır şekilde bilgilendirildiğinizi de önemle bilmenizi isteriz.
2) Alıcılar, satın aldıkları hizmet ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer yasalara tabidir.
3) Sözleşme konusu hizmete ilişkin etkinlik bileti Alıcıya tercih ettiği yöntem doğrultusunda sms ve/veya e-posta ile iletilecek olup ayrıca fiziken teslimat yapılmayacaktır. Alıcı, sadece kendisine gönderilen sms ve/veya e-postada belirtilen yer üzerinde hak sahibi olup, Satıcı, Alıcıya sms ve e-postada belirtilen yerden farklı bir yer verme hakkını saklı tutmaktadır.
4) Satıcı, herhangi bir sebeple sözleşme konusu etkinliğin tarih ve saatini değişebilir, iptal edebilir. Satıcı tarafından tarihi ve saati değiştirilmiş etkinliğe Alıcının katılmasının mümkün olmadığı hallerde, Alıcıya ücret iadesi yapılabilir. Satıcının, satın alınan hizmete ilişkin Alıcının saat ve tarih değişikliği taleplerini karşılama zorunluluğu bulunmamaktadır. Satıcı, müşteri memnuniyeti kapsamında keyfiyeti kendisine ait olmak üzere Alıcının etkinliğe dahil olabileceği başka bir saat veya tarih önerebilir. Satıcının etkinliğin ertelenmesine karar vermesi halinde Alıcı’nın tarihi değiştirilmiş etkinliğe katılması mümkün değilse Alıcı’ya en geç 14 gün içerisinde kredi/banka kartlarına tam bir geri ödeme yapılacaktır. Geri ödeme süresi tamamen aracı bankaya bağlı olarak farklılık gösterebilmektedir.
5)Satın alınan mal veya hizmet ticari amaçla kullanılamaz. Alıcı, satın aldığı mal veya hizmeti hiçbir ticari amaç gütmeksizin, münhasıran şahsi kullanımı için satın aldığını, hiçbir ticari iş veya ticari faaliyet için kullanmayacağını beyan ve taahhüt eder. Bu kapsamda satın alınan mal veya hizmet üçüncü kişilere her ne şekilde olursa olsun satılamaz, ilave ücretle ya da başkaca şekilde satışı teklif edilemez, satın alınan mal veya hizmet, reklam, yarışma ve çekilişler de dahil olmak üzere promosyon veya diğer ticari maksatlarla kullanılamaz. Mal ve hizmetin, etkinlik bileti üzerinde yazılı fiyattan daha yüksek bir fiyatla satılması veya satışına teşebbüs edilmesi, hiçbir ücret iadesi yapılmaksızın veya başkaca taleplere hak kazandırmaksızın, yetkililerce bilete el koyma ve/veya etkinlik biletinin iptali için haklı bir sebep teşkil eder.
6)Tek bir alıcı tarafından en fazla 32 adet bilet alımı yapılabilecektir. Alıcı, birden fazla bilet alımı gerçekleştiriyorsa, Satıcı tarafından talep edilen katılımcılara ait isim, soyisim ve doğum tarihi gibi ayırt edici bilgilerin sisteme girişini yapmalıdır. Biletler satın alındıktan sonra farklı kişiye aktarılamaz. Bu sebeple, Satıcı , etkinlik alanın katılımcılardan kimliklerini doğrular bilgi ve belgeleri talep etme hakkına sahiptir.
7)Yukarıda belirtilen şart ve koşulların uygulanması sırasında herhangi bir uyuşmazlık durumunda uyuşmazlıkların çözümü Türk Hukukuna tabidir. Sözleşme konusu mal veya hizmeti satın almadan önce sözleşme hükümleri ve içeriği konusunda, tüm Alıcılar “dinnerintheskycappadocia.com.tr “ tarafından bilgilendirilmiş olup, etkinlik biletini satın alarak ve siparişi onaylayarak, işbu sözleşmenin düzenlemeleri hakkında tarafınıza Mesafeli Sözleşmeler Yönetmeliği çerçevesinde ön bilgilendirme yapıldığını da kabul etmiş sayılacağınızı bilmenizi isteriz. Bu madde yazılı delil vasfında ispat vasıtasıdır. İşbu Sözleşmeden ve/veya hizmet ya da etkinlik bileti satın alma işlemlerinden dolayı herhangi bir ihtilafın doğması durumunda, uyuşmazlığı öncelikle sulhen gidermeye çalışmak temel hedefimizdir. Buna karşın, sulh ile çözülemeyen durumlarda, doğabilecek olası sorunları ilgili mevzuatta yayımlanan parasal sınırlar çerçevesinde Tüketici Hakem Heyetlerine ve/veya Tüketici Mahkemelerine götürebilirsiniz.
NOT: Sitemiz, Türkçe ve İngilizce dillerinde hazırlanmıştır. Türkçe ve İngilizce metinler arasında herhangi bir farklılık ve/veya çelişki olması halinde Türkçe metin esas alınacaktır.
`;
