import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/img/icons/btn.svg';
import { ReactComponent as NavigateLayout } from 'assets/img/icons/navigate-button.svg';
import {
  Container,
  Overlay,
  Content,
  FSImage,
  FSVideo,
  CloseButton,
  NavigateButton,
  Arrow
} from './style';

const SingleMedia = ({
  allMedia,
  data,
  onClose
}, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentMedia, setCurrentMedia] = React.useState(data);
  const _currentMedia = React.useRef(data);

  React.useEffect(() => {
    _currentMedia.current = currentMedia;
  }, [currentMedia]);

  React.useEffect(() => {
    setCurrentMedia(data);
    return () => {
      setCurrentMedia(null);
    }
  }, [data]);

  const nextMedia = () => {
    allMedia.forEach((media, index) => {
      if (media.id === _currentMedia.current.id) {
        if (allMedia[index + 1]) {
          setCurrentMedia(allMedia[index + 1]);
        } else {
          setCurrentMedia(allMedia[0]);
        }
      }
    });
  };

  const prevMedia = () => {
    allMedia.forEach((media, index) => {
      if (media.id === _currentMedia.current.id) {
        if (allMedia[index - 1]) {
          setCurrentMedia(allMedia[index - 1]);
        } else {
          setCurrentMedia(allMedia[allMedia.length - 1]);
        }
      }
    });

    // allMedia.forEach((media, index) => {
    //   media.data.forEach((item, idx) => {
    //     if (item.id === _currentMedia.current.id) {
    //       if (media.data[idx - 1]) {
    //         setCurrentMedia(media.data[idx - 1]);
    //       } else {
    //         if (index === 0) {
    //           setCurrentMedia(allMedia[allMedia.length - 1].data[allMedia[allMedia.length - 1].data.length - 1]);
    //         } else {
    //           setCurrentMedia(allMedia[index - 1].data[allMedia[index - 1].data.length - 1]);
    //         }
    //       }
    //     }
    //   })
    // });
  };

  const keyListener = (event) => {
    switch (event.key) {
      case 'Escape':
        close();
        break;
      case 'ArrowRight':
        nextMedia();
        break;
      case 'ArrowLeft':
        prevMedia();
        break;
      default:
        break;
    }
  }

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', keyListener, false);
    } else {
      document.removeEventListener('keydown', keyListener, false);
    }
    return () => {
      document.removeEventListener('keydown', keyListener, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);


  const close = () => {
    onClose?.();
    setIsOpen(false);
    setCurrentMedia(null);
  };

  const open = () => {
    setIsOpen(true);
  };

  React.useImperativeHandle(ref, () => ({
    open: open,
    close: close,
  }));

  React.useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.removeAttribute('style')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  if (!isOpen) {
    return;
  }

  return (
    <React.Fragment>
      <Overlay onClick={close} />
      <CloseButton onClick={close}>
        <CloseIcon />
      </CloseButton>

      <NavigateButton $isRight={true} onClick={nextMedia}>
        <NavigateLayout />
        <Arrow $isRight={true} />
      </NavigateButton>

      <NavigateButton $isRight={false} onClick={prevMedia}>
        <NavigateLayout />
        <Arrow $isRight={false} />
      </NavigateButton>

      <Container ref={ref}>
        <Content>
          {currentMedia?.type === 'video' ?
            <FSVideo
              src={currentMedia?.source}
              controls
              controlsList='nodownload noplaybackrate'
              disablePictureInPicture
              disableRemotePlayback
              autoPlay
              playsInline
            />
            :
            <FSImage src={currentMedia?.source} />
          }
        </Content>

      </Container>
    </React.Fragment>
  )
};

export default React.forwardRef(SingleMedia);
