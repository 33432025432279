import React from 'react';
import { ReactComponent as LogoImg } from 'assets/img/logo/dits-logo-full.svg'
// import { ReactComponent as IyzicoLogo } from 'assets/img/logo/iyzico.svg'
import { Link } from 'react-router-dom';
import { FOOTER_LINKS, SOCIAL_MEDIA } from 'constants/sections'
import { LanguageContext } from 'contexts/Languages';
import {
  Container,
  Content,
  RightText,
  TopArea,
  BottomArea,
  LinkArea,
  LinkItem,
  SocialMediaArea,
  SocialMediaItem,
  IyzicoLogo
} from './style';

const Footer = () => {
  const { t } = React.useContext(LanguageContext);
  return (
    <Container>
      <Content>
        <TopArea>
          <LogoImg />
          <LinkArea>
            {FOOTER_LINKS.map(({ key, path }) => (
              <LinkItem key={key}>
                <Link to={path}>
                  {t(`footer.${key}`)}
                </Link>
              </LinkItem>
            ))}
          </LinkArea>
        </TopArea>

        <BottomArea>
          <RightText>© {new Date().getFullYear()} Dinner in the sky. {t('footer.all_right')}</RightText>
          {/* <IyzicoLogo /> */}
          <SocialMediaArea>
            {SOCIAL_MEDIA.map(({ key, path, asset: Logo }) => (
              <SocialMediaItem key={key}>
                <a
                  href={path}
                  target="_blank"
                  rel="noreferrer">
                  <Logo />
                </a>
              </SocialMediaItem>
            ))}
          </SocialMediaArea>
        </BottomArea>
      </Content>
    </Container>
  )
};

export default Footer;
