import styled from 'styled-components';

export const CloudImg = styled.img`
  width: 100%;
  object-fit: contain;
  position: fixed;
  top: 320px;
  right: -20%;
  z-index: -1;
  user-select: none;
`;

export const Container = styled.div`
  padding: 0 52px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 0 16px;
  }
`;

export const HeaderArea = styled.div`
  position: relative;
`;

export const BackImage = styled.img`
  /* position: absolute; */
  user-select: none;
`;

export const HeaderContent = styled.div`
  position: absolute;
  left: 10%;
  top: 45%;
  transform: translateY(-45%);
`;

export const HeaderTitle = styled.h1`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 74px;
  letter-spacing: -1.2px;
  color: #FFFFFF;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 30px;
    line-height: 33px;
    letter-spacing: -0.447846px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  padding-top: 32px;
  width: 90%;
  margin: 0 auto;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    flex-direction: column;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const CookieSettingContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4.16756px 0px rgba(36, 141, 193, 0.15);
  border-radius: 25.0054px;
  padding: 24px;
  box-sizing: border-box;
  margin-right: 24px;
  /* width: 556px; */
  max-width: 556px;
  width: 40%;
  height: max-content;

  position: sticky;
  top: 100px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    position: unset;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

export const CookiePolicyContainer = styled.div`
  width: 60%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
`;
