import React, { useState } from 'react';
import moment from 'moment';
// import 'moment/locale/de';
import 'moment/locale/tr';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/arrow-right.svg';
import { LanguageContext } from 'contexts/Languages';
import {
  Wrapper,
  Table,
  Thead,
  Tbody,
  TableTitleRow,
  DayTd,
  DayBox,
  DayText,
  WeekDayText,
  TitleBar,
  TitleMonthYear,
  Navigations,
  NavButton,

  LoaderWrapper,
  Dot
} from './style';

const containerRef = React.createRef(null);
const Calendar = React.forwardRef(({
  containerStyle,
  tableStyle,
  dayContainerStyle,
  changeDate,
  onCalendarChange,
  loading,
  availableDates = [],
  defaultDate,
}, ref) => {
  const { language } = React.useContext(LanguageContext);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [locale, setLocale] = useState('en');
  const titleRef = React.useRef(null);
  moment.locale(locale);

  React.useEffect(() => {
    setLocale(language.code);
  }, [language.code]);

  React.useEffect(() => {
    // handleSelectDay(new Date().getDate());
    onCalendarChange?.({ year: currentYear, month: currentMonth });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (defaultDate) {
      const availableDays = getAvailableDays();
      moment.locale(locale);
      const date = moment(defaultDate);
      const isAvailable = isAvailableDay(date.date(), availableDays);
      if (!isAvailable) {
        console.log('not available');
        return;
      }
      // console.log('date.month()', date.month());
      setCurrentMonth(date.month());
      setCurrentYear(date.year());
      setSelectedDate(date);
      onCalendarChange?.({ year: currentYear, month: currentMonth });
    }
    // }, [/* defaultDate */]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDate]);

  const handleNextDay = () => {
    const nextDay = moment(selectedDate).add(1, 'day');
    setSelectedDate(nextDay);
    // if (nextDay.isSameOrAfter(moment(), 'day')) {
    //   setCurrentMonth(nextDay.month());
    //   setCurrentYear(nextDay.year());
    // }
    if (nextDay.isSameOrAfter(moment(selectedDate), 'day')) {
      setCurrentMonth(nextDay.month());
      setCurrentYear(nextDay.year());
    }
    // changeDate?.(nextDay);
  };

  const handlePrevDay = () => {
    const prevDay = moment(selectedDate).subtract(1, 'day');
    setSelectedDate(prevDay);
    if (prevDay.isSameOrBefore(moment(selectedDate))) {
      setCurrentMonth(prevDay.month());
      setCurrentYear(prevDay.year());
    }
    // changeDate?.(prevDay);
  };

  React.useImperativeHandle(ref, () => ({
    nextDay: handleNextDay,
    prevDay: handlePrevDay,
    changeDay: handleSelectDay,
    getHeight: () => containerRef.current.offsetHeight,
    formatDateToText: (date) => (
      {
        month: moment.months(date?.getMonth?.()),
        day: moment(date).format('dddd'),
        date: date?.getDate?.(),
      }
    )
  }));

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
      onCalendarChange?.({ year: currentYear - 1, month: 11 });
    } else {
      setCurrentMonth(currentMonth - 1);
      onCalendarChange?.({ year: currentYear, month: currentMonth - 1 });
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
      onCalendarChange?.({ year: currentYear + 1, month: 0 });
    } else {
      setCurrentMonth(currentMonth + 1);
      onCalendarChange?.({ year: currentYear, month: currentMonth + 1 });
    }
  };

  const handleSelectDay = (day) => {
    // console.log(day.target.innerText);
    // setSelectedDate(new Date(currentYear, currentMonth, day));
    setSelectedDate(moment(`${currentYear}-${currentMonth + 1}-${day}`));
    changeDate?.(new Date(currentYear, currentMonth, day));
  };

  const isAvailableDay = (day, availableDates) => {
    return availableDates.includes(day);
  };

  const getAvailableDays = () => {
    const availableDays = [];
    availableDates?.forEach?.((item) => {
      availableDays.push(new Date(item._id).getDate());
    });
    return availableDays;
  };

  const renderCalendarGrid = () => {
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const daysInPrevMonth = new Date(currentYear, currentMonth, 0).getDate();
    const availableDays = getAvailableDays();

    let startDay = new Date(currentYear, currentMonth, 1).getDay(); // index of first day of month
    let dayCounter = 1;
    let cellCounter = 0;

    if (moment.localeData().firstDayOfWeek() === 1) { // if first day of week is Monday
      const currentDay = moment(new Date(currentYear, currentMonth, 1)).locale('en').format('dddd');
      const startMonday = { Sunday: 6, Monday: 0, Tuesday: 1, Wednesday: 2, Thursday: 3, Friday: 4, Saturday: 5 }
      startDay = startMonday[currentDay];
    }

    const rows = [];
    let tableRow = 5;
    if (daysInMonth + startDay <= 35) {
      tableRow = 5;
    } else if (daysInMonth + startDay > 35 && daysInMonth + startDay <= 42) {
      tableRow = 6;
    }
    for (let i = 0; i < tableRow; i++) {
      const cells = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < startDay) {
          // prev month days
          cells.push(<DayTd key={cellCounter}>
            <DayBox $passive $dayContainerStyle={dayContainerStyle} $x={titleRef.current?.getBoundingClientRect()?.y}>
              <DayText $passive>
                {daysInPrevMonth - startDay + j + 1}
              </DayText>
            </DayBox>
          </DayTd>
          );
        } else if (dayCounter > daysInMonth) {
          // next month days
          cells.push(<DayTd key={cellCounter}>
            <DayBox $passive $dayContainerStyle={dayContainerStyle} $x={titleRef.current?.getBoundingClientRect()?.y}>
              <DayText $passive>
                {dayCounter - daysInMonth}
              </DayText>
            </DayBox>
          </DayTd>
          );
          dayCounter++;
        } else {
          // current month days
          const todayNum = dayCounter;
          // const isAvailable = availableDays.includes(dayCounter);
          const isAvailable = isAvailableDay(dayCounter, availableDays)
            && !moment(`${currentYear}-${currentMonth + 1 > 9 ? currentMonth + 1 : `0${currentMonth + 1}`}-${todayNum > 9 ? todayNum : `0${todayNum}`}`).isBefore(moment(), 'day');
          // For safari browser

          cells.push(<DayTd key={cellCounter}>
            <DayBox
              // $selected={selectedDate?.getDate() === dayCounter}
              $selected={isAvailableDay(dayCounter, availableDays) && moment(selectedDate?._i, selectedDate?._f || 'YYYY-MM-DD').isSame(`${currentYear}-${currentMonth + 1 > 9 ? currentMonth + 1 : `0${currentMonth + 1}`}-${todayNum > 9 ? todayNum : `0${todayNum}`}`)}
              onClick={() => handleSelectDay(todayNum)}
              $dayContainerStyle={dayContainerStyle}
              $x={titleRef.current?.getBoundingClientRect()?.y}
              $isAvailable={isAvailable}
              disabled={!isAvailable}
            >
              <DayText $isAvailable={isAvailable}>
                {dayCounter}
              </DayText>
            </DayBox>
          </DayTd>
          );
          dayCounter++;
        }
        cellCounter++;
      }
      rows.push(
        <tr key={i}>
          {cells}
        </tr>
      );
    }

    return rows;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memorizedCalendar = React.useMemo(() => renderCalendarGrid(), [
    locale,
    currentMonth,
    currentYear, selectedDate,
    availableDates
  ]);

  return (
    <Wrapper $containerStyle={containerStyle} ref={containerRef}>
      <TitleBar ref={titleRef}>
        <TitleMonthYear>{moment.months(currentMonth)} {currentYear}
          {loading &&
            <LoaderWrapper>
              <Dot />
              <Dot />
              <Dot />
              <Dot />
              <Dot />
              <Dot />
            </LoaderWrapper>
          }
        </TitleMonthYear>
        <Navigations>
          <NavButton
            $isLeft
            onClick={handlePrevMonth}
            disabled={moment(new Date()).isAfter(moment(new Date(currentYear, currentMonth, 1)))}
          >
            <ArrowIcon />
          </NavButton>
          <NavButton onClick={handleNextMonth} >
            <ArrowIcon />
          </NavButton>
        </Navigations>
      </TitleBar>

      <Table $tableStyle={tableStyle} ref={ref}>
        <Thead>
          <TableTitleRow>
            {moment.weekdaysShort().map((_, idx, arr) => {
              return <WeekDayText key={`wd_${idx}`}>
                {arr[(moment.localeData().firstDayOfWeek() + idx) % 7]}
              </WeekDayText>
            })}
          </TableTitleRow>
        </Thead>
        <Tbody>
          {memorizedCalendar}
        </Tbody>
      </Table>
    </Wrapper>
  );
});

export default Calendar;
// export default React.memo(Calendar, (prevProps, nextProps) => {
//   if (prevProps.defaultDate) {
    
//   }
// })
