import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: all .3s ease;
  padding: 0 52px;
  box-sizing: border-box;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    width: 100%;
    padding: 0 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    width: 100%;
    padding: 0 16px;
  }
`;

export const HeroImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 350px;
  object-fit: cover;
  object-position: center;
  transition: all .3s ease;
  z-index: 1;
  user-select: none;
  pointer-events: none;
`;

export const OnBoardingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  color: #FFFFFF;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 55%;
  /* max-width: 550px; */
  padding-top: 10%;
  padding-left: 10%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 15px;
    padding-left: 25px;
    min-width: 200px;
  }
`;

export const SubTitle = styled.h3`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-transform: uppercase;
  color: inherit;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 17px;
    line-height: 19px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Title = styled.h1`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  /* font-size: 80px; */
  /* line-height: 94px; */
  font-size: 60px;
  line-height: 74px;
  letter-spacing: -1.2px;
  color: inherit;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 34px;
    line-height: 34px;
    letter-spacing: -0.447846px;
    padding-top: 8px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.447846px;
    padding-top: 8px;
  }
`;
