import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@mui/material';
import { useTheme } from 'styled-components';
import {
  StyledSelect,
  Label,
  ArrowIcon,
  MenuItem
} from './style';

const Dropdown = ({
  label,
  options,
  defaultValue,
  value,
  setValue,
  containerStyle,
  disabled,
  error
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        const selectedOption = options.find((option) => option?.value === defaultValue);
        setValue(selectedOption);
        return;
      }
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event) => {
    const { value } = event.target;
    const selectedOption = options.find((option) => option?.value === value);
    setValue(selectedOption);
  };

  return (
    <FormControl
      disabled={disabled}
      variant={'standard'}
      sx={{
        minWidth: 120,
        '.MuiInputLabel-root.Mui-focused': {
          color: useTheme().colors.brand,
        },
        // 'label+.css-1im0rhg-MuiInputBase-root-MuiInput-root-MuiSelect-root': {
        //   marginTop: '2px',
        // },
        // 'label+.css-1im0rhg-MuiInputBase-root-MuiInput-root-MuiSelect-root': {
        //   marginTop: '2px',
        // },
        '.MuiInputBase-formControl': {
          marginTop: '2px',
          paddingRight: '15px',
        },
        ...containerStyle
      }}
    >
      <Label error={error} id={`dd_${label}`}>{label}</Label>
      <StyledSelect
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        labelId={`dd_${label}`}
        value={value?.value}
        placeholder={label}
        onChange={handleChange}
        label={label}
        IconComponent={() => <ArrowIcon $open={open} onClick={() => handleOpen()} />}
        defaultValue={defaultValue?.value}
        sx={{
          'MuiFormControl-root': {
            background: 'red',
            margin: 0,
            width: '100%',
            marginTop: '2px'
          },
        }}
        error={error}
      >
        {(options && options?.length <= 0) ?
          <MenuItem disabled value={''}>No options</MenuItem>
          :
          options?.map?.((option, idx) => (
            <MenuItem key={idx} value={option.value}>{option.label}</MenuItem>
          ))}
      </StyledSelect>
    </FormControl>
  )
};

Dropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  defaultValue: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  setValue: PropTypes.func,
  containerStyle: PropTypes.object,
};

Dropdown.defaultProps = {
  label: '',
  options: [],
  // defaultValue: { value: '', label: '' },
  value: {},
  setValue: () => { },
  containerStyle: {},
};

export default Dropdown;
