import React from 'react';
import { formatPrice } from 'helpers';
import {
  // SectionTitle,
  CurrencyContainer,
  CurrencyDescription,
  CurrencyBoxes,
  CurrencyBox,
  CurrencyText,
  PriceText,
} from './style';

const SelectCurrency = ({
  order,
  handleChangeBillingInfo,
  currencyData,
  totalPrice,
  t
}) => {

  const handleChange = (key, value) => {
    handleChangeBillingInfo(key, value);
  };

  React.useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.substring(0, 2) === 'tr') {
      handleChange('currency', 'TRY');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CurrencyContainer>
      <CurrencyDescription>{t('pages.booking.checkout_section.currency_description')}</CurrencyDescription>

      <CurrencyBoxes>
        <CurrencyBox
          onClick={() => handleChange('currency', 'EUR')}
          $selected={order?.billingInfo?.currency?.value === 'EUR'}
        >
          <CurrencyText>
            EUR <CurrencyText $light>Euro</CurrencyText>
          </CurrencyText>
          <PriceText>
            {/* €1,793.00 <PriceText $thiny>/Total</PriceText> */}
            €{formatPrice(totalPrice)} <PriceText $thiny>/Total</PriceText>
          </PriceText>
        </CurrencyBox>

        <CurrencyBox
          onClick={() => handleChange('currency', 'TRY')}
          $selected={order?.billingInfo?.currency?.value === 'TRY'}
        >
          <CurrencyText>
            TRY <CurrencyText $light>Türk Lirası</CurrencyText>
          </CurrencyText>
          <PriceText>
            ₺{formatPrice(currencyData * totalPrice)} <PriceText $thiny>/Total</PriceText>
          </PriceText>
        </CurrencyBox>


      </CurrencyBoxes>
    </CurrencyContainer>
  )
}

export default SelectCurrency;
