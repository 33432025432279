import React from 'react';
import Cloud from 'assets/img/image/cloud-layout.png';
import image from 'assets/img/image/bg.png';
import CookieSettings from './components/CookieSettings';
import CookiePolicy from './components/CookiePolicy';
import {
  Container,
  CloudImg,
  HeaderArea,
  BackImage,
  HeaderContent,
  HeaderTitle,
  ContentContainer,
  CookieSettingContainer,
  CookiePolicyContainer
} from './style';

const CookieManagement = () => {
  return (
    <Container>
      <CloudImg
        src={Cloud}
        alt='cloud'
        width={'100%'}
        height={'100%'}
      />
      <HeaderArea>
        <BackImage
          src={image}
          alt='Background'
          width={'100%'}
          height={'100%'}
        />
        <HeaderContent>
          <HeaderTitle>Cookie policy</HeaderTitle>
        </HeaderContent>
      </HeaderArea>

      <ContentContainer>
        <CookieSettingContainer>
          <CookieSettings />
        </CookieSettingContainer>

        <CookiePolicyContainer>
          <CookiePolicy />
        </CookiePolicyContainer>
      </ContentContainer>
    </Container>
  )
};

export default CookieManagement;
