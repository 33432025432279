import React from 'react';
import { ROUTES } from 'routes';
import PublicRoute from 'routes/PublicRoute';
import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';

const withLayout = (LayoutComp, RenderComp) => <LayoutComp><RenderComp /></LayoutComp>;

const App = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const renderRoutes = React.useMemo(() => {
    return ROUTES.map(({
      key,
      path,
      component: Component,
      layout
    }, idx) => {
      if (path) {
        return (
          <Route
            id={`${key}_${idx}`}
            key={`${key}_${idx}`}
            element={<PublicRoute />}
          >
            <Route
              id={key}
              key={key}
              path={path}
              element={layout ? withLayout(layout, Component) : <Component />}
            />
          </Route>
        );
      }
      return null;
    });
  }, []);

  return (
    <Routes>
      {renderRoutes}
    </Routes>
  );
};

export default App;
