import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAnimation } from 'framer-motion';
import { LanguageContext } from 'contexts/Languages';
import { SECTIONS } from 'constants/sections';
import { useDimensions } from 'hooks/useDimensions';
import { ReactComponent as HamburgerIcon } from 'assets/img/icons/hamburger.svg';
import { ReactComponent as CloseIcon } from 'assets/img/icons/btn.svg';
import {
  Container,
  SectionsContainer,
  SectionText,
  Divider,
  SelectContainer,
  HeaderLogo,
  HamburgerButton,
  MobileContainer,
  MobileOverlay,
  MobileSectionsContainer,
  MobileSectionText,
  MobileLangContainer,
  MobileMenuTop
} from './style';
import { createPortal } from 'react-dom';
import Button from 'components/Button';
import { PATHS } from 'routes';
import Modal from 'components/Modal';

const overlayStart = {
  opacity: .9,
  transition: 'ease',
  display: 'unset'
};

const overlayStop = {
  opacity: 0,
  transition: 'ease',
  transitionEnd: {
    display: 'none'
  }
};

// TODO :: FIX THIS COMPONENT

const el = document.createElement('div');
el.id = 'nav_menu';

const Header = (_, ref) => {
  const { t, getAvailableLanguages, changeLanguage, language } = React.useContext(LanguageContext);
  const { isTablet, isMobile } = useDimensions();
  const navAnim = useAnimation();
  const overlayAnim = useAnimation();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const bookingModalRef = React.useRef();
  const nav = useNavigate();

  React.useEffect(() => {
    if (isMenuOpen) {
      document.body.appendChild(el);
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.getElementById('nav_menu') && document.body.removeChild(el);
    }
    return () => {
      document.getElementById('nav_menu') && document.body.removeChild(el);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el, isMenuOpen])

  const handleBookModal = () => {
    bookingModalRef.current.open();
    // closeMenu();
  }

  const openMenu = () => {
    setIsMenuOpen(true);
    overlayAnim.start(overlayStart);
    navAnim.start({
      x: 0,
      display: 'flex',
      transition: {
        bounce: 1,
        type: 'tween',
        velocity: 1,
        easings: ['easeOut', 'easeIn'],
      },
    });
  };

  const closeMenu = async () => {
    await navAnim.start({
      transition: {
        // ease: 'easeIn'
        ease: 'easeOut'
      },
      x: '100%',
      transitionEnd: {
        display: 'none'
      }
    });
    await overlayAnim.start(overlayStop);
    setIsMenuOpen(false);
  };

  React.useEffect(() => {
    if (isMenuOpen) {
      setTimeout(() => {
        openMenu();
      }, 1);
    } else {
      document.documentElement.style.overflow = 'unset';
      closeMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const goToSection = (id) => {
    const { height } = ref.current?.getBoundingClientRect?.() || 80;
    const yOffset = -height;
    const element = document.getElementById(id);
    const pos = element?.getBoundingClientRect?.()?.top + window.pageYOffset + yOffset;
    closeMenu();
    window.scrollTo({ top: pos, behavior: 'smooth' });
  };

  if (isTablet || isMobile) {
    return (
      <Container ref={ref} $mobile>
        {window.location.pathname === '/' ? (
          <HeaderLogo
            // style={{ minWidth: 'max-content' }}
            onClick={() => goToSection('header')}
            title={'Dinner in The Sky'}
          />
        ) : (
          <Link
            to={'/'}
          >
            <HeaderLogo
              onClick={() => goToSection('header')}
              title={'Dinner in The Sky'}
            />
          </Link>
        )}

        {window.location.pathname === '/' ?
          <HamburgerButton
            onClick={!isMenuOpen ? openMenu : closeMenu}
          >
            {isMenuOpen ?
              <CloseIcon />
              :
              <HamburgerIcon />
            }
          </HamburgerButton>
          :
          <MobileLangContainer
            onChange={(e) => changeLanguage(e.target.value)}
            value={language.code}
          >
            {getAvailableLanguages().map((lang, index) => (
              <option key={index} value={lang.code}>
                {lang.code}
              </option>
            ))}
          </MobileLangContainer>
        }

        {createPortal(
          <React.Fragment>
            <Modal
              ref={bookingModalRef}
              size={'md'}
              modalType={'booking'}
            />

            <MobileOverlay
              animate={overlayAnim}
              initial={overlayStop}
              onClick={closeMenu}
            />
            <MobileContainer
              initial='hidden'
              variants={{
                visible: {
                  x: '0',
                  transition: { type: 'tween' },
                  display: 'unset'
                },
                hidden: {
                  x: '100%',
                  transition: { type: 'tween' },
                  display: 'none'
                },
              }}
              $isMobile={isMobile}
              animate={navAnim}
              $headerHeight={ref.current?.getBoundingClientRect?.()?.height || 80}
            >
              <MobileMenuTop>
                <Button
                  backgroundColor={'#139CD7'}
                  overlayColor={'#F4F4F4'}
                  textColor={'#002424'}
                  title={'Book now'}
                  onClick={() => nav(PATHS.BOOKING)}
                  // onClick={handleBookModal}
                  fullSize
                />
                <MobileSectionsContainer>
                  {SECTIONS.map(({ key, title, path }) => (
                    <MobileSectionText key={key} onClick={() => goToSection(path)}>
                      <p>{t(`header.${title}`)}</p>
                    </MobileSectionText>
                  ))}
                </MobileSectionsContainer>
              </MobileMenuTop>
              <div>
                <MobileLangContainer
                  onChange={(e) => changeLanguage(e.target.value)}
                  value={language.code}
                >
                  {getAvailableLanguages().map((lang, index) => (
                    <option key={index} value={lang.code}>
                      {lang.code}
                    </option>
                  ))}
                </MobileLangContainer>
              </div>
            </MobileContainer>
          </React.Fragment>
          , el
        )}

      </Container>
    )
  }

  return (
    <Container ref={ref}>
      {window.location.pathname === '/' ?
        (
          <HeaderLogo
            // style={{ minWidth: 'max-content' }}
            onClick={() => goToSection('header')}
            title={'Dinner in The Sky'}
          />
        )
        :
        (
          <Link
            to={'/'}
          >
            <HeaderLogo
              onClick={() => goToSection('header')}
              title={'Dinner in The Sky'}
            />
          </Link>
        )
      }

      {window.location.pathname === '/' ?
        <SectionsContainer>
          {SECTIONS.map(({ key, title, path }) => (
            <SectionText key={key} onClick={() => goToSection(path)}>
              <p>{t(`header.${title}`)}</p>
            </SectionText>
          ))}
          <Divider />
          <SelectContainer
            onChange={(e) => changeLanguage(e.target.value)}
            value={language.code}
          >
            {getAvailableLanguages().map((lang, index) => (
              <option key={index} value={lang.code}>
                {lang.code}
              </option>
            ))}
          </SelectContainer>
        </SectionsContainer>
        :
        <SelectContainer
          onChange={(e) => changeLanguage(e.target.value)}
          value={language.code}
        >
          {getAvailableLanguages().map((lang, index) => (
            <option key={index} value={lang.code}>
              {lang.code}
            </option>
          ))}
        </SelectContainer>
      }

    </Container>
  )
};

export default React.forwardRef(Header);
