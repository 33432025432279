import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  /* margin-bottom: 45px; */
  /* margin: 45px 0; */
  margin: 30px 0;

  &>span:first-child {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #002424;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    flex-direction: column;
    border-bottom: 1px solid #C8C8C8;
    padding-bottom: 20px;
  }

`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    &>div:last-child {
      margin-right: 0;
      margin: auto;
    }
  }
  ${({ $mr }) => $mr && `
    margin-top: 15px;
  `}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  * > fieldset {
    border: none;
    border-bottom: 1px solid #C8C8C8;
  }

  * > .MuiOutlinedInput-input {
    padding: 4px 0 7px 0;
    border-radius: 0;
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 400;
    color: #002424;
    ::placeholder {
      color: #002424;
      opacity: .8;
    }
  }
  * > .MuiOutlinedInput-root {
    border-radius: 0;
    justify-content: center;
  }
  margin-top: 15px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    &>div:last-child {
      margin-right: 0;
      margin: auto;
      width: 50%;
    }
  }
`;

export const Guest = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  span {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #002424;
    white-space: nowrap;
    margin-right: 20px;
    margin: 0 20px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background: #C8C8C8;
  background: #002424;
`;
