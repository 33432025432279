import styled from 'styled-components';

export const Container = styled.div`
  max-width: 950px;
`;

export const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4.16756px 0px rgba(36, 141, 193, 0.15);
  border-radius: 25.0054px;
  overflow: hidden;
`;

export const WarningArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #C8C8C828;
  /* padding: 17px 24px; */
  padding: 17px 19px;
  p {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 1.8px;
    color: ${({ theme }) => theme.colors.error};
    padding-left: 15px;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  /* padding: 12px 24px; */
  /* padding: 12px 19px; */
  padding: 9px 19px;
  /* height: ${({ $height }) => $height || '46px'}; */
  border-bottom: 1.04189px dashed #898C8D;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    padding: 9px 8px;
  }
`;

export const CardContents = styled.div`
  display: flex;
  /* padding: 13px; */
  padding-bottom: 40px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    flex-direction: column;
  }
`;

export const CardContent = styled.div`
  width: 50%;
  background-color: ${({ $bg }) => $bg || '#fff'};
  padding: ${({ $padding }) => $padding || null};
  box-sizing: border-box;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    width: 100%;
    padding: ${({ $padding }) => $padding ? '12px 8px' : null};
  }
`;

export const SectionTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #002424;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.brand};
  margin-top: 14px;
  margin-bottom: 10px;
  ${({ $sx }) => $sx && $sx}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding-top: 35px;
  display: flex;
  justify-content: flex-end;
  & > :not(:last-child) {
    margin-right: 32px;
  }
`;

export const ReceiptContainer = styled.div`
  background: #FFFFFF;
  /* padding: 20px 16px; */
`;

export const ReceiptTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
  padding-bottom: 8px;
`;

export const ReceiptOverallContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 16px 0 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    padding: 12px 8px 0 8px;
  }
`;

export const ReceiptOverall = styled.div`
  display: flex;
  flex-direction: column;
  ${({ $sx }) => $sx && $sx};
`;

export const FoodCount = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #002424;
`;

export const SessionDate = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #002424;
`;

export const Price = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  color: #002424;
`;

export const Fee = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #002424;
`;

export const ReceiptContent = styled.ul`
  list-style: none;
  /* padding: 0 16px 20px 16px; */
`;

export const Receipt = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  font-family: 'Roboto Condensed';
  font-size: 1rem;
  font-weight: 300;
  color: #002424;
  border-bottom: 1px solid #C8C8C8;
  padding: 0 16px;
  margin-bottom: 5px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    padding: 0 8px;
  }
`;

export const StepPrice = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: ${({ $bold }) => $bold ? 600 : 300};
  font-size: 16px;
  line-height: 32px;
  color: #002424;
`;

export const OrderButtonArea = styled.div`
  padding: 24px 16px;
`;

export const List = styled.ul`
  list-style: none;
  padding-bottom: 38px;
  padding: 25px 19px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    padding: 25px 8px;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 0;
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 15px;
    border: 1px solid #1A9DD8;
    border-radius: 100%;
    min-width: 7px;
  }
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #231F20;
`;

export const Box = styled.div`
  padding-left: 19px;
  padding-top: 19px;
  padding-right: 34px;
  padding-bottom: 17px;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-right: 8px;
    padding-left: 8px;
  }
`;

export const SpecialOccasionArea = styled.div`

`;

export const SpecialList = styled.div`
  ${ListItem};
  li {
    align-items: baseline;
  }
  padding-top: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const SpecialTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #002424;
  padding-top: 20px;
  padding-bottom: 8px;
`;

export const SpecialText = styled.p`
  /* display: inline-block; */
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme, $brand }) => $brand ? theme.colors.brand : '#000000'};
`;
