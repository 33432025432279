import React from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { PATHS } from 'routes';
import { LanguageContext } from 'contexts/Languages';
import Button, { TextButton } from 'components/Button';
import {
  Wrapper,
  Container,
  Content,
  ButtonArea,
  Text,
} from './style';

const el = document.createElement('div');
el.id = 'cookie-banner';

const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(['demo']);
  const { t } = React.useContext(LanguageContext);
  const conRef = React.useRef();
  const nav = useNavigate();

  React.useEffect(() => {
    if (!!cookies.demo) {
      return;
    }
    document.body.appendChild(el);
    setTimeout(() => {
      document.documentElement.style.overflow = 'hidden';
    }, 300);
    return () => {
      document.getElementById(el.id) && document.body.removeChild(el);
      document.documentElement.removeAttribute('style');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el]);

  React.useEffect(() => {
    if (!cookies.demo && window.location.pathname !== PATHS.COOKIE_MANAGEMENT) {
      conRef.current.classList.remove('close');
      document.body.appendChild(el);
      setTimeout(() => {
        document.documentElement.style.overflow = 'hidden';
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  React.useEffect(() => {
    document.addEventListener(el, (e) => {
      console.log(e);
    });
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.removeAttribute('style');
    };
  }, []);

  const closeCookieBanner = () => {
    conRef.current.classList.add('close');
    setTimeout(() => {
      document.getElementById(el.id)?.remove?.();
      document.documentElement.removeAttribute('style');
    }, 600);
  };

  const applyAllCookies = () => {
    handleCookie();
    closeCookieBanner();
  };

  const goToCookieManagement = () => {
    nav(PATHS.COOKIE_MANAGEMENT);
    closeCookieBanner();
  };

  const handleCookie = () => {
    setCookie('demo', 'demo content', { path: window.location.host, sameSite: 'strict' });
  };

  return createPortal(
    <Wrapper onClick={applyAllCookies}>
      <Container ref={conRef}>
        <Content>
          <Text>
            {t('pages.cookie_management.banner.text')}
          </Text>

          <ButtonArea>
            <Button
              title={t('pages.cookie_management.banner.accept_all')}
              onClick={applyAllCookies}
              backgroundColor={'#139CD7'}
              overlayColor={'#002424'}
              containerStyle={{ marginBottom: 16 }}
              fullSize
            />
            <TextButton
              title={t('pages.cookie_management.banner.customize')}
              color={'#002424'}
              onClick={goToCookieManagement}
              fullSize
              hasArrow
            />
          </ButtonArea>
        </Content>
      </Container>
    </Wrapper>,
    el
  );
};

export default CookieBanner;
