import styled from 'styled-components';

export const Wrapper = styled.div`
  /* padding: 0 52px; */
  overflow: hidden;
  max-width: 2440px;
  margin: 0 auto;
`;

export const Sections = styled.div`
  width: 85%;
  margin: 0 auto;
`;
