
const theme = {
  colors: {
    primary: '#54565B',
    text: '#002424',
    error: '#EC604D',
    divider: '#C8C8C8',
    brand: '#139CD7'
  },
  breakpoints: {
    xsm: 380,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    xxxl: 2468,
  }
};

export default theme;
