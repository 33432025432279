import styled, { keyframes, css } from 'styled-components';

// const backWidthPercentage = 30;
const backWidthPercentage = 34;
const CARD_RESPONSIVE = '846px';
const CARD_HEIGHT = 80;

export const Container = styled.div`
  max-width: 950px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px){
    max-width: 850px;
  }
  @media only screen and (max-width: ${CARD_RESPONSIVE}){
    width: 80vw;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px){
    max-width: none;
    min-width: 250px;
    width: 100%;
  }

`;

export const Cards = styled.div`
  display: flex;
  align-items: flex-start;
  @media only screen and (max-width: ${CARD_RESPONSIVE}){
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
  }
`;

export const CalendarArea = styled.div`
  background-color: #fff;
  border-radius: 16px;
  width: fit-content;
  border-radius: 0;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  overflow: auto;
  /* max-width: 450px; */
  /* width: 50%; */

  /* width: 50%; */
  width: 53%;
  max-width: 475px;
  box-shadow: 0px 4.16756px 0px rgba(36, 141, 193, 0.15);
  padding: 5px;
  box-sizing: border-box;
  @media only screen and (max-width: ${CARD_RESPONSIVE}){
    width: 100%;
    border-radius: 0;
    max-width: unset;
  }

  /* ::-webkit-scrollbar {
    width: 1px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: red;
  }

  ::-webkit-scrollbar-thumb {
    background: blue;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: pink;
  } */
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.brand};
  color: #fff;
  border-radius: 16px;
  border-radius: 0;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  padding: 0px;
  /* 5px for padding to calendar */
  min-height: ${({ $calendarHeight }) => $calendarHeight + 10}px;
  width: 50%;
  transition: all .1s ease;
  box-shadow: 0px 4.16756px 0px rgba(36, 141, 193, 0.15);
  @media only screen and (max-width: ${CARD_RESPONSIVE}){
    width: 100%;
    border-radius: 0;
    max-width: unset;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 24px;
  height: 46px;
  border-bottom: 1.04189px dashed #898C8D;
`;

export const CardTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  color: #FFFFFF;
`;

export const Sessions = styled.div`
  box-sizing: border-box;
  padding: 24px 24px 24px 0px;
  margin-left: 24px;
  overflow-x: hidden;

  @media screen and (max-width: ${CARD_RESPONSIVE}) {
    padding-top: 0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xxl}px){
    margin-left: 12px;
    padding-top: 12px;
    padding-right: 12px;
  }
`;

export const SessionBox = styled.div`
  /* display: flex; */
  /* justify-content: space-between; */
  position: relative;
  border-radius: 12px;
  box-sizing: border-box;
  height: ${CARD_HEIGHT}px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
  @media only screen and (max-width: ${CARD_RESPONSIVE}){
    height: ${CARD_HEIGHT * 2}px;
  }
`;

const gradientAnimation = keyframes`
  0%{
    background-size: 250%;
    }
  100%{
    background-size: 130%;
  }
`;

export const SessionContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  box-sizing: border-box;
  height: ${CARD_HEIGHT}px;
  transform: ${({ $selected }) => $selected ? `translateX(-${backWidthPercentage}%)` : 'translateX(0)'};
  z-index: 1;

  transition: all ${({ $selected }) => $selected ? '.5s' : '.25s'} cubic-bezier(0.22, 0.68, 0, 1.35);
  align-items: center;
  
  background-color: #fff;
  background-image: ${({ $selected }) => $selected ? 'linear-gradient(270deg, #139CD7 10%, rgba(19, 156, 215, 0) 56.9%)' : 'none'};

  animation-name: ${({ $selected }) => $selected && gradientAnimation};
  animation-timing-function: ease;
  animation-duration: 1s;

  background-size: 130%;
  user-select: none;

  @media only screen and (max-width: ${CARD_RESPONSIVE}){
    height: ${CARD_HEIGHT * 2}px;
  }
`;

export const SessionInfo = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  & > div {
    &>:not(:last-child) {
      margin-bottom: 2px;
    }
  }
  @media only screen and (max-width: ${CARD_RESPONSIVE}){
    flex-direction: column;
  }
`;

export const SessionImage = styled.img`
  width: 75px;
  height: 100%;
  border-radius: 12px 0px 0px 12px;
  user-select: none;
  pointer-events: none;
  margin-right: 10px;
  @media only screen and (max-width: ${CARD_RESPONSIVE}){
    width: 100%;
    margin-right: 0;
    height: ${CARD_HEIGHT}px;
    object-fit: cover;
    border-radius: 0;
    padding-bottom: 10px;
  }
`;

export const SessionTexts = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: ${({ $fw }) => $fw || 600};
  font-size: ${({ $fs }) => $fs || '16px'};
  line-height: ${({ $lh }) => $lh || '20px'};
  color: ${({ $color }) => $color || '#002424'};
  ${({ $sx }) => $sx};
  /* width: 30%; */

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* TODO */
  /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px){
  } */
`;

export const SessionRight = styled.div`
  width: 90%;
`;

export const SessionSelect = styled.button`
  outline: none;
  border: 1px solid #D4D4D4;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 5px;
  background: #F6F6F6;
  margin-right: 8px;
  ${({ $isSelected }) => $isSelected && css`
    border: 1px solid #139CD7;
    background: #139CD7;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99989 10.1703L2.52989 6.70031C2.13989 6.31031 1.50989 6.31031 1.11989 6.70031C0.729893 7.09031 0.729893 7.72031 1.11989 8.11031L5.29989 12.2903C5.68989 12.6803 6.31989 12.6803 6.70989 12.2903L17.2899 1.71031C17.6799 1.32031 17.6799 0.690313 17.2899 0.300312C16.8999 -0.0896875 16.2699 -0.0896875 15.8799 0.300312L5.99989 10.1703Z' fill='%23fff' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  `}
`;

export const GuestsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.brand};
  height: 100%;
  z-index: 0;
  width: ${backWidthPercentage}%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const GuestTitle = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
`;

export const GuestContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CounterArea = styled.button`
  outline: none;
  background: transparent;
  width: 32px;
  height: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 100%;
  border: 2.08px solid #fff;
  display: grid;
  place-items: center;
  &>svg {
    path {
      fill: #fff;
    }
  }

  &:disabled {
    border: 2.08px solid #C8C8C8;
    cursor: not-allowed;
    &>svg {
      path {
        fill: #C8C8C8;
      }
    }
  }
`;

export const CountText = styled.span`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 18px;
  /* padding: 0 ${backWidthPercentage / 3}%; */
  padding: 0 ${backWidthPercentage / 4}%;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #FFFFFF;
`;

export const ButtonContainer = styled.div`
  padding-top: 39px;
  display: flex;
  justify-content: flex-end;
  transition-property: width;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.740, 0.000, 0.215, 0.515);
  @media only screen and (max-width: ${CARD_RESPONSIVE}){
    max-width: 500px;
    margin: 0 auto;
  }
`;

export const SessionInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  @media only screen and (max-width: ${CARD_RESPONSIVE}) {
    padding-left: 20px;
  }
`;

export const NoDateArea = styled.div`
  display: flex;
  align-items: center;
  min-height: inherit;
  justify-content: center;

  p {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    text-align: center;
  }
`;
