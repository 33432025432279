import React from 'react';
import propTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/img/icons/btn.svg';
import {
  CloseButton,
  ModalContainer,
  ModalContent,
} from './style';
import BaseModal from '@mui/material/Modal';
import Consents from './contents/Consents';
import Booking from './contents/Booking';

const Modal = React.forwardRef(({
  isCloseOutsideClick,
  size,
  customModalStyle,
  customContentStyle,
  children,
  modalState,
  showCloseButton,
  modalContainerStyle,
  onClose,

  modalType,
  subProp
}, ref) => {
  const [isVisible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (modalState === undefined) {
      return;
    }
    if (modalState) {
      openModal();
    } else {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState]);

  const keyListener = (event) => {
    if (event.key === 'Escape') {
      isCloseOutsideClick && closeModal();
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', keyListener, false);
    return () => {
      document.removeEventListener('keydown', keyListener, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  const openModal = () => {
    setVisible(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setVisible(false);
    onClose?.();
    document.body.style.overflow = 'auto';
  };

  React.useEffect(() => {
    if (isVisible) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.removeAttribute('style')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  // if (!isVisible) {
  //   return;
  // }

  const renderContent = React.useMemo(() => {
    switch (modalType) {
      case 'consent':
        return <Consents
          consentType={subProp?.type}
          props={{
            buyer: subProp.buyer,
            guests: subProp.guests,
          }}
        />;
      case 'booking':
        return <Booking />;
      default:
        return children;
    }
  }, [modalType, subProp, children])

  return (
    <BaseModal
      ref={ref}
      open={isVisible}
      onClose={closeModal}
      role='presentation'
      aria-labelledby={'Dinner in The Sky'}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)'
          },
          onClick: isCloseOutsideClick ? closeModal : null
        },
      }}
    >
      <ModalContainer $size={size} $sx={modalContainerStyle}>
        {showCloseButton &&
          <CloseButton onClick={closeModal} role='close-modal'>
            <CloseIcon />
          </CloseButton>
        }
        <ModalContent $customContentStyle={customContentStyle}>
          {/* {children} */}
          {renderContent}
        </ModalContent>
      </ModalContainer>
    </BaseModal>
  )
});

Modal.propTypes = {
  size: propTypes.oneOf(['sm', 'md', 'lg']),
  isCloseOutsideClick: propTypes.bool,
  modalState: propTypes.bool,
  showCloseButton: propTypes.bool,
  customModalStyle: propTypes.object
};

Modal.defaultProps = {
  size: 'lg',
  isCloseOutsideClick: true,
  showCloseButton: true
};

export default Modal;
