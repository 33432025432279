import React from 'react';
import TextInput from 'components/TextInput';
import CheckBox, { propagation } from 'components/CheckBox';
import {
  BillingContainer,
  InputContainer,
  SectionHeader,
  SectionTitle,
  InputFContainer,
  ConsentsArea
} from './style';
import Dropdown from 'components/Dropdown';
import Modal from 'components/Modal';
import { countriesWithFlags } from 'helpers/countries';

const BillingSection = ({
  order,
  handleChangeBillingInfo,
  t,
  consents,
  setConsents
}) => {
  const consentModalRef = React.useRef(null);
  const [consentType, setConsentType] = React.useState(null);

  const handleChange = (key, value) => {
    handleChangeBillingInfo(key, value);
  };

  const renderConsentModal = (type) => {
    setConsentType(type);
    consentModalRef.current.open();
  };

  React.useEffect(() => {
    return () => {
      consentModalRef.current?.close?.();
    };
  }, [])

  const renderConsentText = (k) => {
    const translated = t(`pages.booking.checkout_section.consents.${k}`);
    if (translated.indexOf('<0>') === -1) {
      return translated;
    }
    const clickStart = translated.indexOf('<0>') + 3;
    const clickEnd = translated.indexOf('</0>');
    const clickText = translated.substring(clickStart, clickEnd);
    const beforeClick = translated.substring(0, clickStart - 3);
    const afterClick = translated.substring(clickEnd + 4);
    // const clickableElem = React.createElement('span', { className: 'consent-btn', onClick: () => console.log('click') }, beforeClick + clickText + afterClick);
    const clickableElem = React.createElement('span', null,
      // React.createElement('span', {},
      React.createElement('span', {}, beforeClick),
      React.createElement('span', {
        className: 'consent-btn', onClick: (e) => {
          propagation(e);
          renderConsentModal(k);
        }
      }, clickText),
      React.createElement('span', {}, afterClick)
      // )
    );
    return clickableElem;
  };

  const changeConsent = (key, value) => {
    setConsents({ ...consents, [key]: { ...consents[key], value: value, error: null } });
  };

  React.useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.substring(0, 2) === 'tr') {
      handleChange('dialCode', '+90')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const memoCountryDial = React.useMemo(() => {
    return countriesWithFlags.find((country) => country.dial_code === order?.billingInfo?.dialCode.value)
  }, [order?.billingInfo?.dialCode.value]);

  return (
    <BillingContainer>
      <SectionHeader>
        <SectionTitle>{t('pages.success.billing_info')}</SectionTitle>
      </SectionHeader>
      <Modal
        modalType={'consent'}
        subProp={{
          type: consentType,
          buyer: {
            fullName: order?.billingInfo?.fullName?.value,
            phone: order?.billingInfo?.phone?.value,
            email: order?.billingInfo?.email?.value,
            country: order?.billingInfo?.country?.value,
            city: order?.billingInfo?.city?.value,
            address: order?.billingInfo?.fullAddress?.value,
          },
          guests: order?.guestsInfo
        }}
        ref={consentModalRef}
      />

      <InputContainer>
        <TextInput
          name={'firstName'}
          value={order?.billingInfo?.fullName.value}
          onChange={e => handleChange('fullName', e.target.value)}
          label={t('textboxes.first_name')}
          placeholder={t('textboxes.first_name')}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          error={order?.billingInfo?.fullName.error}
        />
        <TextInput
          name={'lastName'}
          value={order?.billingInfo?.surname.value}
          onChange={e => handleChange('surname', e.target.value)}
          label={t('textboxes.surname')}
          placeholder={t('textboxes.surname')}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          error={order?.billingInfo?.surname.error}
        />
        <Dropdown
          label={t('textboxes.country_code')}
          containerStyle={{ width: '100%', marginRight: '20px', paddingTop: '16px' }}
          // value={memoCountryDial ? { value: memoCountryDial?.dial_code, label: `${memoCountryDial?.flag} ${memoCountryDial?.name} ${memoCountryDial?.dial_code}` } : { value: '', label: '' }}
          value={memoCountryDial ? { value: memoCountryDial?.dial_code, label: `${memoCountryDial?.name} ${memoCountryDial?.flag} (${memoCountryDial?.dial_code})` } : { value: '', label: '' }}
          setValue={(value) => handleChange('dialCode', value.value)}
          options={
            countriesWithFlags.sort((a, b) => Number(a.dial_code) - Number(b.dial_code)).map((country) => {
              // return { value: country.dial_code, label: `${country.flag} ${country.name} ${country.dial_code}` };
              return { value: country.dial_code, label: `${country.name} ${country.flag} (${country.dial_code})` };
            })
          }
          error={!!order?.billingInfo?.dialCode.error}
        />
        <TextInput
          name={'tel'}
          value={order?.billingInfo?.phone.value}
          onChange={(e) => {
            if (e.target.value >= 0) {
              handleChange('phone', e.target.value)
            }
          }}
          label={t('textboxes.phone')}
          placeholder={t('textboxes.phone')}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          error={order?.billingInfo?.phone.error}
        />
        <TextInput
          name={'email'}
          value={order?.billingInfo?.email.value}
          onChange={e => handleChange('email', e.target.value)}
          label={'Email'}
          placeholder={'Email'}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          error={order?.billingInfo?.email.error}
        />
        <TextInput
          name={'country-name'}
          value={order?.billingInfo?.country.value}
          onChange={e => handleChange('country', e.target.value)}
          label={t('textboxes.country')}
          placeholder={t('textboxes.country')}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          error={order?.billingInfo?.country.error}
        />
        <TextInput
          name={'address-level1'}
          value={order?.billingInfo?.city.value}
          onChange={e => handleChange('city', e.target.value)}
          label={t('textboxes.city')}
          placeholder={t('textboxes.city')}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          error={order?.billingInfo?.city.error}
        />
        <TextInput
          // name={'street-address'} -> address-line1
          value={order?.billingInfo?.state.value}
          onChange={e => handleChange('state', e.target.value)}
          label={t('textboxes.state')}
          placeholder={t('textboxes.state')}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          error={order?.billingInfo?.state.error}
        />
        <TextInput
          name={'postalCode'}
          value={order?.billingInfo?.postalCode.value}
          onChange={e => handleChange('postalCode', e.target.value)}
          label={t('textboxes.postal_code')}
          placeholder={t('textboxes.postal_code')}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          error={order?.billingInfo?.postalCode.error}
        />
      </InputContainer>
      <InputFContainer>
        <TextInput
          // name={'address-line1'} -> street-address
          value={order?.billingInfo?.fullAddress.value}
          onChange={e => handleChange('fullAddress', e.target.value)}
          label={t('textboxes.full_address')}
          placeholder={t('textboxes.full_address')}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          error={order?.billingInfo?.fullAddress.error}
        // min={15} // TODO: min length
        />

        <ConsentsArea>
          <CheckBox
            checked={consents.terms.value}
            setChecked={e => changeConsent('terms', e)}
            label={<span>{renderConsentText('terms')}</span>}
            error={consents.terms.error}
            containerStyle={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          />
          <CheckBox
            checked={consents.privacy_policy.value}
            setChecked={e => changeConsent('privacy_policy', e)}
            label={<span>{renderConsentText('privacy_policy')}</span>}
            error={consents.privacy_policy.error}
            containerStyle={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          />
          <CheckBox
            checked={consents.kvkk.value}
            setChecked={e => changeConsent('kvkk', e)}
            label={<span>{renderConsentText('kvkk')}</span>}
            error={consents.kvkk.error}
            containerStyle={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          />
          <CheckBox
            checked={consents.information.value}
            setChecked={e => changeConsent('information', e)}
            label={<span>{renderConsentText('information')}</span>}
            error={consents.information.error}
            containerStyle={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          />
          <CheckBox
            checked={consents.electronic.value}
            setChecked={e => changeConsent('electronic', e)}
            label={<span>{renderConsentText('electronic')}</span>}
            error={consents.electronic.error}
            containerStyle={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          />
          <CheckBox
            checked={consents.explicit.value}
            setChecked={e => changeConsent('explicit', e)}
            label={<span>{renderConsentText('explicit')}</span>}
            error={consents.explicit.error}
            containerStyle={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          />
        </ConsentsArea>
      </InputFContainer>
    </BillingContainer>
  )
}

export default BillingSection
