import React from 'react';
// import { SOCIAL_MEDIA } from 'constants/sections';
import { LanguageContext } from 'contexts/Languages';
import {
  Container,
  // SocialMediaArea,
  // SocialMediaItem
} from './style';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';

const SidePanel = () => {
  const { t } = React.useContext(LanguageContext);
  const nav = useNavigate();
  const bookingModalRef = React.useRef();

  const handleBookModal = () => {
    bookingModalRef.current.open();
  }

  return (
    <Container $len={t('common.book_now').length}>
      {/* <SocialMediaArea> */}

      <Modal
        ref={bookingModalRef}
        size={'md'}
        modalType={'booking'}
      />
      <Button
        title={t('common.book_now')}
        onClick={() => nav(PATHS.BOOKING)}
        // onClick={handleBookModal}
        backgroundColor={'#139CD7'}
        overlayColor={'#002424'}
        containerStyle={{
          transform: 'rotateZ(90deg)'
        }}
      />
      {/* {SOCIAL_MEDIA.map(({ key, path, asset: Logo }) => (
          <SocialMediaItem key={key}>
            <a
              href={path}
              target="_blank"
              rel="noreferrer">
              <Logo />
            </a>
          </SocialMediaItem>
        ))} */}
      {/* </SocialMediaArea> */}
    </Container>
  )
};

export default SidePanel;
