import React from 'react'
import TextInput from 'components/TextInput'
import { Container, ErrorText, CardArea } from './style'
import { LanguageContext } from 'contexts/Languages'
import CreditCardInput from 'react-credit-card-input';
import { InputLabel } from '@mui/material';
import Button from 'components/Button';

const CreditCardInfo = ({
  cardInfo,
  setCardInfo,
  onClickOrder,
  payProcess,
  hasProcessError
}) => {
  const { t } = React.useContext(LanguageContext)
  const [isCreditError, setCreditError] = React.useState(false)

  return (
    <Container>
      <CardArea>
        <TextInput
          name={'name'}
          value={cardInfo.holderName}
          onChange={e => setCardInfo({ ...cardInfo, holderName: e.target.value })}
          label={t('textboxes.holderName')}
          placeholder={t('textboxes.holderName')}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
        />
        <InputLabel sx={{ fontSize: '14px' }}>{t('creditCard.cardInfo')}</InputLabel>
        <CreditCardInput
          cardNumberInputProps={{ value: cardInfo.no, placeholder: t('textboxes.no'), onChange: e => { setCardInfo({ ...cardInfo, no: e.target.value }); setCreditError(false) } }}
          cardExpiryInputProps={{ value: cardInfo.expiry, onChange: e => { setCardInfo({ ...cardInfo, expiry: e.target.value }); setCreditError(false) } }}
          cardCVCInputProps={{ value: cardInfo.cvv, onChange: e => { setCardInfo({ ...cardInfo, cvv: e.target.value }); setCreditError(false) } }}
          fieldClassName="input"
          containerStyle={{ width: '100%', border: 'none', borderBottom: `${isCreditError ? '0px' : '1px'} solid #000`, borderRadius: '0' }}
          invalidStyle={{ border: 'none', borderBottom: '1px solid red', borderRadius: '0' }}
          fieldStyle={{ paddingLeft: '0' }}
          customTextLabels={{
            invalidCardNumber: t('creditCard.invalidCardNumber'),
            expiryError: {
              invalidExpiryDate: t('creditCard.expiryError.invalidExpiryDate'),
              monthOutOfRange: t('creditCard.expiryError.monthOutOfRange'),
              yearOutOfRange: t('creditCard.expiryError.yearOutOfRange'),
              dateOutOfRange: t('creditCard.expiryError.dateOutOfRange')
            },
            invalidCvc: t('creditCard.invalidCvc'),
            invalidZipCode: t('creditCard.invalidZipCode'),
            cardNumberPlaceholder: t('creditCard.cardNumberPlaceholder'),
            expiryPlaceholder: t('creditCard.expiryPlaceholder'),
            cvcPlaceholder: t('creditCard.cvcPlaceholder'),
            zipPlaceholder: t('creditCard.zipPlaceholder')
          }}
          onError={(err) => { setCreditError(!!err); console.log(!!err); }}
        />
      </CardArea>
      <div>
      {hasProcessError && <ErrorText>Rezervasyon işleminiz ve ödeme gerçekleşmedi. Lütfen tekrar deneyin.</ErrorText>}
        <Button
          title={t('buttons.proceed_payment')}
          hasArrow
          disabled={isCreditError || cardInfo.holderName === ''}
          backgroundColor={'#139CD7'}
          overlayColor={'#002424'}
          onClick={onClickOrder}
          loading={payProcess}
          fullSize
        />
      </div>

      {/* <TextInput
        name={'cc-num'}
        value={cardInfo.no}
        onChange={e => {
          if (e.target.value >= 0) {
            setCardInfo({ ...cardInfo, no: e.target.value })
          }
        }}
        label={t('textboxes.no')}
        placeholder={t('textboxes.no')}
        containerStyle={{ marginBottom: '0' }}
        fullWidth
        inputProps={{ maxLength: 16 }}
      /> */}
      {/* <Row>
        <TextInput
          name={'cc-exp-month'}
          value={cardInfo.expiryMonth}
          onChange={e => {
            if (e.target.value >= 0) {
              setCardInfo({ ...cardInfo, expiryMonth: e.target.value })
            }
          }}
          label={t('textboxes.expiryMonth')}
          // placeholder={t('textboxes.expiryMonth')}
          placeholder={'4'}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          inputProps={{ maxLength: 2 }}
        />
        <TextInput
          name={'cc-exp-year'}
          value={cardInfo.expiryYear}
          onChange={e => {
            if (e.target.value >= 0) {
              setCardInfo({ ...cardInfo, expiryYear: e.target.value })
            }
          }}
          label={t('textboxes.expiryYear')}
          // placeholder={t('textboxes.expiryYear')}
          placeholder={(new Date().getFullYear() + 4).toString()}
          containerStyle={{ marginBottom: '0' }}
          fullWidth
          inputProps={{ maxLength: 4 }}
        />
        <TextInput
          name={'cvv2'}
          value={cardInfo.cvv}
          onChange={e => {
            if (e.target.value >= 0) {
              setCardInfo({ ...cardInfo, cvv: e.target.value })
            }
          }}
          label={t('textboxes.cvv')}
          placeholder={t('textboxes.cvv')}
          containerStyle={{ marginBottom: '0' }}
          // fullWidth
          inputProps={{ maxLength: 4 }}
        />
      </Row> */}
    </Container>
  )
}

export default CreditCardInfo
