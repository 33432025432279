import styled from 'styled-components';

export const Container = styled.div`
  padding: 9px 19px;
  display: flex;
  flex-direction: column;
`;

export const GuestContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

export const GuestName = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #231F20;
`;

export const Menu = styled.span`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #231F20;
  margin-left: 5px;
`;

export const List = styled.ul`
  list-style: none;
  ${({ $sx }) => $sx && $sx};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px){
    padding: 25px 8px;
  }
`;

export const ListItem = styled.li`
  display: flex;
  /* align-items: center; */
  align-items: baseline;
  padding: 5px 0;
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 5px;
    border: 1px solid #1A9DD8;
    border-radius: 100%;
    min-width: 7px;
  }
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #231F20;
`;