import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 52px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 0 16px;
  }
`;

export const HeaderArea = styled.div`
  position: relative;
`;

export const BackImage = styled.img`
  /* position: absolute; */
  user-select: none;
`;

export const HeaderContent = styled.div`
  position: absolute;
  left: 10%;
  top: 45%;
  transform: translateY(-45%);
`;

export const HeaderTitle = styled.h1`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 74px;
  letter-spacing: -1.2px;
  color: #FFFFFF;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 30px;
    line-height: 33px;
    letter-spacing: -0.447846px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

export const ContextContainer = styled.div`
  padding-top: 32px;
  width: 70%;
  margin: 0 auto;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 85%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Context = styled.div`

`;

export const Title = styled.h4`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 25px;
  color: #231F20;
  white-space: break-spaces;
  padding: 15px 0;
`;

export const Text = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #231F20;
  white-space: break-spaces;
`;

export const List = styled.ul`
  list-style: none;
  padding-bottom: 38px;
  ${({ $sx }) => $sx && $sx};
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 0;
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 15px;
    border: 1px solid #1A9DD8;
    border-radius: 100%;
    min-width: 7px;
  }
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #231F20;
  ${({ $sx }) => $sx && $sx};
`;

export const Line = styled.div`
  width: 100%;
  height: 4px;
  background: #C8C8C8;
  margin-top: 100px;
`;

export const CloudImg = styled.img`
  width: 100%;
  object-fit: contain;
  position: fixed;
  top: 320px;
  right: -20%;
  z-index: -1;
  user-select: none;
`;
