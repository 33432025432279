import React from 'react';
import { LanguageContext } from 'contexts/Languages';
import {
  Container,
  Title,
  SubTitle,
  Context,
  Table,
  Th,
  Td
} from './style';
import {
  policyEn,
  tableEn,
  policyTr,
  tableTr
} from './text';

const CookiePolicy = () => {
  const { language } = React.useContext(LanguageContext);

  const texts = React.useMemo(() => {
    if (language.code === 'tr') {
      return policyTr.split('{###table###}');
    }
    return policyEn.split('{###table###}');
  }, [language])

  return (
    <Container>
      <Title>Cookies policy</Title>

      {/* <SubTitle>What are Cookies</SubTitle> */}
      {texts.map((text, idx) => {
        return (
          <React.Fragment>
            <Context key={idx} dangerouslySetInnerHTML={{ __html: text }} />
            {idx !== texts.length - 1 && (
              <Table>
                <thead>
                  {(language.code === 'tr' ? tableTr : tableEn)[idx].titles.map((title, index) => {
                    return (
                      <Th key={index}>{title}</Th>
                    )
                  })}
                </thead>
                <tbody>
                  {(language.code === 'tr' ? tableTr : tableEn)[idx].rows.map((row, index) => {
                    return (
                      <Td key={index}>{row}</Td>
                    )
                  })}
                </tbody>
              </Table>
            )}
          </React.Fragment>
        )
      })}
    </Container>
  )
};

export default CookiePolicy;
