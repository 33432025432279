import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`;

const blurRate = 10;

const blurAnim = (isStarter) => keyframes`
  from {
    backdrop-filter: blur(${isStarter ? 0 : blurRate}px);
  }
  to {
    backdrop-filter: blur(${isStarter ? blurRate : 0}px);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;
  /* background: rgba(0,0,0,.86); */
  background: rgba(0,0,0,.76);
  /* animation: ${blurAnim(true)} .6s ease-in-out; */
  /* backdrop-filter: blur(${blurRate}px); */
  .close {
    animation: ${fadeOut} .6s ease-in-out;
    transform: translateY(100%);
  }

  /* &:has(.close) {
    animation: ${blurAnim(false)} .6s ease-in-out;
    backdrop-filter: blur(0);
  } */
`;

export const Container = styled.div`
  background: #F0F5F9;
  width: 100%;
  height: 160px;
  display: flex;
  align-items: flex-end;
  animation: ${fadeIn} .6s ease-in-out;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    box-sizing: border-box;
    height: auto;
    padding: 24px 0;
  }
`;

export const Content = styled.div`
  box-sizing: border-box;
  background: #FFFFFF;
  width: 80%;
  height: 90%;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  max-width: 1900px;
  min-width: 190px;
  margin: 0 auto;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const ButtonArea = styled.div`
  box-sizing: border-box;
  background: #FFFFFF;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Text = styled.span`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #002424;
  padding-right: 16px;
  height: 90%;
  overflow: auto;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-bottom: 26px;
  }

  ${({ $sx }) => $sx};
`;
